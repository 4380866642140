import React, {useState, useEffect} from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { PrimaryButton } from '../../../components';
import {useCache, useNavigation, useSendbird } from '../../../context';
import BusinessQuoter from '../../../pages/BusinessPage/BusinessQuoter'

const MessageButton = () =>{
  const { subpage: bid, navigateByName} = useNavigation()
  const {onGetCachedBusiness} = useCache()
  const business = onGetCachedBusiness(bid)

  const [channel, setChannel] = useState()
  const {onGetChannel, sdkLoaded: sbLoaded} = useSendbird()
  useEffect(() => {
    if(!business?.id || !sbLoaded) return
    onGetChannel({invited: business.workerids}).then(channel =>setChannel(channel))
  }, [business?.id, sbLoaded]);

  return <PrimaryButton message="Message" sx={{ width: '70%' }} onClick={()=> navigateByName("InboxPage", {state: channel})} disabled={!channel}/>
}

const Content = ({left, right}) =>{

  return <Box sx={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 1, marginBottom: 1 }}>
           
          {left}

        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        {right}
      </Box>
}

const BottombarBusiness = () => {

  return (
    <Box sx={{ 
        width: "100%", 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        position: 'relative',
        mb: 1
      }}
    >

      <Content 
      left={<BusinessQuoter/>}
      right={<MessageButton/>} 
      />

    </Box>
  );
  
};

export default BottombarBusiness;
