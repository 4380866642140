import React, {useState, useEffect}  from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import '@sendbird/uikit-react/dist/index.css';
import CustomProviders, { useCache } from './context';
import { SelectRole } from './components';
import { routeConfiguration } from './routing/routeConfiguration';
const RequiredInfo = () => {
  const {user} = useCache()
  const [isOpen, setOpen] = useState()
  useEffect(() => {
    if(!user?.id) return

    setOpen(!user?.role)
  }, [user?.id, user?.role]);
  return <SelectRole isOpen={isOpen} setOpen={setOpen}/>
}
const render = () => {
  const app = (
    <CustomProviders>
      <RequiredInfo/>
        <Routes>
          {routeConfiguration.map(({ name, path, component: RouteComponent }) => (
            <Route key={name} path={path} element={<RouteComponent />} />
          ))}
        </Routes>
    </CustomProviders>
  );

  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);
  root.render(app);
};

if (typeof window !== 'undefined') {
  render();
}
