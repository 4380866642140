import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ActivityAvatar from './ActivityAvatar';
import { capitalizeFirstChar, formatTimeForLastUpdate } from '../../../util/utils';
import { useCache } from '../../../context';
import {EDGE_TEMPLATES} from '../../../util/constants'

const previewData = (services) => {
  let messagePlaceholder;
  let iconType = 'Handyman'; // Default iconType

  if (services.length === 1) {
    messagePlaceholder = services[0];
    iconType = services[0];
  } else if (services.length === 2) {
    messagePlaceholder = `${services[0]} and ${services[1]}`;
  } else if (services.length === 3) {
    messagePlaceholder = `${services[0]}, ${services[1]}, and ${services[2]}`;
  } else {
    messagePlaceholder = 'Multiple services';
  }

  return { messagePlaceholder, iconType };
};


const TransactionCard = ({ tx, notificationChannel }) => {
  const theme = useTheme(); 
  const {user} = useCache()
  const {last_notification_type, lastupdate} = tx
  if(!last_notification_type) return


  const { myLastRead } = notificationChannel || -1;
  const otherUser = user.sub == tx.customer.id? tx.business : tx.customer
  const { messagePlaceholder, iconType } = previewData(tx?.schedule?.services || []);
  const lastNotificationTime = lastupdate || -1;
  const lastUpdate = `Last update: ${formatTimeForLastUpdate(lastNotificationTime)}` || '';
  const notRead = lastNotificationTime > myLastRead;

  const state = EDGE_TEMPLATES[tx.last_notification_type].to
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        width: '100%',
        boxShadow: 'none',
        borderBottom: '1px solid #e0e0e0',
        bgcolor: notRead ? theme.palette.action.selected : 'inherit', // Use theme's selected color
      }}
    >
      <ActivityAvatar imageSrc={otherUser?.picture} iconType={iconType} size={40} notRead={notRead} />
      <Box ml={2} flexGrow={1}>
        <Typography variant="body1" color="textPrimary" fontWeight="bold">
          {messagePlaceholder} with {otherUser?.name}
        </Typography>

        <Typography variant="body2"> {capitalizeFirstChar(state)} </Typography>
        <Typography variant="caption" color="textSecondary" display="block" mt={0.5}>
          {lastUpdate}
        </Typography>
      </Box>
    </Box>
  );
};

export default TransactionCard;
