import React, {useState} from 'react'
import {MapForm, ClickableCard} from '../../components'
import {IconButton ,  Box } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const ClickableMap = ({previewsx,drawersx, title, ...mapprops}) => {
    const [cardMode, setCardMode] = useState(true)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const m = <MapForm
    {...mapprops}
    cardMode={cardMode}
/>

    const drawerContent = <Box sx={{height: "100%", width: "100%"}}>
        <IconButton
          onClick={()=>setDrawerOpen(false)}
          sx={{
            position: 'absolute',
            top: 15,
            left: 15,
            backgroundColor: 'rgba(255, 255, 255)',
            borderRadius: '50%',
            zIndex: 1000
          }}
        >
          <CloseIcon />
        </IconButton>
        {m}
    </Box>
  return <ClickableCard
            usedrawer={true}
            drawerOpen ={drawerOpen} 
            setDrawerOpen = {setDrawerOpen}
            onCardClick={()=>{
                setCardMode(false)
            }}
            onDrawerClose={()=>{
                setCardMode(true)
            }}
            content={drawerContent}
            preview={m}
            previewsx={previewsx}
            drawersx={drawersx}
  />
  
}


export default ClickableMap