// src/components/TodoItem.js
import React from 'react';
import {
  Box,
  Stack,
  Chip,
  IconButton,
  Typography,
  Tooltip,
  Button,
  Paper,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useDroppable } from '@dnd-kit/core';

const Counter = ({ itemId, count, onIncreaseItem, onDecreaseItem }) => {
  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Decrease">
        <IconButton
          size="small"
          onClick={() => onDecreaseItem(itemId)}
          aria-label="Decrease count"
        >
          <RemoveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Typography
        variant="h6"
        sx={{textAlign: 'center', fontWeight: 'bold' }}
      >
        {count}
      </Typography>
      <Tooltip title="Increase">
        <IconButton
          size="small"
          onClick={() => onIncreaseItem(itemId)}
          aria-label="Increase count"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const TimeArea = ({ item, onResetTime }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `${item.id}-time`,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isOver ? 'lightblue' : 'background.paper',
        borderRadius: 2,
        padding: '8px',
        minWidth: 80,
        position: 'relative',
        marginTop: 1,
        boxShadow: 1,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
        {item.time} min
      </Typography>
      {item.time > 0 && (
        <Button
          size="small"
          onClick={() => onResetTime(item.id)}
          sx={{
            textTransform: 'none',
            fontSize: '0.75rem',
          }}
        >
          Reset
        </Button>
      )}
    </Box>
  );
};

const TodoItem = ({
  item,
  onRemoveItem,
  onIncreaseItem,
  onDecreaseItem,
  showTimeSelector,
  onResetTime,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        position: 'relative',
        padding: 2,
        marginY: 1,
        borderRadius: 2,
        backgroundColor: 'grey.200', // Changed to a darker shade for better definition
        boxShadow: 3, 
      }}
    >
      <IconButton
        size="small"
        onClick={() => onRemoveItem(item.id)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          backgroundColor: 'white',
          boxShadow: 1,
        }}
        aria-label="Remove item"
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Stack direction="row" spacing={2} alignItems="center">
        {/* Label and Counter Section */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
            {`${item.label}`}
          </Typography>
          <Counter
            itemId={item.id}
            count={item.count}
            onIncreaseItem={onIncreaseItem}
            onDecreaseItem={onDecreaseItem}
          />
        </Box>

        {/* TimeArea Section */}
        {showTimeSelector && <TimeArea item={item} onResetTime={onResetTime} />}
      </Stack>
    </Paper>
  );
};

export default TodoItem;
