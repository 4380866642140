import React, { useState, useEffect } from 'react';
import { useView, useCache, useSendbird, useNavigation } from '../../context';
import {Page, TransactionList, ActivityDisplay} from '../../components'
import { IconButton, Typography, Box, CircularProgress } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
const GoBack = ({setSelectedId}) => {
  return (
    <Box display="flex" alignItems="center"  onClick={()=>setSelectedId(null)} sx={{ ml: 1}}>
      <IconButton  onClick={()=>setSelectedId(null)}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Activity</Typography>
    </Box>
  );
};

const ActivityPage = () => {
  const {PANELS, panel: currentPanel, setPanel: setNavigationPanel, state: initialTx} = useNavigation()
  const ActivityPanels = [PANELS.ACTIVITY_DISPLAY, PANELS.ACTIVITY_DISPLAY]
  const {transactions} = useCache()
  const [selectedTXID, setSelectedId] = useState(null);
  const { isMobileLayout } = useView();
  const handleSelect = (tx, panel) => {
    setSelectedId(tx?.id)
    setNavigationPanel(panel)
  }
  const tx = transactions[selectedTXID] || null
  const activityList = <TransactionList selectedSharable={ (tx) => handleSelect(tx, PANELS.ACTIVITY_DISPLAY)}/>
  const display = <ActivityDisplay tx={tx} />
  useEffect(() => {
    if(!initialTx) return
    handleSelect(initialTx, PANELS.ACTIVITY_DISPLAY)
  }, [initialTx]);
  
  return (
    <Page>
      {isMobileLayout && <Box sx={{width: "100%", mt: 1}}>
        {tx && <GoBack setSelectedId={ () => handleSelect(null, PANELS.ACTIVITY_LIST)}/>}
        {currentPanel == PANELS.ACTIVITY_DISPLAY ? display :  activityList}
        </Box>}
      
      
      {!isMobileLayout && 
      <Box sx={{width: "100%", display:"flex", flexDirection:"row"}}>
          {activityList}
          {display}
        </Box>
    
      }
    </Page>
  );
};

export default ActivityPage;
