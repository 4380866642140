import {useCache, useBackend} from '../../../context'
import TransactionCard from './TransactionCard'
import ScrollList from '../ScrollList';
import { connect } from 'react-redux';
const TransactionList = (props) => {
    const {txArray,hasMore, getTXsinprogress, notificationChannel} = props
    const {onLoadPrevTransactions} = useBackend()

    const components = txArray.map((tx) => <TransactionCard tx={tx} notificationChannel={notificationChannel}/>)
    return (
      <ScrollList 
      items={txArray}
      components={components}
      hasMore={hasMore}
      loadMore={onLoadPrevTransactions}
      loadMoreinprogress={getTXsinprogress}
      {...props}
    />
    );
  };
  
  const mapStateToProps = (state) => {
    const {transactions} = state.marketplaceData
    const {notificationChannel} = state.sendbird
    const {hasMore, getTXsinprogress} = state.transaction
    const txArray = Object.values(transactions).sort((a, b) => b.lastupdate - a.lastupdate);
    return {
      txArray: txArray,
      hasMore,
      getTXsinprogress,
      notificationChannel
    };
  };
  
  
  export default connect(mapStateToProps, )(TransactionList);