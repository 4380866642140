import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
import RangeStack from './RangeStack';

const MainDropArea = ({ items = [], setItems }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'main-drop-area',
  });

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        backgroundColor: isOver ? 'lightgrey' : 'transparent',
        padding: 1,
        minHeight: 200,
        border: `2px dashed ${isOver ? 'blue' : 'grey'}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
  }}
    >
      {items.length === 0 ? (
        <Typography variant="h5" color="textSecondary" align="center">
          Drag days here
        </Typography>
      ) : (
        <RangeStack items={items} setItems={setItems} />
      )}
    </Paper>
  );
};

export default MainDropArea;
