import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Tab as TabNav, Tabs, Box } from '@mui/material';
import { WORKER_ROLE, CUSTOMER_ROLE, TAB_NAMES } from '../../../util/constants';
import SettingsTab from './SettingsTab/SettingsTab';
import ProfileTab from './ProfileTab/ProfileTab';
import PersonalTab from './PersonalTab/PersonalTab'
import FrontBusinessCardTab from './FrontBusinessCardTab/FrontBusinessCardTab';
import BackBusinessCardTab from './BackBusinessCardTab/BackBusinessCardTab';

import ImagesTab from './ImagesTab/ImagesTab';
import css from './TabWizard.module.css';
import { PrimaryButton } from '../../../components';
import { useCache } from '../../../context';

const TABS_COMPONENTS = {
  [TAB_NAMES.LAST.SETTINGS]: SettingsTab,
  [TAB_NAMES.FIRST.PROFILE]: ProfileTab,
  [TAB_NAMES.BUSINESS.FRONTBUSINESSCARD]: FrontBusinessCardTab,
  [TAB_NAMES.BUSINESS.BACKBUSINESSCARD]: BackBusinessCardTab,
  [TAB_NAMES.BUSINESS.IMAGES]: ImagesTab,
  
};

const getTabs = (intl, role, setTabButton) => {
  const { FIRST, USER, BUSINESS, LAST } = TAB_NAMES;
  let tabids;

  switch (role) {
    case CUSTOMER_ROLE:
      tabids = [FIRST.PROFILE, ...Object.values(USER), LAST.SETTINGS];
      break;
    case WORKER_ROLE:
      tabids = [FIRST.PROFILE, ...Object.values(USER), ...Object.values(BUSINESS), LAST.SETTINGS];
      break;
    default:
      tabids = [];
  }

  return tabids
    .map((tabid) => {
      const TabComponent = TABS_COMPONENTS[tabid];
      if (!TabComponent) return null;

      return {
        title: intl.formatMessage({ id: `Tabs.${tabid}.title` }),
        tabComponent: <TabComponent  setTabButton={(button) => setTabButton(tabid, button)} />,
        tabid,
      };
    })
    .filter(Boolean);
};

const TabPanel = ({ children, value, index, tabButton }) => {
  return  (
    value === index && (
      <Box sx={{ p: 3 }} className={css.panel}>
        {children}
        {tabButton && tabButton.onSubmit && (
          <PrimaryButton
            message={tabButton.message || 'Submit'}
            onClick={tabButton.onSubmit}
            inprogress={tabButton.inprogress}
            disabled={tabButton.disabled}
            sx={{ width: '50%', marginTop: '20px' }}
          />
        )}
      </Box>
    )
  );
}

const TabWizard = ({loading, intl, }) => {
  const [value, setValue] = useState(0);
  const [tabButtons, setTabButtons] = useState({});
  const {user, loaded} = useCache()
  const role = user?.role

  const handleTabChange = (event, tabNumber) => setValue(tabNumber);
  const setTabButton = useCallback((tabid, button) => {
    setTabButtons((prev) => ({
      ...prev,
      [tabid]: button
    }));
  }, []);


  useEffect(() => {
    setValue(0); // reset to first tab if role toggled from settings tab
  }, [role]);


  const tabs = getTabs(intl, role,  setTabButton);
  if (value >= tabs.length) return null;
  const navTabs = (
    <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={value} onChange={handleTabChange} aria-label="tabs">
      {tabs.map((tab, index) => (
        <TabNav key={tab.tabid} label={tab.title} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
      ))}
    </Tabs>
  );

  const tabINBOX_PANELS = tabs.map((tab, index) => (
    <TabPanel key={tab.tabid} value={value} index={index} tabButton={tabButtons[tab.tabid]}>
      {loaded? tab.tabComponent : <CircularProgress/>}
    </TabPanel>
  ));

  return (
    <div className={css.root}>
      {navTabs}
      {tabINBOX_PANELS}
    </div>
  );
};

export default TabWizard;
