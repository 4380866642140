

import React, { useState, useEffect } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';
import {  Page,  } from '../../components';
import Box from '@mui/material/Box';
import CustomChannel from './CustomChannel/CustomChannel'
import { useCache, useNavigation, useSendbird } from '../../context';
import CustomChannelList from './CustomChannelList/CustomChannelList'
export const InboxPageComponent = () => {
  const {isMobileLayout, handleSelectChannel} = useSendbird();
  const {PANELS, state: initialChannel, panel: currentPanel} = useNavigation();
  const channelList = <CustomChannelList onChannelSelect={handleSelectChannel}/> 
  const channel = <CustomChannel/>
  const panel = currentPanel === PANELS.CHANNEL ? channel : channelList;
  // might be a good idea to set this using sendbird hook before moving in here instead of passing it as a state
  useEffect(() => {
    if(!initialChannel) return
    handleSelectChannel(initialChannel)
  }, [initialChannel]);


  return (
    <Page>
      {isMobileLayout ? <Box sx={{height: "100%", width:"100%"}}>{panel}</Box>: 
      <Box sx={{ display: 'flex', flexDirection: 'row', height: "100%", width:"100%"}}>
        {channelList}
        {channel}
        
      </Box>
      }
    </Page>
  );
  



  
};


const mapStateToProps = state => {
  return {
};
}

const mapDispatchToProps = dispatch => ({

});

const InboxPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(InboxPageComponent);

export default InboxPage;
