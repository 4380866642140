import React from 'react';
import { Box, Typography, LinearProgress, styled } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DoneIcon from '@mui/icons-material/Done';
import { formatTimeForLastUpdate } from '../../util/utils';

const StyledLinearProgress = styled(({ progressPart, ...other }) => <LinearProgress {...other} />)(
  ({ theme, progressPart }) => ({
    height: 20, // Increase the height to make it thicker
    borderRadius: 5,
    border: '1px solid #000', // Add black border
    backgroundColor: theme.palette.action.disabledBackground,
    '& .MuiLinearProgress-bar': {
      backgroundColor: progressPart ? theme.palette.primary.main : theme.palette.action.disabled,
    },
  })
);

const Progressbar = ({ progress, sx }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { scheduled_for, completed_at } = progress;

  const scheduledExists = scheduled_for > 0;
  const completedExists = completed_at > 0;

  return (
    <Box sx={{ ...sx, p: 2, border: '1px solid #d0d0d0', borderRadius: 2, bgcolor: '#fafafa' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#333' }}>
        Progress
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <ScheduleIcon fontSize="small" sx={{ color: scheduledExists ? '#000' : '#999' }} />
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: scheduledExists ? '#333' : '#999' }}>
            Arriving
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', color: '#666' }}>
            {scheduledExists ? formatTimeForLastUpdate(scheduled_for, timezone) : 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <DoneIcon fontSize="small" sx={{ color: completedExists ? '#000' : '#999' }} />
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: completedExists ? '#333' : '#999' }}>
            Completed
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', color: '#666' }}>
            {completedExists ? formatTimeForLastUpdate(completed_at, timezone) : 'N/A'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ flexGrow: 1, mr: 0.5 }}>
          <StyledLinearProgress
            variant="determinate"
            value={100}
            progressPart={scheduledExists}
            sx={{ borderRadius: '5px 0 0 5px' }}
          />
        </Box>
        <Box sx={{ flexGrow: 1, ml: 0.5 }}>
          <StyledLinearProgress
            variant="determinate"
            value={100}
            progressPart={completedExists}
            sx={{ borderRadius: '0 5px 5px 0' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Progressbar;
