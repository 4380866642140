import React, {useEffect} from 'react';
import {Box, CssBaseline } from '@mui/material';
import Topbar from '../Bars/Topbar';
import Bottombar from '../Bars/Bottombar'; // Assuming you have a Bottombar component
import { useNavigation } from '../../context';

const Page = ({ children }) =>  {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        
        
      }}
    >
      <CssBaseline />
      <Topbar/>
      <Box
        sx={{
          overflowY: 'auto',
          display:"flex",
          flexDirection: "column",
          alignItems:"center",
          width: "100%",
          height:"100%",
        }}
      >
        {children}
      </Box>
      <Bottombar />
    </Box>
  );
};

export default Page;
