// src/components/Availability/PeriodSelector.js
import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { PERIODS } from '../../../util/constants';
import DraggableChip from '../CoreDraggable/DraggableChip'

/**
 * PeriodSelector Component
 *
 * Renders a list of draggable period chips.
 */
const PeriodSelector = () => {
  return (
    <Box>
      <Typography variant="h6">Periods</Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {PERIODS.map((period) => (
          <DraggableChip
            key={period.id}
            id={period.id}
            data={{
              label: period.label,
              type: "period"
            }}
            color="primary"
          />
        ))}
      </Stack>
    </Box>
  );
};

export default PeriodSelector;
