// src/components/BucketForm.js
import React, { useState } from 'react';
import { Box } from '@mui/material';
import MainDropArea from './MainDropArea';
import {
  DndContext,
} from '@dnd-kit/core';
import TimeSelector from './TimeSelector.js';
import { categories } from '../../../util/categories';
import ItemSelector from './ItemSelector';
import useBucketDnd from './useBucketDnd'; 
import useDropEnd from './useDropEnd'
const BucketForm = ({ showTimeSelector, data, setData }) => {
  const [activeCategory, setActiveCategory] = useState(Object.keys(categories)[0]);
  const {
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
  } = useBucketDnd({
    data,
    setData,
    activeCategory,
    applicationHandleDragEnd: useDropEnd
  });

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: 1,
        display: 'flex',           // Enable Flexbox
        flexDirection: 'column',
        gap: 1
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <ItemSelector activeCategory={activeCategory} setActiveCategory={setActiveCategory}/>
        {showTimeSelector && <TimeSelector onSelectTime={(time) => console.log(time)} />}
        {/* Push MainDropArea to the bottom */}
        <Box sx={{ marginTop: 'auto' }}>
          <MainDropArea
            items={data}
            setItems={setData}
            showTimeSelector={showTimeSelector}
          />
        </Box>
      </DndContext>
    </Box>
  );
};

// Exported Forms
export const InvoiceForm = ({ data, setData }) => {
  return <BucketForm showTimeSelector data={data} setData={setData} />;
};

export const TodoForm = ({ data, setData }) => {
  return (
    <BucketForm 
      showTimeSelector={false} 
      data={data} 
      setData={setData} 
    />
  );
};

export default BucketForm;
