import React from 'react';
import css from './SmartImage.module.css';
import classNames from 'classnames';
import { Img } from 'react-image';
import { ClipLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';
import { Box, } from '@mui/material';

const SmartImage = (props) => {
  const { img, index, imageRoot } = props;
  const className = classNames(css.root, imageRoot);
  const key = `${index}_listingImage`;

  return (
    <div className={css.imageContainer}>
      <Img
        src={img.url}
        alt={key}
        className={className}
        loader={<Box
          sx={{
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <Skeleton height={400} width="100%" />
        </Box>}
        unloader={<div >error</div>}
      />
    </div>
  );
};

export default SmartImage;
