import React from 'react';
import BusinessCard from './BusinessCard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { PrimaryButton } from '../../components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Skeleton component for loading placeholders
const SkeletonBusinessCard = () => (
  <Box padding={2}>
    <Skeleton height={180} width="100%" />
    <Skeleton height={20} width="80%" style={{ marginTop: 8 }} />
    <Skeleton height={20} width="60%" style={{ marginTop: 8 }} />
  </Box>
);

const SearchGrid = ({ businesses, hasMore, loadMore, loadInProgress }) => {
  // Define how many skeleton cards you want to show
  const skeletonCount = 8;

  return (
    <Box padding="0 25px" width="100%" boxSizing="border-box" maxWidth="1600px">
      <Grid container spacing={2}>
        {loadInProgress
          ? Array.from({ length: skeletonCount }).map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <SkeletonBusinessCard />
              </Grid>
            ))
          : businesses.map((business) => (
              <Grid item key={business.id} xs={12} sm={6} md={4} lg={3}>
                <BusinessCard business={business} />
              </Grid>
            ))}
      </Grid>
      {hasMore && !loadInProgress && (
        <Box display="flex" justifyContent="center" sx={{mt:2, mb: 2}}>
          <PrimaryButton
            onClick={loadMore}
            inprogress={loadInProgress}
            startIcon={!loadInProgress && <ArrowDownwardIcon />}
            message={"Show More"}
          />
        </Box>
      )}
    </Box>
  );
};

export default SearchGrid;
