import React, { useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { TextField, Rating, Box } from '@mui/material';
import {useCache} from '../../../context'
const ReviewForm = ({tx,  setReview }) => {
  const {user} = useCache()
  const { control, setValue, formState: { errors } } = useForm({
    defaultValues: {
      rating: 0,
      message: '',
    }
  });
  
  const formValues = useWatch({ control });

  useEffect(() => {
    setReview(formValues);
  }, [formValues, setReview]);

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        margin: '0 auto',
        padding: 2,
        border: '1px solid #ccc',
        borderRadius: 2,
      }}
    >
      <Controller
        name="rating"
        control={control}
        rules={{ required: 'Rating is required' }}
        render={({ field }) => (
          <Rating
            {...field}
            onChange={(e, newValue) => setValue('rating', newValue)}
          />
        )}
      />
      {errors.rating && <p>{errors.rating.message}</p>}

      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Review Message"
            variant="outlined"
            multiline
            rows={4}
          />
        )}
      />
    </Box>
  );
};

export default ReviewForm;
