// src/components/Forms/AvailabilityForm/AvailabilityForm.js

import React from 'react';
import { Box } from '@mui/material';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import DaySelector from './DaySelector';
import PeriodSelector from './PeriodSelector';
import MainDropArea from './MainDropArea';
import RangeStack from './RangeStack';
import useAvailabilityDnd from './useAvailabilityDnd';

/**
 * AvailabilityForm Component
 *
 * Main component for managing availability ranges and periods.
 *
 * @param {Object} props
 * @param {Array} props.data - Current availability ranges.
 * @param {Function} props.setData - State updater for availability ranges.
 */
const AvailabilityForm = ({ data, setData }) => {
  // Utilize the custom hook
  const {
    sensors,
    activeItem,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
  } = useAvailabilityDnd(data, setData);

  return (
    <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          padding: 1,
          display: 'flex',          
          flexDirection: 'column',
          gap: 1
          }}
      >
        
          <DaySelector />
          <PeriodSelector />
          <Box sx={{ marginTop: 'auto' }}>
          <MainDropArea items={data} setItems={setData} />
        </Box>

        
      </Box>
    </DndContext>
  );
};

export const AvailabilityDisplay = ({ items }) => (
  <RangeStack items={items} setItems={() => {}} onlyDisplay />
);

export default AvailabilityForm;
