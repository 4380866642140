// src/components/Availability/RangeAreaWithPeriods.js

import React from 'react';
import {
  Paper,
  Typography,
  Chip,
  IconButton,
  Box,
  Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { capitalizeFirstChar } from '../../../util/utils';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

const RangeAreaWithPeriods = ({
  range: { id, startday, endday, periods = [] },
  onRemoveRange,
  onRemovePeriod,
  onlyDisplay,
}) => {
  const { isOver, setNodeRef } = useDroppable({ id }); // Make the range a droppable area

  /**
   * Generates the display name for the range based on start and end days.
   *
   * @param {string} start - The starting day of the range.
   * @param {string} end - The ending day of the range.
   * @returns {JSX.Element} - The formatted range name with labels.
   */
  const renderRangeName = (start, end) => {
    const capitalizedStart = capitalizeFirstChar(start);
    const capitalizedEnd = capitalizeFirstChar(end);

    if (start === end) {
      return (
        <Typography variant="h6" component="div" fontWeight={500}>
          {capitalizedStart}
        </Typography>
      );
    }

    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        {/* Start Day */}
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
            Start:
          </Typography>
          <Typography
            variant="h6"
            component="div"
            fontWeight={500}
            sx={{ ml: 0.5 }}
          >
            {capitalizedStart}
          </Typography>
        </Box>

        {/* End Day */}
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
            End:
          </Typography>
          <Typography
            variant="h6"
            component="div"
            fontWeight={500}
            sx={{ ml: 0.5 }}
          >
            {capitalizedEnd}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <Paper
      ref={setNodeRef}
      elevation={isOver ? 6 : 3} // Increase elevation when hovered
      sx={{
        position: 'relative',
        px: 1,
        py: 2,
        backgroundColor: isOver ? 'info.light' : 'background.paper', // Updated hover color
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider', // Define the border color
        overflow: 'hidden', // Prevent content overflow
        flex: '0 0 auto', // Prevent shrinking
        width: '300px', // Ensure a minimum width
      }}
    >
      {/* Remove Range Button positioned at the top-right corner */}
      {!onlyDisplay && (
        <IconButton
          size="small"
          onClick={() => onRemoveRange(id)}
          aria-label={`Remove range ${
            startday === endday
              ? capitalizeFirstChar(startday)
              : `${capitalizeFirstChar(startday)} to ${capitalizeFirstChar(endday)}`
          }`}
          color="error"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            padding: '4px', // Reduce padding for a smaller button
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}

      {/* Header: Range Name */}
      <Box>
        {/* Range Name with Start and End Labels */}
        {renderRangeName(startday, endday)}
      </Box>

      {/* Periods */}
      <Box mt={2}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Periods:
        </Typography>
        {periods.length > 0 ? (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{
              maxHeight: '150px', // Adjust as needed
              overflowY: 'auto',
            }}
          >
            {periods.map((period) => (
              <Chip
                key={period}
                label={capitalizeFirstChar(period)}
                color="secondary"
                onDelete={
                  !onlyDisplay
                    ? () => onRemovePeriod({ day: id, period })
                    : undefined
                }
                sx={{ mb: 1 }}
                aria-label={`Remove period ${capitalizeFirstChar(period)} from ${
                  startday === endday
                    ? capitalizeFirstChar(startday)
                    : `${capitalizeFirstChar(startday)} to ${capitalizeFirstChar(endday)}`
                }`}
              />
            ))}
          </Stack>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No periods assigned.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

// Define PropTypes for better type checking
RangeAreaWithPeriods.propTypes = {
  range: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startday: PropTypes.string.isRequired,
    endday: PropTypes.string.isRequired,
    periods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onRemoveRange: PropTypes.func.isRequired,
  onRemovePeriod: PropTypes.func.isRequired,
  onlyDisplay: PropTypes.bool,
};

RangeAreaWithPeriods.defaultProps = {
  onlyDisplay: false,
};

export default RangeAreaWithPeriods;
