import React, {useState, createContext, useContext, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import { compile } from 'path-to-regexp';

import { getPathName, PAGE_NAMES, PANELS, routeConfiguration } from '../routing/routeConfiguration';
import { useAuth0 } from '@auth0/auth0-react';




const NavigationContext = createContext();
export const generateDynamicPath = (path, params={}) => {
  const pathGenerator = compile(path);
  return pathGenerator(params);
};


export const NavigationProvider = ({ children }) => {
  const [panel, setPanel] = useState()
  const navigate = useNavigate ();
  const location = useLocation();
  const {isAuthenticated} = useAuth0()
  const url_segments = location.pathname.split('/').filter(segment => segment !== '');
  const page = getPathName(url_segments.length && url_segments[0] || "/")
  const subpage = url_segments.length && url_segments[1]
  const findPath = (name, params) =>{
    const route = routeConfiguration.find(route => route.name === name)
    if(route.auth && !isAuthenticated)
        return '/login'
      
    return generateDynamicPath(route.path, params)  
  }


  useEffect(() => {
    if(!page) return 
    
    const route = routeConfiguration.find(route => route.name === page)
    if(route.auth && !isAuthenticated) 
      navigate('/login')
  }, [page]);


  const navigateByName = (name, params) => {
    const path = findPath(name, params)
    navigate(path, params)
  }

  const enhancedLocation = {
    page,
    subpage,
    ...location
  }

  return (
    <NavigationContext.Provider value={{ 
      findPath, 
      navigateByName,
      panel,
      setPanel,  
      navigate,
      PAGE_NAMES,
      PANELS,
      ...enhancedLocation
     }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
