

// ================ Action types ================ //
import {OPERATION_GET_BUSINESS_DATA,  } from '../../util/constants'
import {fetchJson} from '../../util/api'


import { getBusiness,  } from '../../util/api';
import {
 getCachedBusiness, upsertBusiness,
} from '../../ducks/marketplaceData.duck';



export const FETCH_REVIEWS_REQUEST = 'app/BusinessPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/BusinessPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/BusinessPage/FETCH_REVIEWS_ERROR';








// ================ Reducer ================ //

const initialState = {
  id: null,
  activeTx: null,
  reviews: [],
  fetchReviewsError: null,
};

const BusinessPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    


    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };



  


  
    default:
      return state;
  }
};

export default BusinessPageReducer;

// ================ Action creators ================ //






export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});






// ================ Thunks ================ //





export const fetchBusiness = bid => (dispatch, getState) => {
  const fetchReviews = (bid) => async (dispatch, getState) => {
    const base_url = `${process.env.REACT_APP_CLOUDFRONT_REVIEWS}/${bid}`;
    const manifest_url = `${base_url}/manifest.json`;
    let manifest = [];
    let reviews = [];
  
    try {
      manifest = await fetchJson(manifest_url);
      for (const r of manifest) {
        const review_url = `${base_url}/${r.id}.json`;
        const review = await fetchJson(review_url);
        reviews.push(review);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      return [];
    }
  
    dispatch(upsertBusiness({ id: bid, reviews }));
  
    return reviews;
  };
    
  
  // might be better to fetch images gradually instead of waiting to load all. we need to check total size to confirm.
   const fetchImages = (bid) => async (dispatch, getState) => {
    const base_url = `${process.env.REACT_APP_CLOUDFRONT_SERVICE_IMAGES}/${bid}`;
    const manifest_url = `${base_url}/manifest.json`;

    try {
      const images = await fetchJson(manifest_url);

      if (!images || images.length === 0) {
        return []
      }
  
      dispatch(upsertBusiness({ id: bid, images }));
      return images;
  
    } catch (error) {
      console.error('Error fetching images:', error);
  
      return []
    }
  };
  
  const cachedBusiness = dispatch(getCachedBusiness(bid));
  if(cachedBusiness?.categories) return Promise.resolve(cachedBusiness);
  
  const business_promise = getBusiness({ bid, op: OPERATION_GET_BUSINESS_DATA })
  
  const images_promise = dispatch(fetchImages(bid))
  const reviews_promise = dispatch(fetchReviews(bid))

  business_promise.then(res=>{
    const business = res.data
    dispatch(upsertBusiness(business))
  })
  // lazy loading reviews and images
  Promise.all([business_promise,  images_promise, reviews_promise]).then(res =>{
    const images = res[0]['images']
    const reviews = res[1]
    const BUSINESS = {bid, images, reviews}
    dispatch(upsertBusiness({...BUSINESS, fetchBusinessinprogress: false}));

    return Promise.resolve(BUSINESS)
  })

  // return business_promise immediately.
  return business_promise.then(res=>{
    return res.data
  })
};








