import { UPLOAD_SERVICE_IMAGE_REQUEST, OPERATION_UPDATE_BUSINESS, OPERATION_GET_OWN_BUSINESS, UPDATE_IMAGE_MANIFEST, DELETE_SERVICE_IMAGE, UPLOAD_AVATAR_REQUEST } from '../util/constants'

import {resizeImage, uploadFileToS3, postBeavrUser, postOwnBusiness, getOwnBusiness} from '../util/api'

import {decodeMessage, parseList} from '../util/utils'


const UPDATE_OWNBUSINESS_REQEUST = 'app/user/UPDATE_OWNBUSINESS_REQEUST';
const UPDATE_OWNBUSINESS_SUCCESS = 'app/user/UPDATE_OWNBUSINESS_SUCCESS';
const UPDATE_OWNBUSINESS_ERROR = 'app/user/UPDATE_OWNBUSINESS_ERROR';





export const updateBusinessRequest = (updateType) => ({ type: UPDATE_OWNBUSINESS_REQEUST, payload: updateType });


export const updateBusinessError = (e) => ({
  type: UPDATE_OWNBUSINESS_ERROR,
  payload: e
});


export const updateBusinessSuccess = (payload) => ({
  type: UPDATE_OWNBUSINESS_SUCCESS,
  payload: payload
});




const initialState = {
  ownBusiness: null,
  updateBusinessInProgress: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_OWNBUSINESS_REQEUST:
      return { ...state, updateBusinessInProgress: payload };
    case UPDATE_OWNBUSINESS_ERROR:
      return { ...state, updateBusinessInProgress: '' };

    case UPDATE_OWNBUSINESS_SUCCESS:
      const { ownBusiness: newOwnBusiness } = payload;
      newOwnBusiness?.workschedules && (newOwnBusiness.workschedules = parseList(newOwnBusiness.workschedules));
      newOwnBusiness?.bio && (newOwnBusiness.bio = decodeMessage(newOwnBusiness.bio));
      return {
        ...state,
        ownBusiness: {...state.ownBusiness, ...newOwnBusiness},
        updateBusinessInProgress: ''
      };      

    default:
      return state;
  }
}










export const deleteServiceImage = (imgId) => (dispatch, getState) => {
  const {images: currentImages} = getState().business.ownBusiness
  const filteredImages = currentImages.filter(obj => obj.id !== imgId);
  dispatch(updateBusinessSuccess({ownBusiness: {images: filteredImages}}))
  postOwnBusiness({op: DELETE_SERVICE_IMAGE, imgId})
}

export const uploadServiceImages = (bid, files) => (dispatch, getState) =>{
  const uploadServiceImage = (imageFile) => (dispatch, getState) => {
    return resizeImage(imageFile)
      .then(resizedImageFile => {
        // Request to get the upload URL
        return postOwnBusiness({ op: UPLOAD_SERVICE_IMAGE_REQUEST })
          .then( response => {
            const {uploadUrl, ...img } = response.data;
            // Upload the file to S3
            return uploadFileToS3(uploadUrl, resizedImageFile).then(()=>{
              img.url = `${process.env.REACT_APP_CLOUDFRONT_SERVICE_IMAGES}/${bid}/${img.id}`
              return img;
            })
            
          });
      })
      .catch(error => {
        console.error("Error uploading image:", error);
        throw error;
      });
  };
  
  dispatch(updateBusinessRequest(UPLOAD_SERVICE_IMAGE_REQUEST));
  const promises = []
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const promise = dispatch(uploadServiceImage(file));
    promises.push(promise);
  }
  Promise.all(promises).then(uploadedImages => {
    postOwnBusiness({ op: UPDATE_IMAGE_MANIFEST })
    const {images: currentImages} = getState().business.ownBusiness
    dispatch(updateBusinessSuccess({ownBusiness: {images: [...uploadedImages, ...currentImages]}}))
    
  }) 
}

export const uploadAvatar = (imgFile) => (dispatch) => {
  return resizeImage(imgFile).then(resizedImageFile=>{
    return postOwnBusiness({ op: UPLOAD_AVATAR_REQUEST })
    .then( async response => {
      const {uploadUrl, ...img } = response.data;
      // Upload the file to S3
      await uploadFileToS3(uploadUrl, resizedImageFile)
      return img
    });
  })
  
}
export const updateOwnBusiness = (data) => (dispatch, getState) => {
  const {op = OPERATION_UPDATE_BUSINESS} = data
  const {id} = getState().business?.ownBusiness
  dispatch(updateBusinessRequest(op))
  
  return postOwnBusiness({op, ...data}).then((res) => {
    dispatch(updateBusinessSuccess({ownBusiness: {id, ...data}}))
  })
}

export const fetchOwnBusiness = () =>  (dispatch, getState) => {
  const {updateBusinessInProgress} = getState().business
  if(updateBusinessInProgress) return
  dispatch(updateBusinessRequest('fetch'))
  return  getOwnBusiness({op: OPERATION_GET_OWN_BUSINESS}).then((response) =>
    {
      const business = response.data;
      dispatch(updateBusinessSuccess({ownBusiness: business}))

    }
    
    ).catch(e => dispatch(console.log(e)))
}





export const calculateCost = (lines, hourly, minCharge) => {
  let total_time = 0
  for(const line of lines){
    total_time += line.time
  }

  const total_price = total_time * hourly / 60
  const remainder = minCharge > total_price? minCharge - total_price : 0;
  const final_price = remainder ? minCharge : total_price
  return {
    total_time,
    total_price, 
    final_price,
    remainder,
    hourly,
    minCharge
  }
}