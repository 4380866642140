import React, { useState } from 'react';
import { Box, List, ListItem, Skeleton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { PrimaryButton } from '../Button/Button';

const ScrollList = (props) => {
  const {
    items = [],
    components = [],
    hasNew = false,
    loadNew = () => {},
    hasMore,
    loadMore,
    loadMoreinprogress,
    selectedSharable = () => {},
    sx = {},
  } = props;

  const [selected, setSelected] = useState(null);
  const handleItemClick = (index) => {
    setSelected(index);
    selectedSharable(items[index]);
  };

  const skeletonCount = 3; // Number of skeleton items to display

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 0,
        margin: 0,
        ...sx,
      }}
    >
      <List
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          padding: 0,
          margin: 0,
        }}
      >
        {hasNew && (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 0, margin: 0 }}>
            <PrimaryButton
              onClick={loadNew}
              startIcon={<ArrowUpwardIcon />}
              disabled={!hasNew}
              message={"Next"}
              sx={{ mt: 0, mb: 1 }}
            />
          </Box>
        )}
        {components.map((item, index) => (
          <ListItem
            key={index}
            selected={selected === index}
            onClick={() => handleItemClick(index)}
            sx={{
              cursor: 'pointer',
              width: '100%',
              textAlign: 'left',
              padding: 0,
              margin: 0,
            }}
          >
            {item}
          </ListItem>
        ))}

        {hasMore && (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 0, margin: 0 }}>
            <PrimaryButton
              onClick={loadMore}
              inprogress={loadMoreinprogress}
              startIcon={!loadMoreinprogress && <ArrowDownwardIcon />}
              disabled={!hasMore}
              message={"Load More"}
              sx={{ mt: 1 }}
            />
          </Box>
        )}

        {loadMoreinprogress && (
          <Box sx={{ padding: 0, margin: 0 }}>
            {Array.from({ length: skeletonCount }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height={60}
                sx={{ mt: 1, mb: 1 }}
              />
            ))}
          </Box>
        )}
      </List>
    </Box>
  );
};

export default ScrollList;
