import React from 'react';
import { NamedLink } from '../../components';
import css from './LinkedLogo.module.css';

const logoUrl = 'https://beavrassets.s3.amazonaws.com/logo-desktop.png';

const LinkedLogo = () => (
  <NamedLink name="SearchPage" className={css.logoLink}>
    <img src={logoUrl} alt="Logo" className={css.logoImage} />
  </NamedLink>
);

export default LinkedLogo;
