import React from 'react';
import { Controller } from 'react-hook-form';
import { FieldTextInput } from '../../../components';
import { BUSINESS_BIO_PLACEHOLDER, CUSTOMER_BIO_PLACEHOLDER } from '../../../util/constants';
import { useCache } from '../../../context';

const BioForm = ({ control }) => {
  const { isCustomer } = useCache();

  return (
    <form>
      <FieldTextInput
          name="bio"
           control={control}
            placeholder={isCustomer ? CUSTOMER_BIO_PLACEHOLDER : BUSINESS_BIO_PLACEHOLDER}
            multiline
            rows={4}
            fullWidth
          />
    </form>
  );
};

export default BioForm;
