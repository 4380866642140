import React, { useState } from 'react';
import { Box, Button, IconButton, InputBase, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close'; // Close icon added
import ItemSettingsModal from './ItemSettingsModal';
import { useTheme } from '@mui/material/styles';

/**
 * Generic UI Component for Manager Header
 */
const ManagerHeaderUI = ({
  sharables,
  selectedSharable,
  setSelectedSharable,
  addSharable,
  deleteSharable,
  updateSharable,
  type,
  onClose // onClose function to handle drawer closing
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For menu dropdown
  const theme = useTheme();

  const handleAddClick = () => {
    addSharable(`new item ${sharables.length}`, type);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (item) => {
    setSelectedSharable(item);
    setAnchorEl(null);
  };

  return (
      <Box
        width="100%"
        boxShadow={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Close Drawer Button */}
        {onClose && <IconButton onClick={onClose} sx={{ color: 'black' }}>
          <CloseIcon />
        </IconButton>}

        {/* Centered Sharable Selection */}
        <Button
          onClick={handleMenuClick}
          sx={{
            margin: '0 auto',
            color: 'black',
            backgroundColor: selectedSharable?.isDefault
              ? theme.palette.secondary.light
              : 'rgba(0, 0, 0, 0.1)',
            border: '1px solid black',
          }}
        >
          {selectedSharable ? selectedSharable.title : `Select ${type}`}
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {sharables?.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleMenuClose(item)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>

        {/* Action Buttons */}
        <Box>
          <IconButton onClick={handleAddClick} sx={{ color: 'black' }}>
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => setModalOpen(true)}
            sx={{ color: 'black' }}
            disabled={!selectedSharable}
          >
            <SettingsIcon />
          </IconButton>
        </Box>

        <ItemSettingsModal
          open={modalOpen}
          setModalOpen={setModalOpen}
          selectedSharable={selectedSharable}
          updateSharable={updateSharable}
          onDelete={deleteSharable}
          sharables={sharables}
          type={type}
        />
      </Box>
  );
};

/**
 * Specific Header Component for Locations
 */
export const ManagerHeader = (props) => {
  const {
    sharables,
    selectedSharable,
    setSelectedSharable,
    addSharable,
    deleteSharable,
    updateSharable,
    type,
    onClose,
  } = props;

  return (
    <ManagerHeaderUI
      sharables={sharables}
      selectedSharable={selectedSharable}
      setSelectedSharable={setSelectedSharable}
      addSharable={addSharable}
      deleteSharable={deleteSharable}
      updateSharable={updateSharable}
      type={type}
      onClose={onClose} 
    />
  );
};

export default ManagerHeader;
