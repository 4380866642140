import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { useView, useNavigation, useSendbird } from '../../context';
import BottombarMobile from './Mobile/BottombarMobile';
import BottombarBusiness from './Mobile/BottombarBusiness';

const Bottombar = () => {
  const { isDesktopLayout } = useView();
  const { page, PAGE_NAMES, PANELS, panel: currentPanel } = useNavigation();

  if (isDesktopLayout) return null;

  const isBusinessPage = page === PAGE_NAMES.BusinessPage;
  const isChannel = page === PAGE_NAMES.InboxPage && currentPanel === PANELS.CHANNEL;
  const isTxDisplay = page === PAGE_NAMES.ActivityPage && currentPanel === PANELS.ACTIVITY_DISPLAY;
  const isMobileBar = !isBusinessPage && !isChannel;

  const bar = isBusinessPage ? (
    <BottombarBusiness />
  ) : isChannel ? null : isTxDisplay ? null : (
    <BottombarMobile />
  );

  if (!bar) return null;

  return (
    <AppBar position="sticky" sx={{ top: 'auto', bottom: 0, width: '100%' }}>
      <Toolbar
        sx={{
          backgroundColor: 'var(--barBackgroundColor)',
          borderTop: !isMobileBar && 'var(--barBorder)',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          minHeight: '56px', // Ensure this matches the height
        }}
      >
        {bar}
      </Toolbar>
    </AppBar>
  );
};

export default Bottombar;
