// MapDrawer.js
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import {SharableDrawer } from '../../../components';
import DrawerIcon from './DrawerIcon'; // Adjust the import path as necessary

const MapDrawer = React.memo(({ isInitialized }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetIsOpen = useCallback((open) => {
    setIsOpen(open);
  }, []);

  return (
    <Box>
      <DrawerIcon component={<PublicIcon />} setIsOpen={handleSetIsOpen} isInitialized={isInitialized} />
      <SharableDrawer isOpen={isOpen} setIsOpen={setIsOpen} type="locations" />
    </Box>
  );
});

export default MapDrawer;
