import {DAYS_OF_WEEK, EDGE_TEMPLATES} from './constants'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {calculateCost} from '../ducks/business.duck'
import { 
  NOTIFICATION_UPDATE_SCHEDULE, 
  NOTIFICATION_UPDATE_INVOICE, 
} from './constants';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);

export function normalize_availability(availability) {
  
  // Extract start and end day from id
  const [startDay, endDay] = availability.id.split("-");
  
  // Get the start and end day index
  const startIndex = DAYS_OF_WEEK.indexOf(startDay.toLowerCase());
  const endIndex = DAYS_OF_WEEK.indexOf(endDay ? endDay.toLowerCase() : startDay.toLowerCase());

  // Initialize an object to store the normalized availability
  const normalized = {};

  // Iterate through the days from start to end
  for (let i = startIndex; i <= endIndex; i++) {
      const day = DAYS_OF_WEEK[i];

      // For each day, create an object entry for each period
      availability.periods.forEach(period => {
          normalized[`${day}_${period}`] = true;
      });
  }

  return normalized;
}
export function denormalize_availability(normalized) {

  // Helper to extract day and period from each key
  const extractDayAndPeriod = (key) => {
      const [day, period] = key.split("_");
      return { day, period };
  };

  // Extract unique days and periods from the normalized object
  const uniqueDays = [];
  const periodsSet = new Set();

  Object.keys(normalized).forEach(key => {
      const { day, period } = extractDayAndPeriod(key);

      if (!uniqueDays.includes(day)) {
          uniqueDays.push(day);
      }
      periodsSet.add(period);
  });

  // Sort the unique days by their position in the week
  uniqueDays.sort((a, b) => DAYS_OF_WEEK.indexOf(a) - DAYS_OF_WEEK.indexOf(b));

  // Get the start and end day
  const startDay = uniqueDays[0];
  const endDay = uniqueDays[uniqueDays.length - 1];

  // Convert periodsSet back to an array
  const periods = Array.from(periodsSet);

  // Return the denormalized availability
  return {
      id: startDay === endDay ? startDay : `${startDay}-${endDay}`,
      periods
  };
}

export const formatTimeForLastUpdate = (unixTime) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = dayjs().tz(timezone);
  const date = dayjs.unix(unixTime).tz(timezone);
  const isSameWeek = now.week() === date.week() && now.year() === date.year();
  const isSameYear = now.year() === date.year();
  const isToday = now.isSame(date, 'day');
  if (isToday) {
    return `Today ${date.format('h:mm A')}`; // Return "Today h:mm A"
  } else if (isSameWeek) {
    return date.format('dddd h:mm A'); // Return day of the week
  } else if (isSameYear) {
    return date.format('MMM D'); // Return month and day
  } else {
    return date.format('YYYY-MM-DD'); // Return full date
  }
};
// Function to encode a message
export function encodeMessage(message) {
  if (!message) {
      return '';
  }
  message = message.replace(/,/g, ''); // Remove commas
  let encodedMessage = btoa(unescape(encodeURIComponent(message)));
  return encodedMessage;
}

export function parseList(base64String) {
  if (!base64String)
    return []
  const jsonString = atob(base64String);
  return JSON.parse(jsonString);
}


// Function to decode a message
export function decodeMessage(message) {
  if (!message || typeof message !== 'string') {
      return '';
  }
  let decodedMessage = decodeURIComponent(escape(atob(message)));
  return decodedMessage;
}

export const   formatInvoiceAmount = (invoice) => {
  const {lines, hourlyrate, minpay, currency='$'} = invoice
  let amount = calculateCost(lines, hourlyrate, minpay)
  return `${currency}${amount.final_price.toFixed(2)}`;
};
export  const formatTimeForMessage = (unixTime) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = dayjs.unix(unixTime).tz(timezone);

  const day = date.format('dddd')
  const time = date.format('h:mm A')
  return `${day}, ${time}`
};
export const standarize_tx_data = (data) => {
  const notification = data['last_notification_type']
  const standard_data = {...data,
    state: EDGE_TEMPLATES[notification].to,
    lastupdate: Math.floor(Date.now() / 1000)
  } 
  return standard_data
}

export const get_tx_message = (notification, data) => {
  let message = EDGE_TEMPLATES[notification].message
  if(notification == NOTIFICATION_UPDATE_INVOICE)
    message = message.replace("DATA_PLACEHOLDER", formatInvoiceAmount(data.invoice))
  else if(notification == NOTIFICATION_UPDATE_SCHEDULE)
    message = message.replace("DATA_PLACEHOLDER", formatTimeForMessage(data.schedule.startTime))

  return message
}


// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());


export function capitalizeFirstChar(str) {
  if (!str) return str; // Return the original string if it's empty
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */

class Money {
  constructor(amount, currency) {
    this.amount = amount;
    this.currency = currency;
    this.type = 'Money'
  }
  toString() {
    return this.amount.toFixed(2); // Converts the amount to a string with 2 decimal places
  }

}



export {  Money };
