import React from 'react';
import { Box, Typography } from '@mui/material';

const EmptyForm = ({ message, shape = 'rectangle', onClick = ()=>{} , sx}) => {
  return (
    <Box
      sx={{
        width: '100%',            // Full width, decided by parent
        height: '100%',           // Full height, decided by parent
        padding: '16px',          // Padding around the dotted border
        boxSizing: 'border-box',  // Ensures padding doesn’t affect the width/height
        cursor: 'pointer',
        ...sx

      }}
      onClick={()=>onClick()}
    >
      <Box
        sx={{
          border: '2px dotted gray', // Dotted border
          borderRadius: shape === 'circle' ? '50%' : '8px', // Circle or rounded rectangle
          width: '100%',            // Full width of the container minus padding
          height: '100%',           // Full height of the container minus padding
          display: 'flex',          // Flexbox for centering content
          alignItems: 'center',     // Center items vertically
          justifyContent: 'center', // Center items horizontally
          textAlign: 'center',      // Center text
          flexDirection: 'column',  // Stack + sign and message vertically
  
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'gray' }}>
          +
        </Typography>
        <Typography variant="body1" sx={{ color: 'gray', marginTop: '8px' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyForm;
