import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LEAF_STATES } from '../../util/constants';
const CancelButton = ({ state }) => {
  if(!state || LEAF_STATES.includes(state))
    return


  const handleCancelClick = () => {
    console.log("clicked")
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} bgcolor="#f8d7da" borderRadius="8px">
      <Typography variant="h6" color="#721c24" gutterBottom>
        Stop it here
      </Typography>
      <Typography variant="body2" color="#721c24" align="center" mb={2}>
        This will terminate the current activity. Are you sure you want to proceed?
      </Typography>
      <Button
        variant="contained"
        color="error"
        startIcon={<CancelIcon />}
        onClick={handleCancelClick}
        sx={{ padding: '12px 24px', fontSize: '16px' }}
      >
        Stop it here
      </Button>
    </Box>
  );
};

export default CancelButton;
