// src/components/TimeSelector.js
import React from 'react';
import { Box } from '@mui/material';
import DraggableChip from '../CoreDraggable/DraggableChip';

const TimeSelector = ({ onSelectTime }) => {
  const timeOptions = ['10min', '20min', '30min', '1hour'];

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
      {timeOptions.map((time) => (
        <DraggableChip
          key={time}
          id={time} // Unique ID for drag-and-drop (e.g., '10min', '1hour')
          onClick={() => onSelectTime(time)}
          data={{
            label: time,
            type: "timeItem"
          }}
        />
      ))}
    </Box>
  );
};

export default TimeSelector;
