// src/components/Forms/AvailabilityForm/useAvailabilityDragHandlers.js

import { useCallback } from 'react';
import { DAYS_OF_WEEK, PERIODS } from '../../../util/constants';

/**
 * Custom hook to handle drag-and-drop events for availability ranges.
 *
 * @param {Array} ranges - Current state of availability ranges.
 * @param {Function} setRanges - State updater function.
 * @returns {Object} - Contains the handleDragEnd function.
 */
const useAvailabilityDragHandlers = (ranges, setRanges) => {
  if (typeof setRanges !== 'function') {
  }

  /**
   * Identifies the type of the draggable or droppable element.
   *
   * @param {Object} element - The element to identify.
   * @returns {string} - The type of the element.
   */
  const getElementType = (element) => {
    const id = element?.id;
    if (!id) return '';

    // Check if the ID matches any period
    if (PERIODS.some((period) => period.id === id)) return 'period';

    // Check if the ID matches any day
    if (DAYS_OF_WEEK.includes(id)) return 'range';

    // Special drop area
    if (id === 'main-drop-area') return 'main-drop-area';

    // Check if the ID represents a range (e.g., "monday-tuesday")
    const splitted = id.split('-');
    if (
      splitted.length > 1 &&
      splitted.every((part) => DAYS_OF_WEEK.includes(part))
    )
      return 'range';

    return '';
  };

  /**
   * Handles the end of a drag event.
   *
   * @param {Object} event - The drag event.
   */
  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      const dropped = { id: active?.id, type: getElementType(active) };
      const area = { id: over?.id, type: getElementType(over) };


      // Validate types
      if (!dropped.type || !area.type) {
        return;
      }

      // **Case 1: Adding a New Day to the Main Drop Area**
      if (area.type === 'main-drop-area' && dropped.type === 'range') {
        const exists = ranges.some(
          (range) =>
            range.startday === dropped.id && range.endday === dropped.id
        );
        if (exists) {
          return;
        }

        // Create new range object
        const newRange = {
          id: dropped.id, // You might want to generate a unique ID instead
          startday: dropped.id,
          endday: dropped.id,
          periods: [],
        };

        // Update ranges
        const newRanges = [...ranges, newRange];
        setRanges(newRanges);
        return;
      }

      // **Case 2: Assigning an End Day to an Existing Range**
      if (area.type === 'range' && dropped.type === 'range' && area.id != dropped.id) {
        // Find the target range
        const targetRangeIndex = ranges.findIndex(
          (range) => range.id === area.id
        );
        if (targetRangeIndex === -1) {
          return;
        }

        const targetRange = ranges[targetRangeIndex];

        // Prevent assigning the same range as endday
        if (targetRange.endday === dropped.id) {
          return;
        }

        // Create updated range
        const updatedRange = {
          ...targetRange,
          endday: dropped.id,
        };

        // Optionally, update the range ID to reflect the new range
        updatedRange.id = `${updatedRange.startday}-${updatedRange.endday}`;

        // Check if the updated range already exists
        const exists = ranges.some((range) => range.id === updatedRange.id);
        if (exists) {
          return;
        }

        // Update ranges immutably
        const newRanges = [...ranges];
        newRanges[targetRangeIndex] = updatedRange;
        setRanges(newRanges);
        return;
      }

      // **Case 3: Adding a Unique Period to an Existing Range**
      if (area.type === 'range' && dropped.type === 'period') {
        // Find the target range
        const targetRangeIndex = ranges.findIndex(
          (range) => range.id === area.id
        );
        if (targetRangeIndex === -1) {
          return;
        }

        const targetRange = ranges[targetRangeIndex];

        // Check for period uniqueness
        if (targetRange.periods.includes(dropped.id)) {
          return;
        }

        // Add the new period
        const updatedRange = {
          ...targetRange,
          periods: [...targetRange.periods, dropped.id],
        };

        // Update ranges immutably
        const newRanges = [...ranges];
        newRanges[targetRangeIndex] = updatedRange;
        setRanges(newRanges);
        return;
      }

      // **Unhandled Cases**
    },
    [ranges, setRanges]
  );

  return { handleDragEnd };
};

export default useAvailabilityDragHandlers;
