import React, { useState, useEffect } from 'react';
import { useBackend, useCache, useManager } from '../../context';
import { Avatar, Typography, Box, Divider, Skeleton } from '@mui/material';

import {  NamedLink, PrimaryButton, ManagerHeader } from '../../components';
import { useNavigation, useSendbird } from '../../context';


const SelectedFavorites = () => {
  const mprops = useManager('favorites') || {};
  const {onUpdateUser} = useBackend()
  const { selectedSharable: SelectedFavorites, sharables, type } = mprops;
  const ids = SelectedFavorites?.data || []; 

  useEffect(() => {
    if (!sharables) return;
    onUpdateUser({ [type]: sharables });
  }, [sharables]);

  if (!SelectedFavorites) return <div>Loading...</div>;
  if (ids.length === 0) return <Typography>No favorites found</Typography>;

  return (
    <Box sx={{ width: '100%', overflowY: 'auto', padding: 2, display: "flex", flexDirection: "column", gap: 1 }}>
      <ManagerHeader {...mprops} />
      <Box>
        {ids.map((id, index) => (
          <React.Fragment key={id}>
            <FavoriteCard bid={id} />
            <Divider sx={{ mt: 1, mb: 1 }} />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

const FavoriteCard = ({ bid }) => {
  const [business, setBusiness] = useState(null);
  const { onFetchBusiness } = useBackend();
  
  const [channel, setChannel] = useState();
  const { onGetChannel, sdkLoaded: sbLoaded } = useSendbird();
  const { navigateByName } = useNavigation();

  useEffect(() => {
    if (!business?.id || !sbLoaded) return;
    onGetChannel({ invited: business.workerids }).then(channel => setChannel(channel));
  }, [business?.id, sbLoaded]);

  useEffect(() => {
    onFetchBusiness(bid).then(business => setBusiness(business));
  }, [bid]);

  if (!business) {
    return (
      <Box display="flex" width="100%" alignItems="center">
        <Skeleton variant="circular" width={60} height={60} sx={{ mr: 2 }} />
        <Box width="70%">
          <Skeleton width="100%" height={30} />
        </Box>
        <Skeleton variant="rectangular" width="30%" height={36} />
      </Box>
    );
  }

  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box width="70%">
        <NamedLink name="BusinessPage" params={{ bid }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={business.picture}
              alt={business.name}
              sx={{ width: 60, height: 60, mr: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 500, color: 'black' }}>
              {business.name}
            </Typography>
          </Box>
        </NamedLink>
      </Box>
      <PrimaryButton
        message="Message"
        onClick={() => navigateByName("InboxPage", { state: channel })}
        disabled={!channel}
        sx={{ width: "30%" }}
      />
    </Box>
  );
};

export default SelectedFavorites;
