// src/components/Manager/useInitializeManager.js

import { useState, useEffect } from 'react';
import { useCache } from '../../context';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Custom hook to initialize manager data based on managerType.
 * Ensures that the initialList always contains at least one sharable.
 * If the userData is empty, appends the default sharable to the list.
 * @param {string} managerType - Type of the manager ('locations', 'availabilities', 'buckets', 'favorites').
 * @returns {Object} - Contains initialList, initialSelectedItem, isInitialized, and isAuthenticated.
 */
const useInitializeManager = (managerType) => {
  const { user } = useCache();
  const { isLoading: auth0Loading, isAuthenticated } = useAuth0();
  const [initialSelectedItem, setInitialSelectedItem] = useState(null);
  const [initialList, setInitialList] = useState([]);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (auth0Loading || (isAuthenticated && !user?.id)) return;

    // Define the default sharable based on managerType
    let defaultSharable;
    if (managerType === 'locations') {
      defaultSharable = isAuthenticated
        ? { id: 'id-1', title: 'Default Location', data: user?.geoLocation || { lat: 0, lng: 0 } , 'type': managerType}
        : { id: 'id-1', title: 'Default Location', data: { lat: 0, lng: 0 } };
    } else {
      defaultSharable = { id: 'id-1', title: `Default ${capitalize(managerType)}`, data: [], 'type': managerType };
    }

    if (isAuthenticated) {

      const userData = user[managerType] || [];
      const hasItems = userData.length > 0;

      if (hasItems) {
        setInitialSelectedItem(userData[0]);
        setInitialList(userData);
      } else {
        setInitialSelectedItem(defaultSharable);
        setInitialList([defaultSharable]); // Ensure initialList contains the default sharable
      }
    } else {
      setInitialSelectedItem(defaultSharable);
      setInitialList([defaultSharable]); // Ensure initialList contains the default sharable
    }

    setInitialized(true);
  }, [auth0Loading, isAuthenticated, user, managerType]);

  return { initialList, initialSelectedItem, isInitialized, isAuthenticated };
};

/**
 * Utility function to capitalize the first letter of a string.
 * @param {string} str - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default useInitializeManager;
