// src/components/Forms/AvailabilityForm/useAvailabilityDnd.js

import { useState } from 'react';
import {
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import useAvailabilityDragHandlers from './useAvailabilityDragHandlers';

/**
 * Custom hook to manage drag-and-drop logic for AvailabilityForm.
 *
 * @param {Array} data - Current availability ranges.
 * @param {Function} setData - State updater for availability ranges.
 * @returns {Object} - Contains sensors, activeItem, and event handlers.
 */
const useAvailabilityDnd = (data, setData) => {
  const [activeItem, setActiveItem] = useState(null);

  // Configure MouseSensor
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 0, // Drag starts after moving 5px
    },
  });

  // Configure TouchSensor
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100, // 100ms delay before drag starts
      tolerance: 0, // Drag starts after moving 5px
    },
  });

  // Combine sensors
  const sensors = useSensors(mouseSensor, touchSensor);

  // Utilize the existing drag handlers
  const { handleDragEnd } = useAvailabilityDragHandlers(data, setData);

  /**
   * Handler for when a drag starts.
   *
   * @param {Object} event - The drag start event.
   */
  const handleDragStart = (event) => {
    const { active } = event;
    const draggedItem = data.find((item) => item.id === active.id);
    setActiveItem(draggedItem);
  };

  /**
   * Handler for when a drag ends.
   *
   * @param {Object} event - The drag end event.
   */
  const handleDragEndInternal = (event) => {
    handleDragEnd(event);
    setActiveItem(null);
  };

  /**
   * Handler for when a drag is canceled.
   */
  const handleDragCancel = () => {
    setActiveItem(null);
  };

  return {
    sensors,
    activeItem,
    handleDragStart,
    handleDragEnd: handleDragEndInternal,
    handleDragCancel,
  };
};

export default useAvailabilityDnd;
