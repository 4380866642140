import React, { useState, useEffect } from "react";
import { ClickableCard, ClickableMap, AvailabilityPreview, AvailabilityForm, EmptyForm } from '../../../../components';

import { Box, IconButton, Accordion, AccordionSummary, AccordionDetails, TextField, Typography } from "@mui/material";
import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {default as RemoveIcon} from '@mui/icons-material/Delete';

const ServiceArea = ({center, setCenter}) => {
  return (
    <>
      <ClickableMap
        fuzzy={true}
        circleRadius={100}
        center={center}
        setCenter={setCenter}
        allowEdit={true}
        previewsx={{
          cardsx:{width:"100%", height: 250, borderBottomLeftRadius:0, borderBottomRightRadius:0}
        }}
        drawersx={{
          boxsx: {height: 750}
        }}
      />
    </>
  );
};

const Schedule = ({availabilities, setAvailability}) => {
  const preview = availabilities.length ? 
    <AvailabilityPreview availability={availabilities}/> : 
    <EmptyForm message="Add new availabilities" />;
    
  return (
    <ClickableCard
      usedrawer
      preview={preview}
      content={<AvailabilityForm data={availabilities} setData={setAvailability}/>}
      previewsx={{
        cardsx:{width:"100%", borderTopLeftRadius:0, borderTopRightRadius:0},
      }}
      drawersx={{
        boxsx: {height: 750}
      }}
    />
  );
};

const validate_availability = (availabilities) => {
  if (availabilities.length === 0) return false;
  for (const a of availabilities) {
    if (a.periods.length === 0) return false;
  }
  return true;
};

const validate_center = (center) => {
  return center?.lat && center?.lng && center?.radius;
};

const validate_scheduledArea = (scheduledArea) => {
  const {availabilities, center} = scheduledArea;
  return validate_center(center) && validate_availability(availabilities);
};

const ScheduledAreaForm = ({ scheduledArea, setWorkSchedule, onRemove }) => {
  const [center, setCenter] = useState(scheduledArea?.center || {});
  const [availabilities, setAvailability] = useState(scheduledArea?.availabilities || []);
  const DeleteButton = () => (
      <IconButton 
        onClick={onRemove}
        sx={{ position: 'absolute', top: 0, right: 0, color: 'gray' }}
      >
        <RemoveIcon />
      </IconButton>
  )
  useEffect(() => {
    setWorkSchedule({ center, availabilities });
  }, [JSON.stringify(center), JSON.stringify(availabilities)]);
  
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <ServiceArea center={center} setCenter={setCenter} />
      <Schedule availabilities={availabilities} setAvailability={setAvailability} />
      <DeleteButton/>
      
    </Box>
  );
};

export const validate_all_work_schedules = (scheduledAreas) => {
  if (!scheduledAreas.length) return false
  return scheduledAreas.every((schedule) => validate_scheduledArea(schedule));
};


const ScheduledAreaItem = ({
  scheduledArea, 
  index, 
  expanded, 
  handleAccordionChange, 
  handleTitleClick, 
  handleTitleChange, 
  handleTitleBlur, 
  handleUpdateWorkSchedule, 
  handleRemoveWorkSchedule, 
  editingIndex 
}) => {
  return (
    <Accordion
      key={index}
      expanded={expanded === index}
      onChange={handleAccordionChange(index)} // Track which accordion is open
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        {editingIndex === index ? (
          <TextField
            variant="outlined"
            value={scheduledArea.title}
            onChange={(e) => handleTitleChange(index, e.target.value)}
            onBlur={handleTitleBlur}
            autoFocus
            fullWidth
          />
        ) : (
          <Typography
            variant="h6"
            onClick={() => handleTitleClick(index)}
            sx={{ cursor: 'pointer' }}
          >
            {scheduledArea.title}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {/* Render the ScheduledAreaForm inside AccordionDetails */}
        <ScheduledAreaForm
          scheduledArea={scheduledArea} setWorkSchedule={(newSchedule) => handleUpdateWorkSchedule(index, newSchedule)}
          onRemove={() => handleRemoveWorkSchedule(index)}
        />
      </AccordionDetails>
    </Accordion>
  );
};


const ScheduledAreas = ({ scheduledAreas, setScheduledArea }) => {
  const [expanded, setExpanded] = useState(false); // Track which accordion is expanded
  const [editingIndex, setEditingIndex] = useState(null); // Track which title is being edited
  const empty = scheduledAreas.length === 0
  const maxed = scheduledAreas.length >= 3
  const handleAddSchedule = () => {
    setScheduledArea([...scheduledAreas, { title: "New Area Schedule", center: {}, availabilities: [] }]);
  };

  const handleRemoveWorkSchedule = (index) => {
    const updatedSchedules = scheduledAreas.filter((_, i) => i !== index);
    setScheduledArea(updatedSchedules);
  };

  const handleUpdateWorkSchedule = (index, newSchedule) => {
    const updatedSchedules = scheduledAreas.map((schedule, i) =>
      i === index ? { ...schedule, center: newSchedule.center, availabilities: newSchedule.availabilities } : schedule
    );
    setScheduledArea(updatedSchedules);
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false); // Ensure only one accordion is expanded at a time
  };

  const handleTitleChange = (index, newTitle) => {
    const updatedSchedules = scheduledAreas.map((schedule, i) =>
      i === index ? { ...schedule, title: newTitle } : schedule
    );
    setScheduledArea(updatedSchedules);
  };

  const handleTitleClick = (index) => {
    setEditingIndex(index); // Enable editing mode for the clicked title
  };

  const handleTitleBlur = () => {
    setEditingIndex(null); // Disable editing mode when the input loses focus
  };

  return (
    <Box>
      {empty? (
        <EmptyForm message={"add new area"} onClick={handleAddSchedule}/> // Render EmptyForm if no work schedules exist
      ) : (
        scheduledAreas.map((scheduledArea, index) => (
          <ScheduledAreaItem
            key={index}
            index={index}
            scheduledArea={scheduledArea}
            expanded={expanded}
            editingIndex={editingIndex}
            handleAccordionChange={handleAccordionChange}
            handleTitleClick={handleTitleClick}
            handleTitleChange={handleTitleChange}
            handleTitleBlur={handleTitleBlur}
            handleUpdateWorkSchedule={handleUpdateWorkSchedule}
            handleRemoveWorkSchedule={handleRemoveWorkSchedule}
          />
        ))
      )}
      
      {/* "+" Button to add new schedule */}
      {!empty && !maxed && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <IconButton onClick={handleAddSchedule} sx={{ color: 'gray' }}>
          <AddIcon />
        </IconButton>
      </Box>}
    </Box>
  );
};
export default ScheduledAreas;
