import React, { useEffect, useState, useRef } from 'react';
import { ImageGallery, EmptyForm } from '../../../components';
import { UPLOAD_SERVICE_IMAGE_REQUEST } from '../../../util/constants';
import { useBackend, useCache } from '../../../context';
import { Box, IconButton, Skeleton} from "@mui/material";
import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {default as RemoveIcon} from '@mui/icons-material/Delete';

const AddButton = ({onClick}) => {
  
  return <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          <IconButton onClick={onClick} sx={{ color: 'gray' }}>
            <AddIcon sx={{ fontSize: '2rem' }}/>
          </IconButton>
        </Box>
}
const DeleteButton = ({imgId}) => {
  const { onDeleteImage } = useBackend();
  return (
  <IconButton 
    onClick={()=>onDeleteImage(imgId)}
  >
    <RemoveIcon  sx={{ fontSize: '2rem' }}/>
  </IconButton>
    )}


const GalleryForm = () => {
  const fileInputRef = useRef(null);
  const {  onUploadServiceImages } = useBackend();
  const {ownBusiness, updateBusinessInProgress} = useCache()
  const {images: currentImages, starimages=[]} = ownBusiness || {}
  const [currentId, setCurrentImgId] = useState('');
  const onClick = ()=>{fileInputRef.current.click()}
  return <Box sx={{width:'650px', height: '650px', display: "flex", flexDirection: "column", alignItems: "center", border: "2px solid", borderRadius: "5px"}}>
          <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          onChange={(e) => {
            const files = e.target.files;
            onUploadServiceImages(files);
          }}
        />
    {updateBusinessInProgress == UPLOAD_SERVICE_IMAGE_REQUEST? 
    <Skeleton
    variant="rectangular"
    width="100%"
    height="100%"
  />:
    currentImages?.length? 
      <>
      <ImageGallery bImages={currentImages} starimages={starimages} setCurrentImgId={setCurrentImgId} /> 
      <Box sx={{display: "flex", gap: 2}}>
        <AddButton onClick={onClick}/>
        <DeleteButton imgId={currentId}/>
      </Box>
  
      </>
      : 
      <EmptyForm message="Add Images"  onClick={onClick}/>    
      
    
  }
    
  </Box>
}
export default GalleryForm;
