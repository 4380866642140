import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const FieldTextInput = ({
  control,
  name,
  label,
  defaultValue,
  isUncontrolled,
  inputRef,
  hideErrorMessage,
  placeholder,
  onChange,
  inputProps = {}, // Accept additional input props
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...rest}
          label={label}
          placeholder={placeholder}
          inputRef={inputRef}
          error={!!error}
          helperText={!hideErrorMessage && error ? error.message : null}
          variant="outlined"
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e);
          }}
          InputProps={{
            ...inputProps, // Apply custom input styles here
          }}
        />
      )}
    />
  );
};

export default FieldTextInput;
