import React, { useState, useEffect } from 'react';
import {
  Box,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { useSendbird } from '../../context';
import NamedLink from './NamedLink';

const ChannelLink = ({channelData, children, sx}) => {
const { isAuthenticated } = useAuth0();
const {onGetChannel, sdkLoaded} = useSendbird()
const [channel, setChannel] = useState()
useEffect(() => {
  if(!channelData?.invited.length > 0 || !isAuthenticated || !sdkLoaded) return
   const promise = onGetChannel(channelData)
   promise?.then(channel => setChannel(channel))
  }, [isAuthenticated, sdkLoaded]);

return <Box sx={sx}>
  <NamedLink name={"InboxPage"} state={channel}>
    {children}
</NamedLink>
</Box>

}

export default ChannelLink