// src/hooks/hook.js


/**
 * Application-specific handler for when a drag ends.
 *
 * @param {Object} event - The drag end event.
 * @param {Function} setItems - Function to update the list of items.
 * @param {string} category - The currently active category.
 */
const handleDragEnd = (event, items, setItems) => {
    const { active, over } = event;
    const droppedItem = active?.data.current;

    if (over) {
      if (droppedItem.type === 'todo' && over.id === 'todosArea') {
        // Handle dropping todo items in the main area
        const existingItem = items.find((item) => item.id === active.id);
        if(existingItem) 
          setItems(items.map((item) => item.id === active.id ? { ...item, count: item.count + 1 } : item ))
        else 
          setItems([...items, droppedItem])
      } 
      
      else if (droppedItem.type === 'timeItem' && over.id.endsWith('-time')) {
        // Handle dropping time items in the time areas
        const targetId = over.id.split('-')[0];
        const timeToAdd = active.id === '1hour' ? 60 : parseInt(active.id.replace('min', ''), 10);

        setItems(items.map((item) => item.id === targetId ? { ...item, time: item.time + timeToAdd } : item ))

      }
    }
  };
  
  export default handleDragEnd;
  