import React, { useState } from 'react';
import { List, ListItem, Divider, Box, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Material-UI Icon
import {capitalizeFirstChar} from '../../util/utils'

const renderAvailabilityItem = (item) => (
  <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
    {/* Day with fixed width and monospace font */}
    <Typography
      variant="body1"
      sx={{
        fontWeight: 'bold',
        color: 'black',
        width: '50px', // Adjust width as needed
        fontFamily: 'monospace',
        textAlign: 'left',
      }}
    >
      {capitalizeFirstChar(item.id)}
    </Typography>
    {/* Vertical separator */}
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
    {/* Periods - allow it to grow and use space flexibly */}
    <Typography
      variant="body2"
      sx={{ color: 'black', flexGrow: 1, textOverflow: 'ellipsis' }}
    >
      {item.periods.join(', ')}
    </Typography>
  </Box>
);


const ListPreview = ({ items }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const previewItems = expanded || items.length <= 3 ? items : items.slice(0, 3);
  return (
    <List sx={{ width: "100%",  }}>
      {previewItems.map((item, index) => (
        <div key={index}>
          <ListItem>
            {item} {/* Directly rendering the passed component */}
          </ListItem>
          {index < items.length - 1 && <Divider  />}
        </div>
      ))}
      {items.length > 3 && (
        <Box textAlign="center" sx={{ mt: -0.25 }}>
          <IconButton
            onClick={handleToggleExpand}
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
              borderRadius: '50%',
              padding: '10px',
              transition: 'transform 0.3s',
              '&:hover': {
                backgroundColor: '#f1f1f1',
              },
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      )}
    </List>
  );
};


export const AvailabilityPreview = ({availability}) => {

  const items = availability.map(renderAvailabilityItem);
  return <ListPreview items={items} />

}

export const BucketPreview = ({bucket}) => {
  const items = bucket?.map((item) => (
    <Typography variant="body1" key={item.id} >
    {item.count} {item.id}
    </Typography>
));
return <ListPreview items={items} />
}
export default ListPreview;
