import React, {useState, useEffect} from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import RateReviewIcon from '@mui/icons-material/RateReview';
import {useCache, useSendbird, useNavigation} from '../../context';
import {PayDrawer, ReviewDrawer, InvoicerDrawer} from '../../components'

import {NOTIFICATION_UPDATE_INVOICE, NOTIFICATION_MARK_AS_PAID, NOTIFICATION_UPDATE_REVIEW, NOTIFICATION_UPDATE_SCHEDULE} from '../../util/constants'


const ButtonBox = ({ isActive, icon, label, onClick }) => {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      fontSize: '32px',
      color: isActive ? '#000' : '#6c757d',
    },
    '& .MuiTypography-root': {
      color: isActive ? '#000' : '#6c757d',
      fontWeight: isActive ? 'bold' : 'normal',
      marginLeft: '16px',
      fontSize: '16px',
    },
  };

  return (
    <Box display="flex" alignItems="center">
      {/* This inner Box is clickable */}
      <Box sx={styles} onClick={onClick} backgroundColor="red">
        {icon}
        <Typography variant="body1">{label}</Typography>
      </Box>
    </Box>
  );
};

const CustomerActions = ({ tx, setPayOpen, setReviewOpen }) => (
  <Box display="flex" flexDirection="row" gap={2}>
    <ButtonBox 
      isActive={tx?.last_notification_type === NOTIFICATION_UPDATE_INVOICE}
      icon={<PaymentIcon />}
      label="Pay"
      onClick={tx?.last_notification_type === NOTIFICATION_UPDATE_INVOICE ? () => setPayOpen(true) : () => {}}
    />
    <ButtonBox 
      isActive={tx?.last_notification_type === NOTIFICATION_MARK_AS_PAID}
      icon={<RateReviewIcon />}
      label="Leave a Review"
      onClick={() => {
        if (tx?.last_notification_type === NOTIFICATION_UPDATE_REVIEW) return;
        setReviewOpen(true);
      }}
    />
  </Box>
);


const BusinessActions = ({ tx, setInvoicerOpen }) => (
  <Box display="flex" flexDirection="row" gap={2}>
    <ButtonBox 
      isActive={tx?.last_notification_type === NOTIFICATION_UPDATE_SCHEDULE}
      icon={<ReceiptIcon />}
      label="Create Invoice"
      onClick={tx?.last_notification_type === NOTIFICATION_UPDATE_SCHEDULE ? () => setInvoicerOpen(true) : () => {}}
    />
  </Box>
);


const Taskbar = ({ tx }) => {
  const [isInvoicerOpen, setInvoicerOpen] = useState(false);
  const [isReviewOpen, setReviewOpen] = useState(false);
  const [isPayOpen, setPayOpen] = useState(false);

  const { user } = useCache();
  const isCustomer = tx?.customerid === user?.id;
  const otherUser = isCustomer ? tx?.business : tx?.customer;

  const [channel, setChannel] = useState()
  const {onGetChannelByUrl, sdkLoaded: sbLoaded} = useSendbird()
  const channel_url = tx?.channel_url
  useEffect(() => {
    if(!channel_url || !sbLoaded) return
    onGetChannelByUrl(channel_url).then(channel =>setChannel(channel))
  }, [channel_url, sbLoaded]);

  const {navigateByName} = useNavigation()
  return (
    <>
      {!isCustomer && <InvoicerDrawer tx={tx} isOpen={isInvoicerOpen} onClose={() => setInvoicerOpen(false)} />}
      <ReviewDrawer tx={tx} isOpen={isReviewOpen} onClose={() => setReviewOpen(false)} />
      <PayDrawer tx={tx} isOpen={isPayOpen} onClose={() => setPayOpen(false)} />

      <Box display="flex" flexDirection="column" p={1} gap={2}>
        <ButtonBox 
            isActive={true}
            icon={<ChatIcon />}
            label={`Chat with ${otherUser.name}`}
            onClick={()=> navigateByName("InboxPage", {state: channel})}
            disabled={!channel}
          />

        {isCustomer ? (
          <CustomerActions tx={tx} setPayOpen={setPayOpen} setReviewOpen={setReviewOpen} />
        ) : (
          <BusinessActions tx={tx} setInvoicerOpen={setInvoicerOpen} />
        )}
      </Box>
    </>
  );
};

export default Taskbar;
