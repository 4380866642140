import axios from 'axios';

// Adds access tokens in all API requests
var apiToken = null;
export const updateToken = (token) => {
  apiToken = token;
};

export const apiBaseUrl = () => {
  return `https://jnjjqo1raa.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_ENV}`; 
};

const request = async (path, body = null, authorize = true) => {
  const url = `${apiBaseUrl()}${path}`;
  const headers = {
    'Content-Type': 'application/json',
  };

  if (authorize) {
    headers['Authorization'] = `Bearer ${apiToken}`;
  }

  try {
    const response = await axios({
      url: url,
      method: 'POST',  // Always use POST
      headers: headers,
      data: body,
    });


    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Response Error:', {
        url,
        status: error.response.status,
        data: error.response.data,
        body: body,
        headers: error.response.headers
      });
      const e = new Error(`Request ${path} failed with status ${error.response.status}`);
      e.data = error.response.data;
      throw e;
    } else if (error.request) {
      // Request was made but no response received
      console.error(`Request ${path} made but no response received:`, error.request);
      throw new Error('No response received from server');
    } else {
      // Other errors
      console.error('Request Error:', error.message);
      throw new Error(`Request error: ${error.message}`);
    }
  }
};



// Define functions for each endpoint with appropriate HTTP methods

export const getTransaction = (body) => {
  return request('/getTransaction', body);
};


export const postTransition = body => {
  return request('/postTransition', body);
};



export const postOwnBusiness = body => {
  return request('/postOwnBusiness', body);
};




export const postAuthor = body => {
  return request('/postAuthor', body);
};

export const getBusiness = (body) => {
  return request('/getBusiness', body, false); // should be renamed on backened
};

export const getSearch = (body) => {
  return request('/getSearchedServices', body, false); // name should be changed on gateway
};


export const postBeavrUser = body => {
  return request('/postBeavrUser', body);
};

export const getBeavrUser = body => {
  return request('/getBeavrUser', body);
};
export const getOwnBusiness = body => {
  return request('/getOwnBusiness', body);
};

export const postTransaction = body => {
  return request('/postTransaction', body);
};




export const createStripeAccount = () => {
  return request('/createStripeAccount', null);
};


export const fetchJson = (url) => {
  return fetch(url) .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();  // Parse the JSON data
  })
  .then(data => {
    if(data?.images) return data.images
    return data
  })
  .catch(error => {
    console.error('There has been a problem with your fetch operation:', error);
  });
}


export const resizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    const reader = new FileReader();

    reader.onload = (event) => {
      img.src = event.target.result;
    };

    img.onload = () => {
      const newWidth = 900;
      const newHeight = 900;

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const resizedFile = new File([blob], file.name.replace(/\.[^.]+$/, '.webp'), { type: 'image/webp' });
          resolve(resizedFile);
        } else {
          reject(new Error('Image resizing failed'));
        }
      }, 'image/webp');
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};


export const uploadFileToS3 = (presignedUrl, imgFile) => {
  return fetch(presignedUrl, {
    method: 'PUT',
    body: imgFile,
  });
};
