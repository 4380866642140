import React, { } from 'react';
import { GalleryForm,  } from '../../../../components';




const ImagesTab = (props) => {


  return <GalleryForm/>
};

export default ImagesTab;
