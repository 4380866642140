// ConnectPayments.jsx

import React from 'react';
import { useStripeConnect } from '../../context';
import {ConnectPayments as RawConnectPayments } from "@stripe/react-connect-js";
import { CircularProgress, Typography, Box } from '@mui/material';

const ConnectPayments = () => {
  const { stripeConnectInstance, loading, error } = useStripeConnect();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">Failed to load Stripe Payments: {error.message}</Typography>
    );
  }

  if (!stripeConnectInstance) {
    return <Typography>No Stripe instance available for Payments.</Typography>;
  }

  return <RawConnectPayments />;
};

export default ConnectPayments;
