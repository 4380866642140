import React, { useState } from 'react';
import BoltIcon from '@mui/icons-material/Bolt';

import { useNavigation } from '../../../context';
import MessageIcon from '@mui/icons-material/Message';
import { LinkedLogo, Likebutton, Sharebutton } from '../../../components';


import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {IconButton, Box,AppBar, Toolbar, Typography } from '@mui/material';



const Mainbar = () => {
  const {navigateByName} = useNavigation()
  const left = <LinkedLogo/>

const right =  <Box display="flex" >
          <IconButton onClick={()=>{navigateByName("ActivityPage")}} aria-label="bolt">
            <BoltIcon />
          </IconButton>

            <IconButton onClick={()=>{navigateByName("InboxPage")}} aria-label="bolt">
            <MessageIcon />
          </IconButton>
        </Box>

  
  return <Bar left={left} right={right}/>

}

const TopbarBusiness = () => {
  const GoBackButton = () => {
    const { navigate, findPath } = useNavigation();
    
    return (
      <Box display="flex" alignItems="center">
        <IconButton aria-label="home" onClick={() => navigate(findPath('SearchPage'))}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Services</Typography>
      </Box>
    );
  };
  
  const left = <GoBackButton />
  const right = <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Sharebutton />
                <Likebutton />
              </Box>

  return  <Bar left={left} right={right}/>;
};


const Bar = ({left, right}) => {

  return (
    <Box sx={{ width: '100%'}}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {left}
        {right}
      </Box>


      
    </Box>
  );
};

const TopbarMobile = () => {
  const {page, panel, PAGE_NAMES, PANELS} = useNavigation()
  const bar = page == PAGE_NAMES.InboxPage && panel == PANELS.CHANNEL? null :
              page == PAGE_NAMES.ActivityPage && panel == PANELS.ACTIVITY_DISPLAY? null :
              page == PAGE_NAMES.BusinessPage? <TopbarBusiness/> :
              <Mainbar/>

  if(!bar) return

  return (
                <AppBar position="sticky">
                  <Toolbar sx={{
                    width: "100%",
                    backgroundColor: 'var(--barBackgroundColor)',
                    minHeight: 'var(--topMinBarHeight)', boxShadow: 'var(--barShadow)', borderBottom: 'var(--barBorder)', padding: 'var(--barPadding)',
                    }}>
                  {bar}
                  </Toolbar>
                  
                </AppBar>
            );
}


export default TopbarMobile;
