// src/components/DraggableChip.js
import React from 'react';
import { Chip } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { useDraggable } from '@dnd-kit/core';
import { styled } from '@mui/system';

const StyledChip = styled(Chip)(({ theme }) => ({
  cursor: 'grab',
  margin: theme.spacing(0.5),
}));

const DraggableChip = ({ id, data, color, backgroundColor }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    data
  });

  const style = {
    transform: transform
      ? `translate(${transform.x}px, ${transform.y}px) scale(1.2)`
      : undefined,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1000 : 'auto',
    backgroundColor: backgroundColor || undefined,
  };

  return (
    <StyledChip
      ref={setNodeRef}
      label={data?.label}
      icon={<DragIndicator />}
      {...listeners}
      {...attributes}
      style={style}
      color={color}
    />
  );
};

export default DraggableChip;
