// src/components/Drawer.js
import React from 'react';
import PropTypes from 'prop-types'; // For prop type checking
import { Box, IconButton, Drawer as RawDrawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Drawer Component
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to display inside the drawer
 * @param {boolean} props.isOpen - Controls whether the drawer is open
 * @param {Function} [props.onClose] - Function to handle closing the drawer
 * @param {Function} [props.renderheader] - Optional function to render a custom header
 * @returns {JSX.Element} The Drawer component
 */

const DrawerHeader = ({children}) => {
  return <Box
  sx={{
    width: '100%',
    display: 'flex',
    backgroundColor: "#f8f9fa",
    borderBottom:"var(--barBorder)",
    padding: 1, 
    position:"sticky",
    top: 0,
    left:0,
    right:0,
    zIndex:1,
    bgcolor:"#f8f9fa"

  }}
>
  {children}
</Box>
}

const DrawerBottom = ({children}) => {
  return <Box
  sx={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "#f8f9fa",
    borderBottom:"var(--barBorder)",
    position:"sticky",
    pb:1,
    bottom: 0,
    left:0,
    right:0,
    zIndex:1,
    bgcolor:"#f8f9fa"

  }}
>
  {children}
</Box>
}


const Drawer = ({ children, isOpen, onClose, renderheader, renderfooter }) => {
  // Default Header: Close Button
  const defaultHeader = <IconButton onClick={onClose} sx={{ color: 'black' }} aria-label="Close Drawer">
        <CloseIcon />
      </IconButton>
  

  return (
    <Box sx={{ width: '100%' }}>
      <RawDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            color: '#000000',
            height: '100%'
          },
        }}
      >
          <DrawerHeader>
              {renderheader ? renderheader() : defaultHeader}
          </DrawerHeader>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            overflowY:"auto"
          }}
        >
          

          {children}

        </Box>
        {renderfooter && <DrawerBottom>
            {renderfooter()}
            </DrawerBottom>}

      </RawDrawer>
    </Box>
  );
};

// Define Prop Types for Better Type Checking
Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  renderheader: PropTypes.func, // Optional
};

// Define Default Props
Drawer.defaultProps = {
  onClose: null,
  renderheader: null,
};

export default Drawer;
