// ConnectTaxSettings.jsx

import React from 'react';
import { useStripeConnect } from '../../context';
import { ConnectTaxSettings as RawConnectTaxSettings, ConnectTaxRegistrations as RawConnectTaxRegistrations } from "@stripe/react-connect-js";
import { CircularProgress, Typography, Box } from '@mui/material';

const ConnectTaxSettings = () => {
  const { stripeConnectInstance, loading, error } = useStripeConnect();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error">Failed to load Stripe Tax Settings: {error.message}</Typography>
    );
  }

  if (!stripeConnectInstance) {
    return <Typography>No Stripe instance available for Tax Settings.</Typography>;
  }

  return (
    <Box sx={{display:"flex", flexDirection:"column"}}>
      <RawConnectTaxSettings />
      <RawConnectTaxRegistrations />
    </Box>
  );
};

export default ConnectTaxSettings;
