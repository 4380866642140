import React, { useState } from 'react';
import {
    ImageGallery
  } from '../../../components';
  
  import {
    Box, Card
  } from '@mui/material';
  
const SectionGallery = ({ business }) => {
  return (
    <Card sx={{
      width: '100%',
      maxWidth: '900px',
      maxHeight: '1600px',        
      borderRadius: '5%'
  
    }} >
  
  <ImageGallery bImages={business?.images} starImages={business?.starimages} />
          
    </Card>
  );
};

export default SectionGallery;
