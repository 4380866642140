import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { useCache } from '../../../context';
import {PATHS} from '../../../routing/routeConfiguration'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MuiSearchIcon from '@mui/icons-material/Search';
import { Favorite as FavoriteIcon } from '@mui/icons-material';

const getValue = (pathname) => {
  switch (pathname) {
    case PATHS.SearchPage:
      return 0;
    case PATHS.FinancesPage:
      return 1;

    case PATHS.FavoritesPage:
        return 2;
  
    case PATHS.AccountPage:
      return 3;

    default:
      return -1;
  }
};

const BottombarMobile = () => {
  const location = useLocation();
  const value = getValue(location.pathname);
  const { isWorker } = useCache();
  const textColorSx = {'&.Mui-selected': {'& .MuiBottomNavigationAction-label': {color: 'black'},},}
  
  return (
    <BottomNavigation value={value} showLabels style={{ width: '100%', height: '100%' }}>

      <BottomNavigationAction
        label="Explore"
        icon={<MuiSearchIcon />}
        component={Link}
        to={PATHS.SearchPage}
        sx={{...textColorSx}}
      />

      {isWorker && (
        <BottomNavigationAction
          label="Finances"
          icon={<MonetizationOnIcon />}
          component={Link}
          to={PATHS.FinancesPage}
          sx={{...textColorSx}}
        />
      )}

      <BottomNavigationAction
          label="Favorites"
          icon={<FavoriteIcon />}
          component={Link}
          to={PATHS.FavoritesPage}
          sx={{...textColorSx}}
        />

      <BottomNavigationAction
        label="Profile"
        icon={<AccountCircleIcon />}
        component={Link}
        to={PATHS.AccountPage}
        sx={{...textColorSx}}
      />
    </BottomNavigation>
  );
};

export default BottombarMobile;
