import { getSearch } from '../../util/api';
import { upsertBusiness } from '../../ducks/marketplaceData.duck';
// ================ Action types ================ //

export const SEARCH_BUSINESSES_REQUEST = 'app/SearchPage/SEARCH_BUSINESSES_REQUEST';
export const SEARCH_BUSINESSES_SUCCESS = 'app/SearchPage/SEARCH_BUSINESSES_SUCCESS';
export const SEARCH_BUSINESSES_ERROR = 'app/SearchPage/SEARCH_BUSINESSES_ERROR';


// ================ Reducer ================ //
const LIMIT = 10
const initialState = {
  searches: {}, // searches contains a list of business ids. each id has an entry in businesss
};

const searchPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
            

    case SEARCH_BUSINESSES_REQUEST: // payload is query
      const s =  state.searches[payload]
      return {
        searches: {
          ...state.searches,
          [payload]: {...s, inprogress: true}
        }
      }

      case SEARCH_BUSINESSES_SUCCESS:
        const { query, bids, hasMore } = payload;
        const old_bids = state.searches[query].bids || [];
        const old_offset = state.searches[query].old_offset || 0;
        const new_bids = [...old_bids, ...bids];
        return {
          ...state,
          searches: {
            ...state.searches,
            [query]: {
              hasMore,
              bids: new_bids,
              offset: old_offset + LIMIT,
              inprogress:false
            },
          },
        };
      
    case SEARCH_BUSINESSES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchListingsError: payload };
    

    default:
      return state;
  }
};

export default searchPageReducer;

// ================ Action creators ================ //

export const searchRequest = query => ({
  type: SEARCH_BUSINESSES_REQUEST,
  payload: query,
});

export const searchSuccess = payload => ({
  type: SEARCH_BUSINESSES_SUCCESS,
  payload: payload,
});

export const searchError = e => ({
  type: SEARCH_BUSINESSES_ERROR,
  error: true,
  payload: e,
});



export const fetchSearch = (query, offset) => (dispatch, getState) => {
  const LIMIT = 10
  const searches = getState().SearchPage.searches
  const cachedSearch = query in searches && searches[query]
  const count = cachedSearch?.bids?.length || 0
  if(cachedSearch && (offset + LIMIT < count || cachedSearch.inprogress)) return Promise.resolve(cachedSearch)

  dispatch(searchRequest(query));
  return getSearch({query, offset}).then(response => {
    const {businesses, hasMore} = response.data
    const bids =   []
    for(const b of businesses)
    {
      dispatch(upsertBusiness(b))
      bids.push(b['id'])
    }


    dispatch(searchSuccess({query, bids, hasMore}));
    return {bids, hasMore}

    
  }).catch(e => dispatch(searchError(e)))


};



