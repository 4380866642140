import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import {  SecondaryButton, DangerButton, DiscouragedButton,  NeutralBlueButton} from '../../../../components';
import { 
  CUSTOMER_ROLE, WORKER_ROLE, OPERATION_SET_ROLE,
  UPDATE_EMAIL, RESET_PASSWORD, DELETE_ACCOUNT, TOGGLE_ROLE

} from '../../../../util/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useBackend, useCache } from '../../../../context';

const SettingsTab = (props) => {
  const {onUpdateUser} = useBackend()
  const {updateUserInProgress, isCustomer} = useCache()
  
  const { logout } = useAuth0();
  const handleResetPassword = () => {
    console.log('Reset password clicked'); // Replace with your reset password logic
  };

  const handleDeleteAccount = () => {
    console.log('Delete account clicked'); // Replace with your delete account logic
  };

  const handleListServices = () => {
    const newRole = isCustomer ? WORKER_ROLE : CUSTOMER_ROLE;
    onUpdateUser({ op: OPERATION_SET_ROLE, role: newRole });
  };


  return (
    <Box
      component="form"
      sx={{ mt: 4, width: '100%', maxWidth: 400, mx: 'auto' }}
    >
      <Typography variant="h5" gutterBottom>
        Settings
      </Typography>
      <SecondaryButton
        fullWidth
        message="Reset Password"
        onClick={handleResetPassword}
        inprogress={updateUserInProgress === RESET_PASSWORD}
        sx={{ mt: 2 }}
      />
      <NeutralBlueButton
        fullWidth
        message={`Switch to ${isCustomer ? 'Provider' : 'Customer'} Mode`}
        onClick={handleListServices}
        inprogress={updateUserInProgress === OPERATION_SET_ROLE}
        sx={{ mt: 2 }}
      />
      <DiscouragedButton
        fullWidth
        message={`logout`}
        onClick={()=>{logout({ logoutParams: { returnTo: process.env.REACT_APP_MARKETPLACE_ROOT_URL } })}}
        sx={{ mt: 2 }}
      />

      <DangerButton
        fullWidth
        message="Delete Account"
        onClick={handleDeleteAccount}
        inprogress={updateUserInProgress === DELETE_ACCOUNT}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default SettingsTab;
