// DrawerIcon.js
import React from 'react';
import { IconButton, Skeleton } from '@mui/material';

const DrawerIcon = React.memo(({ component, setIsOpen, isInitialized }) => {
  return (
    <IconButton onClick={() => setIsOpen(true)} aria-label="drawer-icon">
      {isInitialized ? component : <Skeleton variant="circular" width={25} height={25} />}
    </IconButton>
  );
});

export default DrawerIcon;
