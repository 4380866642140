import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

const FieldCurrencyInput = ({ control, name, label }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <NumericFormatWrapper
        {...field}
        label={label}
      />
    )}
  />
);

const NumericFormatWrapper = forwardRef((props, ref) => (
  <NumericFormat
    {...props}
    customInput={TextField}
    getInputRef={ref}
    thousandSeparator={true}
    decimalScale={2}
    fixedDecimalScale={true}
    prefix={'$'}
    onChange={(e) => {
      const str_value = e.target.value.replace("$",'')
      const float_value = parseFloat(str_value)
      props.onChange(float_value)
    }}
  />
));

export default FieldCurrencyInput;
