import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, CircularProgress,TextField  } from '@mui/material';
import {useBackend, useStripeConnect, useModal} from '../../../context'
import {InvoiceDisplay, FieldCurrencyInput, PrimaryButton } from '../../../components'
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import {calculateCost} from '../../../ducks/business.duck'
import { useForm } from 'react-hook-form';
import { NOTIFICATION_MARK_AS_PAID, EDGE_TEMPLATES } from '../../../util/constants';
import { useSendbird } from '../../../context';

const Hint = () => (
    <Typography variant="body2" sx={{
        padding: "32px",
        color: "#666"
    }}>
      Beavr does not save your payment information. We recommend using link to save it securely with Stripe.
    </Typography>
)


const TipSelector = ({ baseInvoice, setPayClicked, setClientSecret, setLoadingSecret, txId }) => {
    const [tip, setTip] = useState(15); // This is used for percentage tips
    const [isCustomTip, setIsCustomTip] = useState(false); // To track if custom tip is selected
    const [invoice, setInvoice] = useState(baseInvoice);
    const { control, watch } = useForm(); // Hook for controlling form state
    const customTip = watch('customTip', 0); // Watch custom tip changes
    const {onGetPaymentSession} = useBackend()
    const fetchClientSecret =  () => {
        setLoadingSecret(true);
        const secretPromise = onGetPaymentSession({ id: txId, tip });
        secretPromise.then(secret => {
          setClientSecret(secret);
          setLoadingSecret(false);
        })
        
      };
  
    useEffect(() => {
        const costData = calculateCost(invoice.lines, invoice.hourlyrate, invoice.minpay);

        // Calculate tip based on percentage or custom dollar amount
        const tip_amount = isCustomTip
            ? parseFloat(customTip || 0).toFixed(2) // Custom tip is directly in dollars
            : ((costData.final_price * tip) / 100).toFixed(2); // Percentage-based tip

        setInvoice({ ...invoice, tip: tip_amount });

        fetchClientSecret()
    }, [tip, customTip, isCustomTip]);

    const handleTipChange = (event) => {
        const selectedTip = event.target.value;

        // Check if the custom tip is selected
        if (selectedTip === 'custom') {
            setIsCustomTip(true);
            setTip(0); // Reset the percentage tip
        } else {
            setIsCustomTip(false);
            setTip(Number(selectedTip)); // Set the percentage tip
        }
    };

    return (
        <>
            <RadioGroup row name="tip" value={isCustomTip ? 'custom' : tip} onChange={handleTipChange}>
                <FormControlLabel value={10} control={<Radio />} label="10%" />
                <FormControlLabel value={15} control={<Radio />} label="15%" />
                <FormControlLabel value={20} control={<Radio />} label="20%" />
                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
            </RadioGroup>

            {/* Show custom tip input when "Custom" is selected */}
            {isCustomTip && (
                <FieldCurrencyInput control={control} name="customTip" label="Custom Tip ($)" />
            )}

            <InvoiceDisplay invoice={invoice} />

            <PrimaryButton message="pay" sx={{width:"75%"}} inprogress={false}
            onClick= {()=>{
                setPayClicked(true)
            }}/>
        </>
    );
};


const StripeForm = ({clientSecret, txId, channel_url}) => {
    const { stripePromise } = useStripeConnect();
    const {PayModalControl} = ()=>{}
    const {onSendTxUpdate} = useSendbird()
    const {onUpdateTransaction} = useBackend()
    const onComplete = () => {
        const data = {id: txId, last_notification_type: NOTIFICATION_MARK_AS_PAID}
        onUpdateTransaction(data).then(()=>{
            onSendTxUpdate(channel_url, data).then(()=>{
                PayModalControl.closeModal()
            })
        })
        
    }
    return (
        <>
        <Hint/>
        <EmbeddedCheckoutProvider
              key={clientSecret} // Key ensures re-mounting when clientSecret changes
              stripe={stripePromise}
              options={{ 
                clientSecret, 
                onComplete,
               }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </>
    )
}


function PayForm({ tx }) {
    const [clientSecret, setClientSecret] = useState(null);
    const [payClicked, setPayClicked] = useState(false)
    const [loadingSecret, setLoadingSecret] = useState(false)
    

    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width:"100%" }}>
    
    {payClicked && loadingSecret? <CircularProgress/>:
    
    payClicked? <StripeForm clientSecret={clientSecret} txId={tx.id} channel_url={tx.channel_url}/>:
    
    <TipSelector 
    txId={tx.id} baseInvoice={tx.invoice} setClientSecret={setClientSecret} setPayClicked={setPayClicked}
     setLoadingSecret={setLoadingSecret}
     />
     }
  </Box>;
}

export default PayForm;
