import React, { useState, useEffect } from 'react';
import GroupChannel from '@sendbird/uikit-react/GroupChannel'; // Correct import
import { useBackend, useCache, useModal, useNavigation, useSendbird, useView } from '../../../context';
import { GroupChannelHeader } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { IconButton, Box, Drawer } from '@mui/material';
import CustomMessage from '../CustomMessage/CustomMessage'
import { Message } from '@sendbird/uikit-react/GroupChannel/components/Message';
import { WORKER_ROLE } from '../../../util/constants';
import ChatInput from './ChatInput';
import BoltIcon from '@mui/icons-material/Bolt';
import { useAuth0 } from '@auth0/auth0-react';

const CUSTOME_TYPES = ['locations', 'buckets', 'availabilities']

const ActionButton = ({ctx}) => {
  const {navigateByName} = useNavigation()
  return <IconButton 
  color={ctx ? "primary" : "default"}
  onClick={()=>navigateByName("ActivityPage", {state: ctx})}>
    <BoltIcon/>
  </IconButton>
};

const CustomChannel = (props) => {
  const { handleSelectChannel, onHandleInquiry, currentChannel: channel } = useSendbird();
  const {onGetChannelTransaction} = useBackend()
  const [ctx, setChannelTx] = useState()
  const channel_url = channel?._url;
    useEffect(() => {
    if(!channel_url ) return


    onGetChannelTransaction(channel_url).then(tx =>setChannelTx(tx))
  }, [channel_url]);
  return (
    <>
      <GroupChannel
        channelUrl={channel_url}
        onBackClick={() => {
          handleSelectChannel(null);
        }}
        onBeforeSendUserMessage={(message) => {
          onHandleInquiry(channel);
          return message;
        }}
        renderChannelHeader={() => (
          <GroupChannelHeader renderRight={() => <ActionButton ctx={ctx}/>} />
        )}

        renderMessage={({message}) => {
          const type = message?.customType
          return CUSTOME_TYPES.includes(type)? <>
          <Message message={message}/>
          <CustomMessage message={message}/>
          </> : <Message message={message}/>
        }}

        renderMessageInput={(props)=>{
            return <Box sx={{backgroundColor:"blue"}}>
              <ChatInput channel={channel} />
            </Box>
        }}
      />
    </>
  );
};

export default CustomChannel;
