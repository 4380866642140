import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import createReducer from './reducers';

export default function configureStore(initialState = {}) {
  const middlewares = [thunk];

  const enhancer = compose(applyMiddleware(...middlewares));

  const store = createStore(createReducer(), initialState, enhancer);

  return store;
}
