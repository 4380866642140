import React, { useEffect } from 'react';
import { AvatarForm, BioForm } from '../../../../components';
import {encodeMessage} from '../../../../util/utils'
import { useBackend, useCache } from '../../../../context';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

const ProfileTab = ({setTabButton}) => {
  const {onUpdateProfile} = useBackend()
  const { profile, updateProfileInProgress } = useCache();

  const { control, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      bio: profile?.bio || '',
      picture: profile?.picture || ''
    }
  });
  
  const bio = watch('bio');
  const picture = watch('picture');

  
  useEffect(() => {
    const bioChanged = bio !== profile?.bio;
    const urlChanged = picture !== profile?.picture;
    const newData = {};
    if (bioChanged) newData.bio = encodeMessage(bio);
    if (urlChanged) newData.picture = picture;

    setTabButton({
      onSubmit: handleSubmit(() => {
        onUpdateProfile(newData);
        console.log(newData)
      }),
      inprogress: updateProfileInProgress,
      // disabled: !bioChanged && !urlChanged,
    });
  }, [bio, picture, profile, updateProfileInProgress]);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
      <AvatarForm setUrl={(url)=>setValue('picture', url)} url={picture}/>
      <BioForm control={control} bio={bio} />
    </Box>
  );
};

export default ProfileTab;
