import React, {useState} from 'react';
import { Avatar, Typography, Box } from '@mui/material';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import { useCache, useSendbird } from '../../../context';
import { GroupChannelListHeader } from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader';
const CustomChannelList = () => {
  const {handleSelectChannel} = useSendbird()
  const {user} = useCache()
  return (
  <GroupChannelList
  disableAutoSelect={true} 
  onChannelSelect={handleSelectChannel}
  renderHeader={()=> <GroupChannelListHeader 
    renderLeft={()=> <Avatar src={user?.picture} alt={user?.given_name}>
    {user?.given_name?.[0] /* Fallback to first letter if picture not available */}
  </Avatar>}
    renderMiddle={()=> <Typography variant="h6" component="div">
      {user?.given_name || 'Guest'}
    </Typography>}
  />}
  />
  );
};

export default CustomChannelList;
