// CONFIGS (later this should be moved out to a config file and fetched from the server)
export const CURRENCY = 'USD';
// ================ PAGE CONSTANTS ================ //

// ================ LINE CONSTANTS ================ //



// ================ IMAGE CONSTANTS ================ //

export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

// src/util/constants.js
export const PERIODS = [
  { id: 'morning', label: 'Morning' },
  { id: 'afternoon', label: 'Afternoon' },
  { id: 'evening', label: 'Evening' },
  // Add more periods as needed
];



export const UPDATE_IMAGE_MANIFEST = 'update_image_manifest'

export const UPLOAD_SERVICE_IMAGE_REQUEST = 'upload_serviceImage_request'
export const UPLOAD_AVATAR_REQUEST = 'upload_avatar_request'

export const DELETE_SERVICE_IMAGE = 'DELETE_SERVICE_IMAGE'



// Possible amount of stars in a review

// ================ ROLES & TYPES CONSTANTS ================ //

export const CUSTOMER_ROLE = 'customer';
export const WORKER_ROLE = 'provider';

// ================ OPERATION CONSTANTS ================ //

export const OPERATION_UPDATE_USER = 'upsert_user'
export const OPERATION_UPDATE_BUSINESS = 'update_business'

export const OPERATION_SEND_OTP = 'send_verify_sms'
export const OPERATION_VERIFY_OTP = 'verify_otp'
export const OPERATION_GET_CHANNEL_TX = 'get_channel_transaction'
export const OPERATION_CREATE_TX = 'create_tx'
export const OPERATION_GET_PAYMENT_SESSION = 'get_payment_session'
export const GET_TXs = 'load_txs'
export const OPERATION_SET_ROLE = 'switch_role'
export const OPERATION_CHECK_SLUG_AVAILABILITY = 'check_slug_availability'

export const BUSINESS_BIO_PLACEHOLDER = "Hi, I'm [Name], a skilled and reliable [Category/Profession] with [X] years of experience.\
 I specialize in [service type] and am dedicated to providing high-quality work with attention to detail.\
 Whether you need help with [specific task] or [another task], I'm here to assist and make sure everything runs smoothly. Let’s get the job done together!"

 export const CUSTOMER_BIO_PLACEHOLDER = "Hey, I’m [Name]! I love spending my time [hobby or interest], \
 and when I'm not doing that, I'm probably planning my next [fun activity or project]. \
 I’m here looking for help with things like [task type] so I can free up more time for the things I enjoy. Excited to find someone who can help make life a little easier!"


 export const OPERATION_GET_USER = 'get_user'
export const OPERATION_GET_OWN_BUSINESS = 'get_own_business'



// UPDATE OPERATION TYPES
export const UPDATE_EMAIL = 'update_email'
export const RESET_PASSWORD = 'update_password'
export const DELETE_ACCOUNT = 'delete_account'
export const TOGGLE_ROLE = 'toggle_role'
export const OPERATION_GET_BUSINESS_DATA = 'get_business_data'
// ================ TABS, STATES & TRANSITIONS CONSTANTS ================ //

export const TAB_NAMES = {
  FIRST: {
    PROFILE: 'profile',
  },
  USER: {
    PERSONAL: 'personal'
  },
  BUSINESS: {
    FRONTBUSINESSCARD: 'frontbusinesscard',
    BACKBUSINESSCARD: 'backbusinesscard',
    RATES: 'rates',
    SERVICEAREA: 'servicearea',
    IMAGES: 'images',
    // QUOTES: 'quotes',
    // STRIPE: 'stripe'
  },
  LAST: {
    SETTINGS: 'settings',
  },
};




  
// ================ OTHER CONSTANTS ================ //

export const NOTIFICATION_CANCEL = 'cancel'
export const NOTIFICATION_UPDATE_SCHEDULE = 'update_schedule'
export const NOTIFICATION_UPDATE_INVOICE = 'update_invoice'
export const NOTIFICATION_UPDATE_REVIEW = 'update_review'
export const NOTIFICATION_MARK_AS_PAID = 'mark_as_paid'

export const OPERATION_CREATE_TRANSACTION = 'create_transaction'
export const OPERATION_UPDATE_TRANSACTION = 'update_transaction'


// can't cancel anymore.
export const LEAF_STATES = [NOTIFICATION_CANCEL, NOTIFICATION_MARK_AS_PAID, NOTIFICATION_UPDATE_REVIEW, NOTIFICATION_UPDATE_INVOICE]


export const OPERATION_UPDATE_LISTING = 'update_listing'

export const EDGE_TEMPLATES = {
  [NOTIFICATION_UPDATE_SCHEDULE]: {
      'message': 'user scheduled you for DATA_PLACEHOLDER',
      'allowed_actors': ['provider'],
      'allowed_from': ['INQUIRED', 'SCHEDULED'],
      'to': 'SCHEDULED',
  },
  'CANCEL': {
      'message': 'user canceled your activity',
      'allowed_actors': ['customer', 'provider'],
      'allowed_from': ['SCHEDULED'],
      'to': 'FAILED_CONVERSION',
  },
  [NOTIFICATION_UPDATE_INVOICE]: {
      'message': 'user marked your activity as complete. your total is DATA_PLACEHOLDER',
      'allowed_actors': ['provider'],
      'allowed_from': ['SCHEDULED'],
      'to': 'INVOICED',
  },
  [NOTIFICATION_MARK_AS_PAID]: {
      'message': 'user marked your activity as paid',
      'allowed_actors': ['customer'],
      'allowed_from': ['INVOICED'],
      'to': 'PAID',
  },
  [NOTIFICATION_UPDATE_REVIEW]: {
      'message': 'user reviewed this activity!',
      'allowed_actors': ['provider'],
      'allowed_from': ['SCHEDULED'],
      'to': 'REVIEWED',
  },
}


export const NOTIFICATION_UPDATE_TYPES = [OPERATION_CREATE_TRANSACTION,OPERATION_UPDATE_TRANSACTION, NOTIFICATION_UPDATE_SCHEDULE, 
  NOTIFICATION_UPDATE_INVOICE, NOTIFICATION_UPDATE_REVIEW, NOTIFICATION_MARK_AS_PAID]

  
export const ALL_POSSIBLE_SERVICES = ["cleaning", "assembly", "moving",  "painting", "general-handyman"]



// ================ SERVICES AND DEFAULT QUOTES ================ //
// <sname>: {
//   <unit1>: {"default lowest time": <int>, "default highest time": <int>},
//   <unit2>: {"default lowest time": <int>, "default highest time": <int>},
//   ....
export const SERVICES = {
  assembly: {
    'Chair': {"lower_limit": 10, "upper_limit": 15, "quotetype": "per_item"},
    'Bed': {"lower_limit": 20, "upper_limit": 30, "quotetype": "per_item"},
    'Sofa': {"lower_limit": 30, "upper_limit": 40, "quotetype": "per_item"},
    'Other': {"quotetype": "hourly"}
  },
  plumbing: {
    'Sink': {"lower_limit": 10, "upper_limit": 15, "quotetype": "per_item"},
    'Toilet': {"lower_limit": 20, "upper_limit": 30, "quotetype": "per_item"},
    'Shower': {"lower_limit": 30, "upper_limit": 40, "quotetype": "per_item"},
    'Other': {"quotetype": "hourly"}
  },
  cleaning: {
    'Bathroom': {"lower_limit": 10, "upper_limit": 15, "quotetype": "per_item"},
    'Kitchen': {"lower_limit": 20, "upper_limit": 30, "quotetype": "per_item"},
    'Living Room': {"lower_limit": 30, "upper_limit": 40, "quotetype": "per_item"},
    'Other': {"quotetype": "hourly"}
  },
  moving: {
    'Studio': {"lower_limit": 60, "upper_limit": 90, "quotetype": "per_item"},
    'Bedroom': {"lower_limit": 90, "upper_limit": 120, "quotetype": "per_item"},
    'Office': {"lower_limit": 90, "upper_limit": 120, "quotetype": "per_item"},
    'Other': {"quotetype": "hourly"}
  },
  handyman: {
    'TV Mount': {"quotetype": "flat", 'price': 100},
    'Mirror & Shelf Mount': {"lower_limit": 20, "upper_limit": 30, "quotetype": "flat"},
    'Other': {"quotetype": "hourly"}
  },
}