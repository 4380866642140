import React, {useState, useEffect} from 'react';
import {AvailabilityForm} from '../index';
import { useManager } from '../../context';

const SelectedAvailability = () => {
  const {selectedSharable, updateSharableData} = useManager('availabilities')
  

  return selectedSharable ? (
    <AvailabilityForm
      key={selectedSharable.id}
      data={selectedSharable.data}
      setData={updateSharableData}
    />
  ) : null;
};

export default SelectedAvailability;
