import React, {useState} from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Slide, Box, Typography } from '@mui/material';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import { Share as ShareIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material';


const Sharebutton = ({ url, title }) => {
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <>
        <IconButton aria-label="share" onClick={handleOpen} >
          <ShareIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '10px 10px 0 0',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maxHeight: '50%',
              margin: 0,
            },
          }}
        >
          <DialogTitle>Share this profile</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <EmailShareButton url={url} subject={title}>
                <Box display="flex" alignItems="center" gap={1}>
                  <EmailIcon size={32} round />
                  <Typography>Email</Typography>
                </Box>
              </EmailShareButton>
              <FacebookShareButton url={url} quote={title}>
                <Box display="flex" alignItems="center" gap={1}>
                  <FacebookIcon size={32} round />
                  <Typography>Facebook</Typography>
                </Box>
              </FacebookShareButton>
              <TwitterShareButton url={url} title={title}>
                <Box display="flex" alignItems="center" gap={1}>
                  <TwitterIcon size={32} round />
                  <Typography>Twitter</Typography>
                </Box>
              </TwitterShareButton>
              <WhatsappShareButton url={url} title={title}>
                <Box display="flex" alignItems="center" gap={1}>
                  <WhatsappIcon size={32} round />
                  <Typography>WhatsApp</Typography>
                </Box>
              </WhatsappShareButton>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  alert('Link copied to clipboard');
                }}
              >
                <ContentCopyIcon />
                <Typography>Copy Link</Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };


  export default Sharebutton