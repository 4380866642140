import {
  postBeavrUser,
  getBeavrUser,
} from '../util/api'
import {
  OPERATION_GET_USER,
  OPERATION_UPDATE_USER,
} from '../util/constants'
import {decodeMessage, parseList} from '../util/utils'
// ================ Action types ================ //






 const UPDATE_BEAVR_REQEUST = 'app/user/UPDATE_USER_REQEUST';
 const UPDATE_BEAVR_SUCCESS = 'app/user/UPDATE_BEAVR_SUCCESS';
 const UPDATE_BEAVR_ERROR = 'app/user/UPDATE_BEAVR_ERROR';



 const UPDATE_AUTH_STATUS = 'app/user/UPDATE_AUTH_STATS';

// ================ Reducer ================ //




const initialState = {
  isAuthenticated: false,
  user: null,
  updateUserInProgress: '',
  fetchIpGeoinprogress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {


    case UPDATE_BEAVR_REQEUST:
      return { ...state, updateUserInProgress: payload };
    case UPDATE_BEAVR_ERROR:
      return { ...state, updateUserInProgress: '' };  
  
      case UPDATE_BEAVR_SUCCESS:
        const {user: newUser} = payload
        newUser?.bio && (newUser.bio = decodeMessage(newUser.bio));
        return {
          ...state,
          user: {...state.user, ...newUser},
          updateUserInProgress: ''
        };      
       
  
   
      
  
    
    
    case UPDATE_AUTH_STATUS:
      return {...state, isAuthenticated: payload}
    default:
      return state;
  }
}

// ================ Selectors ================ //


// ================ Action creators ================ //

export const updateAuthStatus = (isAuthenticated) => ({ type: UPDATE_AUTH_STATUS, payload: isAuthenticated });
  











export const updateBeavrRequest = (updateType) => ({ type: UPDATE_BEAVR_REQEUST, payload: updateType });


export const updateBeavrError = (e) => ({
  type: UPDATE_BEAVR_ERROR,
  payload: e
});


export const updateUserSuccess = (payload) => ({
  type: UPDATE_BEAVR_SUCCESS,
  payload: payload
});








// ================ Thunks ================ //


// updates  user
export const updateUser = (data) => (dispatch, getState) => {
  const {op = OPERATION_UPDATE_USER} = data
  const {id} = getState().user?.user || {}
  if(!id) return
  
  dispatch(updateBeavrRequest(op));
  return postBeavrUser({op, ...data }).then((res) => {
    dispatch(updateUserSuccess({ user: {id, ...data} }));
  })
  .catch((error) => {
    console.error('Error updating user:', error);
    // You might want to dispatch an error action here
  });
};







export const fetchUser = () =>  (dispatch, getState) => {
  const {updateUserInProgress} = getState().user
  if(updateUserInProgress) return
  dispatch(updateBeavrRequest())
  return getBeavrUser({op: OPERATION_GET_USER}).then((response) =>
    {
      const user = response.data;
      const {availabilities, favorites, buckets, locations} = user
      user.availabilities = parseList(availabilities)
      user.favorites = parseList(favorites)
      user.buckets = parseList(buckets)
      user.locations = parseList(locations)
      dispatch(updateUserSuccess({user}))

    }
    
    ).catch(e => dispatch(console.log(e)))
}

