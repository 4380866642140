import React from 'react';
import {Grid, Typography, Divider, Paper } from '@mui/material';
import { calculateCost } from '../../ducks/business.duck';

const InvoiceDisplay = (props) => {
  const { invoice } = props;
  const invoiceData = invoice || { lines: [] };

  // Calculate cost from invoice data
  const costData = calculateCost(invoiceData.lines, invoiceData.hourlyrate, invoiceData.minpay);
  const totalWithoutTip = costData.final_price;
  
  // Add tip to the total, ensuring tip is a number
  const tip = invoiceData.tip ? parseFloat(invoiceData.tip) : 0;
  const totalWithTip = (totalWithoutTip + tip).toFixed(2);

  // Helper function to calculate the charge for each line (time in minutes)
  const calculateLineCharge = (time, hourlyrate) => {
    const hours = time / 60; // Convert minutes to hours
    return (hours * hourlyrate).toFixed(2);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: '#f9f9f9', width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Invoice
      </Typography>
      {invoiceData.lines.length === 0 ? (
        <Typography variant="body1">Project is not completed yet</Typography>
      ) : (
        <>
          {invoiceData.lines.map((line, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 1, alignItems: 'center' }}>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {line.count} {line.id}
                </Typography>
              </Grid>
              {line.time && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="body1" align="right">{line.time} Minute(s)</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" align="right">${calculateLineCharge(line.time, invoiceData.hourlyrate)}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          ))}

          <Divider sx={{ my: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="body1">Subtotal:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" align="right">${totalWithoutTip.toFixed(2)}</Typography>
            </Grid>
          </Grid>

          {tip > 0 && (
            <>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={9}>
                  <Typography variant="body1">Tip:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" align="right">${tip.toFixed(2)}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
            </>
          )}

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={9}>
              <Typography variant="h6">Total:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" align="right">${totalWithTip}</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default InvoiceDisplay;
