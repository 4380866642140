import React, {useState} from 'react'

import {
  SharableDrawer,
  SimpleButton
  } from '../../components';

import {useManagersStatus} from '../../context'
import {
    Box, 
  } from '@mui/material';
  


  import { PriceCheck } from '@mui/icons-material';





const BusinessQuoter = ({ sx ,}) => {
  const [isOpen, setIsOpen] = useState(false);
    return (
      <Box sx={sx}>
        <SimpleButton onClick={()=>setIsOpen(true)} message="Estimate Cost" sx={{border: 'none', width:"100%"}} startIcon={<PriceCheck />}/>
        <SharableDrawer isOpen={isOpen} setIsOpen={setIsOpen} type="buckets"/>
        
          
      </Box>
    );
  };


  export default BusinessQuoter