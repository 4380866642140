import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { useForm, Controller } from 'react-hook-form';
import { FieldTextInput, FieldCheckboxList } from '../../index';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

const daysOfWeek = {
  "Sunday": 0,
  "Monday": 1,
  "Tuesday": 2,
  "Wednesday": 3,
  "Thursday": 4,
  "Friday": 5,
  "Saturday": 6
};

function Scheduler({ unixSchedule, setUnixSchedule, slist=[] }) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { control, setValue, watch } = useForm({
    defaultValues: { day: '', startTime: '', endTime: '', services: [] },
  });
  const schedule = watch();

  useEffect(() => {
    if (unixSchedule && timezone && unixSchedule.startTime && unixSchedule.endTime) {
      const startDate = dayjs.unix(unixSchedule.startTime).tz(timezone);
      const endDate = dayjs.unix(unixSchedule.endTime).tz(timezone);

      setValue('day', startDate.format('dddd'));
      setValue('startTime', startDate.format('HH:mm'));
      setValue('endTime', endDate.format('HH:mm'));
      if (unixSchedule.services) {
        setValue('services', unixSchedule.services);
      }
    }
  }, [unixSchedule, timezone, setValue]);

  useEffect(() => {
    if (slist.length === 1) {
      const updatedSchedule = { ...schedule, services: slist };
      setValue('services', slist);
      updateUnixSchedule(updatedSchedule);
    }
  }, [slist, schedule, setValue]);

  const handleDayChange = (event) => {
    const updatedSchedule = { ...schedule, day: event.target.value };
    setValue('day', event.target.value);
    updateUnixSchedule(updatedSchedule);
  };

  const handleStartTimeChange = (event) => {
    const updatedSchedule = { ...schedule, startTime: event.target.value };
    setValue('startTime', event.target.value);
    updateUnixSchedule(updatedSchedule);
  };

  const handleEndTimeChange = (event) => {
    const updatedSchedule = { ...schedule, endTime: event.target.value };
    setValue('endTime', event.target.value);
    updateUnixSchedule(updatedSchedule);
  };

  const handleCheckboxChange = (services) => {
    const updatedSchedule = { ...schedule, services };
    setValue('services', services);
    updateUnixSchedule(updatedSchedule);
  };

  const updateUnixSchedule = (updatedSchedule) => {
    if (updatedSchedule.day && updatedSchedule.startTime && updatedSchedule.endTime) {
      try {
        const dayOfWeek = daysOfWeek[updatedSchedule.day];
        const startDate = dayjs().day(dayOfWeek).hour(updatedSchedule.startTime.split(':')[0]).minute(updatedSchedule.startTime.split(':')[1]).second(0).millisecond(0).tz(timezone);
        const endDate = dayjs().day(dayOfWeek).hour(updatedSchedule.endTime.split(':')[0]).minute(updatedSchedule.endTime.split(':')[1]).second(0).millisecond(0).tz(timezone);

        setUnixSchedule({
          startTime: startDate.unix(),
          endTime: endDate.unix(),
          services: updatedSchedule.services,
        });
      } catch (error) {
        console.error('Error updating unixSchedule:', error);
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300, mt: 2, mx: 'auto' }}
    >
      <Typography variant="h6" component="div">
        Schedule Details
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="day-select-label">Day of the Week</InputLabel>
        <Select
          labelId="day-select-label"
          id="day-select"
          value={schedule.day}
          label="Day of the Week"
          onChange={handleDayChange}
        >
          {Object.keys(daysOfWeek).map(day => (
            <MenuItem key={day} value={day}>{day}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FieldTextInput
        control={control}
        name="startTime"
        label="Start Time"
        type="time"
        value={schedule.startTime}
        onChange={handleStartTimeChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        fullWidth
      />
      <FieldTextInput
        control={control}
        name="endTime"
        label="End Time"
        type="time"
        value={schedule.endTime}
        onChange={handleEndTimeChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        fullWidth
      />
      {slist.length > 1 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" component="div">
            Select Services
          </Typography>
          <FieldCheckboxList
            control={control}
            name="services"
            options={slist}
            className="checkbox-list"
            onChange={handleCheckboxChange}
          />
        </>
      )}
    </Box>
  );
}

export default Scheduler;
