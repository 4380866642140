
import {GET_TXs,  NOTIFICATION_UPDATE_SCHEDULE, NOTIFICATION_UPDATE_REVIEW,
   OPERATION_GET_PAYMENT_SESSION,
   OPERATION_GET_CHANNEL_TX,
  OPERATION_CREATE_TRANSACTION, OPERATION_UPDATE_TRANSACTION, 
  LEAF_STATES} from '../util/constants';
import { postTransaction, getTransaction } from '../util/api';
import {standarize_tx_data} from '../util/utils'
import {getCachedChannelTx, upsertTransaction} from './marketplaceData.duck';
// ================ Action types ================ //


export const UPDATE_TX_REQUEST = 'app/Transactions/UPDATE_TX_REQUEST';
export const UPDATE_TX_SUCCESS = 'app/Transactions/UPDATE_TX_SUCCESS';
export const UPDATE_TX_ERROR = 'app/Transactions/UPDATE_TX_ERROR';

export const GET_TXs_REQUEST = 'app/Transactions/GET_TX_REQUEST';
export const GET_TXs_SUCCESS = 'app/Transactions/GET_TX_SUCCESS';
export const GET_TXs_ERROR = 'app/Transactions/GET_TX_ERROR';

export const UPDATE_LAST_SEEN_ID = 'app/Transactions/UPDATE_LAST_SEEN_ID';
// ================ Reducer ================ //

const initialState = {
  // transactions: {}, use the object in marketplace data for now
  getTXsinprogress: false,
  lastSeenId: null,
  hasMore: false,
  updateTransactioninprogress: ''
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LAST_SEEN_ID:
      const {lastSeenId, hasMore} = payload;
      return { ...state, lastSeenId, hasMore };
    case UPDATE_TX_REQUEST:
      return { ...state, updateTransactioninprogress: payload };
    case UPDATE_TX_SUCCESS:
      return { ...state, updateTransactioninprogress: '' };
    case UPDATE_TX_ERROR:
      return { ...state, updateTransactioninprogress: '' };

    case GET_TXs_REQUEST:
      return { ...state, getTXsinprogress: true };
    case GET_TXs_SUCCESS:
      return { ...state, getTXsinprogress: false };
    case GET_TXs_ERROR:
      return { ...state, getTXsinprogress: false };
    
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const updateTxRequest = (utype) => ({ type: UPDATE_TX_REQUEST, payload: utype });
export const updateTxSuccess = () => ({ type: UPDATE_TX_SUCCESS });
export const updateTxError = error => ({
  type: UPDATE_TX_ERROR,
  error: true,
  payload: error,
});

export const getTXsRequest = (utype) => ({ type: GET_TXs_REQUEST, utype });
export const getTXsSuccess = () => ({ type: GET_TXs_SUCCESS });
export const getTXsTxError = error => ({
  type: GET_TXs_ERROR,
  error: true,
  payload: error,
});





// ================ Thunk ================ //
export const createTransaction = (params) => (dispatch, getState) => {

  const {customer, business, lastupdate, state, ...body} = params
  dispatch(updateTxRequest(OPERATION_CREATE_TRANSACTION));
  return postTransaction(body).then(response => {
      const id = response.data
      const {op, ...rest} = body
      const tx = {id, customer, business, lastupdate, state, ...rest}

      dispatch(updateTxSuccess());
      dispatch(upsertTransaction(tx));
      return id
    }).catch(e => {
      dispatch(updateTxError(e));
    });
  };


export const updateTransaction = (params) =>  (dispatch, getState) => {
  dispatch(updateTxRequest('update'));
  return postTransaction(params).then(res => {
    const {op, ...rest} = params
    const tx = standarize_tx_data(rest)
    console.log(res)
    dispatch(updateTxSuccess());
    dispatch(upsertTransaction(tx));
  }).catch(e => {
    dispatch(updateTxError(e));
  });

}



  
  export const getChannelTransaction = (channel_url) => (dispatch, getState) => {
    const isActiveTx = (tx) => tx && !LEAF_STATES.includes(tx?.last_notification_type)

    const cachedTx = dispatch(getCachedChannelTx(channel_url))
    
    if(isActiveTx(cachedTx))
      return Promise.resolve(cachedTx)

    return getTransaction({op: OPERATION_GET_CHANNEL_TX, channel_url}).then(res=>{
      const tx = res?.data
      if(tx)
        dispatch(upsertTransaction(tx));
      if(isActiveTx(tx))
        return tx
    })
  }
  
  export const getTransactions = () => (dispatch, getState) => {
    const lastSeenId = getState().transaction.lastSeenId;
    dispatch(getTXsRequest());
    return getTransaction({op: GET_TXs, lastSeenId}).then(response => {
      dispatch(getTXsSuccess());
      const {transactions, hasMore} = response.data;
      if (transactions.length > 0) {
        const lastSeenId = transactions[transactions.length - 1].id;
        dispatch({ type: UPDATE_LAST_SEEN_ID, payload: {lastSeenId, hasMore} });
      }
      for (const tx of transactions){
        dispatch(upsertTransaction(tx))
      }

      return {transactions, hasMore}
    }).catch(e => {
      dispatch(getTXsTxError(e));
    })
  }
    
  export const getClientSecret = (params) => (dispatch, getState) => {
    return getTransaction({op: OPERATION_GET_PAYMENT_SESSION, ...params}).then(res =>{
      console.log(res)
      const clientSecret = res.data['client_secret']
      return clientSecret
    })
  }

