// ChatInput.js
import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import { useSendbird } from '../../../context';
import { TypingIndicator } from '@sendbird/uikit-react/GroupChannel/components/TypingIndicator';
import { MessageInputWrapper } from '@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper';

import AttachmentMenu from './AttachmentMenu'; // Adjust the path as necessary

const ChatInput = ({ channel }) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const { onSendMessage } = useSendbird();
  const typingTimeoutRef = useRef(null);

  // Handle typing indicator
  const handleIsTypingIndicator = () => {
    channel.startTyping();

    // Clear existing timer if any
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timer to end typing after 2 seconds of inactivity
    typingTimeoutRef.current = setTimeout(() => {
      channel.endTyping();
      typingTimeoutRef.current = null;
    }, 2000); // Changed to 2000ms for 2 seconds
  };

  // Handle opening the attachment menu
  const handleAttachmentClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the attachment menu
  const handleAttachmentClose = () => {
    setAnchorEl(null);
  };

  // Handle sending the message
  const handleSendMessage = () => {
    if (message.trim() !== '') {
      onSendMessage(channel._url, message);
      setMessage('');
      channel.endTyping(); // End typing when message is sent
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = null;
      }
    }
  };

  // Handle key press events (e.g., Enter key to send message)
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // Cleanup the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        borderTop: '1px solid #ddd',
        backgroundColor: 'var(--backgroundColor)',
      }}
    >
      {/* Emoji Button */}
      <IconButton color="primary" sx={{ mr: 1 }} aria-label="insert emoji">
        <InsertEmoticonIcon />
      </IconButton>

      {/* Text Input */}
      <TextField
        multiline
        maxRows={4}
        variant="outlined"
        placeholder="Type a message..."
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
          handleIsTypingIndicator();
        }}
        onKeyPress={handleKeyPress}
        sx={{
          flexGrow: 1,
          backgroundColor: 'white',
          borderRadius: 1,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {/* Attachment Button */}
              <IconButton
                color="primary"
                onClick={handleAttachmentClick}
                aria-label="open attachment menu"
              >
                <AttachFileIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* AttachmentMenu Component */}
      <AttachmentMenu
        anchorEl={anchorEl}
        handleAttachmentClose={handleAttachmentClose}
        channel={channel}
      />

      {/* Send Button */}
      <Tooltip title="Send">
        <span>
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            disabled={message.trim() === ''}
            sx={{
              ml: 1,
            }}
            aria-label="send message"
          >
            <SendIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ChatInput;
