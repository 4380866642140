// src/components/Availability/RangeStack.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import RangeAreaWithPeriods from './RangeAreaWithPeriods';

const RangeStack = ({ items, setItems, onlyDisplay = false }) => {
  const onRemoveRange = (id) => {
    const newRanges = items.filter((obj) => obj.id !== id);
    setItems(newRanges);
  };

  const onRemovePeriod = ({ day, period }) => {
    const updatedRanges = items
      .map((range) => {
        if (range.id === day) {
          return { ...range, periods: range.periods.filter((p) => p !== period) };
        }
        return range;
      })
      .filter((range) => range.periods.length > 0); // Remove ranges with no periods
    setItems(updatedRanges);
  };

  if (!Array.isArray(items)) {
    console.error('RangeStack expects items to be an array, but received:', items);
    return (
      <Typography color="error">
        Error: Invalid data format. Expected an array of ranges.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'auto', // Enable horizontal scrolling
        pb: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: 1
      }}
    >
      {items.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No availability ranges defined.
        </Typography>
      ) : (
        items.map((item) => (
          <RangeAreaWithPeriods
            key={item.id}
            range={item}
            onRemoveRange={onRemoveRange}
            onRemovePeriod={onRemovePeriod}
            onlyDisplay={onlyDisplay}
          />
        ))
      )}
    </Box>
  );
};

export default RangeStack;
