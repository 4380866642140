import React, { useState } from 'react';
import { Box, Menu, MenuItem, IconButton, AppBar, Toolbar } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BoltIcon from '@mui/icons-material/Bolt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MessageIcon from '@mui/icons-material/Message';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { LinkedLogo } from '../../../components';
import { useCache, useNavigation } from '../../../context';

const Bar = () => {
  const { navigateByName } = useNavigation();
  const { isWorker } = useCache();

  // State for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Menu items
  const menuItems = [
    {
      icon: <FavoriteIcon />,
      label: 'Favorites',
      onClick: () => {
        navigateByName('FavoritesPage');
        handleMenuClose();
      },
    },
    {
      icon: <AccountCircleIcon />,
      label: 'Profile',
      onClick: () => {
        navigateByName('AccountPage');
        handleMenuClose();
      },
    },
  ];

  // Conditionally add MonetizationOnIcon to menu if isWorker
  if (isWorker) {
    menuItems.unshift({
      icon: <MonetizationOnIcon />,
      label: 'Earnings',
      onClick: () => {
        navigateByName('FinancesPage'); // Ensure you have this page
        handleMenuClose();
      },
    });
  }

  // Define a subtle black color (dark gray)
  const subtleBlack = '#333333'; // You can adjust this hex code as needed

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100vw"
    >
      <LinkedLogo />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        {/* Primary Icons */}
        <IconButton
          onClick={() => navigateByName('ActivityPage')}
          aria-label="Activity"
          sx={{ color: subtleBlack }}
        >
          <BoltIcon />
        </IconButton>

        <IconButton
          onClick={() => navigateByName('InboxPage')}
          aria-label="Inbox"
          sx={{ color: subtleBlack }}
        >
          <MessageIcon />
        </IconButton>

        {/* Menu for Secondary Icons */}
        <IconButton
          onClick={handleMenuClick}
          aria-label="More options"
          aria-controls={menuOpen ? 'topbar-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          sx={{ color: subtleBlack }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="topbar-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              <Box display="flex" alignItems="center" gap={1}>
                {React.cloneElement(item.icon, { sx: { color: subtleBlack } })}
                {item.label}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

const TopbarDesktop = () => {
  return (
    <AppBar position="sticky">
      <Toolbar sx={{
        width: "100%",
        backgroundColor: 'var(--barBackgroundColor)',
        minHeight: 'var(--topMinBarHeight)', boxShadow: 'var(--barShadow)', borderBottom: 'var(--barBorder)', padding: 'var(--barPadding)',
        }}>
      <Bar/>
      </Toolbar>
      
    </AppBar>
);

}
export default TopbarDesktop;
