import React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';

const FieldCheckboxList = ({ control, name, options, className, onChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => {
        const { value } = field;
        // Ensure value is always an array
        const selectedValues = Array.isArray(value) ? value : [];

        return (
          <FormControl component="fieldset">
            <FormGroup>
              <div className={className}>
                {options.map(option => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={selectedValues.includes(option)}
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...selectedValues, option]
                            : selectedValues.filter(item => item !== option);
                          field.onChange(newValue);
                          if (onChange) onChange(newValue);
                        }}
                        name={option}
                      />
                    }
                    label={option}
                  />
                ))}
              </div>
            </FormGroup>
          </FormControl>
        );
      }}
    />
  );
};

export default FieldCheckboxList;
