// Searchbar.js
import React from 'react';
import { AppBar, Box, Paper } from '@mui/material';
import MapDrawer from './MapDrawer'; 
import AvailabilityDrawer from './AvailabilityDrawer'; 
import BucketDrawer from './BucketDrawer'; 



const Searchbar = React.memo(({ isInitialized }) => {
  return (
    <AppBar position="sticky">
      <Box sx={{ width: '100%', backgroundColor: 'var(--backgroundColor)', display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: 4,
            width: '50%',
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mb: 0.5,
            mt: 0.5,
          }}
        >
          <MapDrawer isInitialized={isInitialized} />
          <AvailabilityDrawer isInitialized={isInitialized} />
          <BucketDrawer isInitialized={isInitialized} />
        </Paper>
      </Box>
    </AppBar>
  );
});

export default Searchbar;
