import React, { useState, useEffect } from 'react';
import {TodoForm,} from '../index';
import { useManager } from '../../context';


const SelectedTodos= () => {
  const {selectedSharable, updateSharableData} = useManager('buckets')

  return selectedSharable ? (
    <TodoForm
      key={selectedSharable.id}
      data={selectedSharable.data}
      setData={updateSharableData}
    />
  ) : null;
};


export default SelectedTodos;
