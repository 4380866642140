import React, { useEffect, useState } from 'react';
import { SmartImage } from '../index';
import { Swiper, SwiperSlide } from 'swiper/react';
import css from './ImageGallery.module.css';
import classNames from 'classnames';

const ImageGallery = (params) => {
  const { 
    bImages=[],
    starImages=[],
    className,
    setCurrentImgId
   } = params;
   const [currentSlide, setCurrentSlide] = useState(0);
   const images = starImages.concat(
    bImages.filter(bImage => !starImages.some(starImage => starImage.id === bImage.id))
  );
  useEffect(() => {
    if(images.length == 0) return
    const currentId = images[currentSlide].id;
    setCurrentImgId && setCurrentImgId(currentId)
  }, [currentSlide || images.length]); // we should reset id if images.length changes to account for deletions.

  const slides = images.map((img, index) => <SmartImage img={img} index={index} key={index} />);
  


  return (
    <Swiper
        onSlideChange={(swiper) => {
          const slideIndex = swiper.activeIndex
          setCurrentSlide(slideIndex)
          
        }}
        className={css.root}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className={classNames(css.slide, className)}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
  );
};

export default ImageGallery;
