
import { useBackend, useCache, useNavigation } from '../../context';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { Favorite as FavoriteIcon } from '@mui/icons-material';

const Likebutton = () => {
    function addById(sharables = [], id, newData) {
      // Check if the id exists in the sharables
      const idExists = sharables.some(item => item.id === id);
    
      if (idExists) {
        // If the id exists, update the existing item's data by adding newData
        return sharables.map(item => {
          if (item.id === id) {
            // Merge old data with newData, ensuring uniqueness
            const mergedData = [...new Set([...item.data, newData])];
            return { ...item, data: mergedData };
          }
          return item;
        });
      } else {
        // If the id doesn't exist, add a new item with newData
        return [...sharables, { id, data: [newData] }];
      }
    }
    
    function removeById(sharables = [], id, removeData) {
      // Check if the id exists in the sharables
      const idExists = sharables.some(item => item.id === id);
    
      if (idExists) {
        // If the id exists, update the data by removing removeData
        return sharables.map(item => {
          if (item.id === id) {
            // Filter out the removeData from the data array
            const updatedData = item.data.filter(data => data !== removeData);
            return { ...item, data: updatedData };
          }
          return item;
        });
      }
    
      // If the id doesn't exist, return the sharables unchanged
      return sharables;
    }
    
    function doesDataExist(sharables, searchData) {
      // Iterate through the sharables and check if any data array contains the searchData
      return sharables.some(item => item.data.includes(searchData));
    }
    
    const { user } = useCache();
    const flists = user?.favorites || [{ data: [] }];
    const { subpage: bid } = useNavigation();
    const { onUpdateUser } = useBackend();
    const active = doesDataExist(flists, bid);
  
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
  
    return (
      <IconButton
        aria-label="like"
        sx={{ color: active ? primaryColor : 'gray' }}
        onClick={() => {
          if (!user?.id) return;
  
          // Use the correct logic: if active, remove, otherwise add
          const newflists = active
            ? removeById(flists, "id-1", bid)
            : addById(flists, "id-1", bid);
  
          onUpdateUser({ favorites: newflists }, true);
        }}
      >
        <FavoriteIcon />
      </IconButton>
    );
  };
  
  export default Likebutton