// src/components/ItemSelector/ItemSelector.js
import React, { useState } from 'react';
import DraggableChip from '../CoreDraggable/DraggableChip';
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Stack,
  Tabs, Tab, 
} from '@mui/material';
import { Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { categories } from '../../../util/categories';

const CategorySelector = ({ activeCategory, setActiveCategory }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCategorySelect = (category) => {
    setActiveCategory(category);
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <Box>
      {/* Header with Menu Icon and Current Category */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={() => toggleDrawer(true)} aria-label="open drawer">
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'medium',
            color: '#333',
            ml: 1,
          }}
        >
          {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}
        </Typography>
      </Box>

      {/* Drawer for Category Selection */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <Typography variant="h6" sx={{ m: 2 }}>
            Select Category
          </Typography>
          <List>
            {Object.entries(categories).map(([category, { icon }]) => (
              <ListItem key={category} disablePadding>
                <ListItemButton onClick={() => handleCategorySelect(category)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    primary={category.charAt(0).toUpperCase() + category.slice(1)}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

const CategoryItems = ({ activeCategory }) => {
  /**
 * a11yProps function for accessibility
 */
function a11yProps(index) {
  return {
    id: `subcategory-tab-${index}`,
    'aria-controls': `subcategory-tabpanel-${index}`,
  };
}

/**
 * TabPanel Component
 *
 * Renders the content of each tab using MUI's Box component.
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="section"
      role="tabpanel"
      hidden={value !== index}
      id={`subcategory-tabpanel-${index}`}
      aria-labelledby={`subcategory-tab-${index}`}
      {...other}
      sx={{ width: '100%' }} // Ensures the Box takes full width
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </Box>
  );
};

/**
 * CategoryItems Component
 *
 * Displays items under the active category using centered Tabs for subcategories.
 */
  const categoryData = categories[activeCategory];
  const subcategories = categoryData.subcategories
    ? Object.keys(categoryData.subcategories)
    : [];

  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      {categoryData.subcategories ? (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable" 
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {subcategories.map((subcategory, index) => (
                <Tab
                  key={subcategory}
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {subcategory
                        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
                        .replace(/^./, (str) => str.toUpperCase())}
                    </Typography>
                  }
                  {...a11yProps(index)}
                  sx={{
                    textTransform: 'none',
                    fontWeight: currentTab === index ? 'bold' : 'regular',
                    color: currentTab === index ? 'primary.main' : 'text.secondary',
                  }}
                />
              ))}
            </Tabs>
          </Box>
          {subcategories.map((subcategory, index) => (
            <TabPanel key={subcategory} value={currentTab} index={index}>
              <Stack
                direction="row"
                spacing={1.5}
                flexWrap="wrap"
                justifyContent="center"
                
              >
                {categoryData.subcategories[subcategories[index]].map((item) => (
                  <DraggableChip
                    key={item.id}
                    id={item.id}
                    data={{
                      id: item.id,
                      time: 0,
                      count: 1,
                      label: item.label,
                      type: 'todo',
                      category: activeCategory,
                    }}
                    backgroundColor="primary"
                  />
                ))}
              </Stack>
            </TabPanel>
          ))}
        </>
      ) : (
        <Stack
          direction="row"
          spacing={1.5}
          flexWrap="wrap"
          justifyContent="center"
          sx={{ pt: 2 }}
        >
          {categoryData.items.map(({ id, label }) => (
            <DraggableChip
              key={id}
              id={id}
              data={{
                id: id,
                label: label,
                type: 'todo',
                category: activeCategory,
              }}
              backgroundColor="primary"
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const ItemSelector = ({ activeCategory, setActiveCategory }) => {
  return (
    <Box>
      <CategorySelector
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <CategoryItems activeCategory={activeCategory} />
    </Box>
  );
};

export default ItemSelector;
