import React from 'react';
import { useView } from '../../context';
import TopbarDesktop from './Desktop/TopbarDesktop';
import TopbarMobile from './Mobile/TopbarMobile'

const Topbar = () => {
  const {isMobileLayout} = useView();
  return isMobileLayout? <TopbarMobile/> : <TopbarDesktop/>;

};

export default Topbar;
