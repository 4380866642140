import React, { useEffect, useState } from 'react';

import PayForm from './PayForm'
import Drawer from '../Drawer'
const PayDrawer = ({isOpen, onClose, tx}) => {
  

  return <Drawer isOpen={isOpen} onClose={onClose}>
    <PayForm tx={tx}/>
  </Drawer>
};


export default PayDrawer;
