import React from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import BuildIcon from '@mui/icons-material/Build';
import HandymanIcon from '@mui/icons-material/Handyman';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // Truck icon for Moving
import MultipleActivitiesIcon from '@mui/icons-material/Home'; // Using Home icon for multiple activities

const activityIcons = {
  Handyman: <HomeRepairServiceIcon />,
  Assembly: <HandymanIcon />,
  Cleaning: <CleaningServicesIcon />,
  Plumbing: <PlumbingIcon />,
  Carpentry: <CarpenterIcon />,
  Electrical: <ElectricalServicesIcon />,
  Moving: <LocalShippingIcon />, // New icon for Moving
  Home: <MultipleActivitiesIcon />, // Single icon for multiple activities
};

const ActivityAvatar = ({ imageSrc, iconType, size = 40 }) => {
  const IconComponent = activityIcons[iconType] || activityIcons.Home; // Default to Home icon for multiple activities

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <IconButton size="small" style={{ backgroundColor: 'white', borderRadius: '50%' }}>
          {IconComponent}
        </IconButton>
      }
    >
      <Avatar src={imageSrc} alt="ownBusiness Avatar" sx={{ width: size, height: size }} />
    </Badge>
  );
};

export default ActivityAvatar;
