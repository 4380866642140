import React, { createContext, useContext, useState, useEffect } from 'react';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { connect } from 'react-redux';
import { useView } from './ViewProvider';
import { useSendbirdStateContext } from '@sendbird/uikit-react';
import { useAuth0 } from '@auth0/auth0-react';
import { GroupChannelListProvider } from '@sendbird/uikit-react/GroupChannelList/context';
import {EDGE_TEMPLATES, OPERATION_UPDATE_TRANSACTION, NOTIFICATION_UPDATE_INVOICE,
   NOTIFICATION_UPDATE_SCHEDULE,
  
  } from '../util/constants'

import {standarize_tx_data, get_tx_message} from '../util/utils'
import {
  createChannel, createNotificationChannel, initNotificationChannel,
  initializeSDK, updateUserMetadata,
  addTxUpdateHandler, handleInquiry,
  updateUnreadCounts,
  SET_READ_COUNTS,
  sendMessage,
  getChannelByUrl
} from '../ducks/sendbird.duck';
import { useDispatch } from 'react-redux';
import { useNavigation } from './NavigationProvider';

const APP_ID = '9B7BB33B-AE03-4BA2-8785-C4C5EB8FD277';

const SendbirdContext = createContext();

const SendbirdCustomProvider = (props) => {
  const {
    children,
    user,
    fetchNotificationsinprogress,
    sdkLoaded,
    notificationChannel
  } = props;
  const dispatch = useDispatch();
  const { stores } = useSendbirdStateContext();
  const {setPanel: setNavigationPanel, PANELS} = useNavigation()
  const { sdk } = stores?.sdkStore;
  const { currentUser } = sdk;
  const notification_url = currentUser?.metaData?.notification_url;
  const [currentInboxPanel, setCurrentPanel] = useState(PANELS.CHANNEL_LIST);
  const [currentActivityPanel, setActivityPanel] = useState(PANELS.ACTIVITY_LIST);
  const [currentChannel, selectChannel] = useState();
  

  const handleSelectChannel = (channel) => {
    const panel = channel? PANELS.CHANNEL : PANELS.CHANNEL_LIST
    setCurrentPanel(panel)
    setNavigationPanel(panel)
    selectChannel(channel)
  }

  const onGetChannel = (props) => {      
    return dispatch(createChannel(props))
  }
  const onUpdateUserMetadata = (metadata) => dispatch(updateUserMetadata(metadata));
  const onHandleInquiry = (channel) => dispatch(handleInquiry(channel))
  const onGetChannelByUrl = (channel_url) => dispatch(getChannelByUrl(channel_url))
  const onSendMessage = (channel_url, message, type='', data={}) =>{
    dispatch(sendMessage(channel_url, message.replace("user", user.given_name), type, data))
  }

  const onSendTxUpdate = (channel_url, data) => {
    const notification = data['last_notification_type']
    const standard_data =  standarize_tx_data(data)
    const message = get_tx_message(notification, standard_data)     

    
    return onSendMessage(channel_url, message, OPERATION_UPDATE_TRANSACTION, standard_data)
  }
  const getUsers = (channel) => {
    const members = channel?.members || [];
    const currentUser = members.filter(member => member.userId === user?.sub)[0];
    let otherUser = members.filter(member => member.userId !== user?.sub)[0];
    if (!otherUser && members.length === 1)
      otherUser = currentUser;
    return { currentUser, otherUser };
  };
  useEffect(() => {
    if (!sdkLoaded) {
      dispatch(initializeSDK(sdk));
    }
  }, [sdk, sdkLoaded, dispatch]);

  useEffect(() => {
    if(!sdkLoaded) return
    
    dispatch(addTxUpdateHandler());
    dispatch(updateUnreadCounts())
  }, [sdkLoaded, notification_url]);


  useEffect(() => {
    if(!sdkLoaded || !currentUser?.userId) return
    if (!notification_url) {
      dispatch(createNotificationChannel(currentUser.userId));
    }

    else dispatch(initNotificationChannel(notification_url))
  }, [currentUser?.userId, notification_url, sdkLoaded]);

  const markNotificationsAsRead = () => {
    if(!notificationChannel) return
    dispatch({type: SET_READ_COUNTS, payload: {notification_count: 0}})
    notificationChannel.markAsRead()
  }

  const markMessagesAsRead = () => {
    dispatch({type: SET_READ_COUNTS, payload: {channel_count: 0}})
  }

  return (
    <SendbirdContext.Provider value={{
      markNotificationsAsRead, markMessagesAsRead,
      currentInboxPanel,
      currentActivityPanel, setActivityPanel,
      onGetChannel, onUpdateUserMetadata,
      current_member: currentUser,
      fetchNotificationsinprogress,
      getUsers,
      sdk,
      onHandleInquiry,
      currentChannel,
      handleSelectChannel,
      onSendMessage,
      onSendTxUpdate,
      onGetChannelByUrl,
      sdkLoaded,
      ...props
    }}>
      {children}
    </SendbirdContext.Provider>
  );
};

const SendbirdWithRedux = (props) => {
  const { user } = props;
  // Move hook calls to the top level
  const { isMobileLayout, isDesktopLayout } = useView();
  const { isAuthenticated,  isLoading} = useAuth0();
  


  const sendbirdUser = isAuthenticated? {
    "nickname": `${user?.given_name}`,
    "userId": user?.id,
    "profileUrl": user?.picture

  } : {
    "nickname": "guest",
    "userId": "",
    "profileUrl": ""
  }

  const myColorSet = {
    '--sendbird-light-primary-500': '#FF8C00',
    '--sendbird-light-primary-400': '#FF8C00',
    '--sendbird-light-primary-300': '#FF8C00', // This is the shade used by icons
    '--sendbird-light-primary-200': '#FF8C00',
    '--sendbird-light-primary-100': '#FF8C00',
  };

  return (
    <SendbirdProvider
      appId={APP_ID}
      {...sendbirdUser}
      breakpoint={!isDesktopLayout}
    >
      <SendbirdCustomProvider {...props} isMobileLayout={isMobileLayout} colorSet={myColorSet}/>
    </SendbirdProvider>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.user;
  const { fetchNotificationsinprogress, sdk, notificationChannel } = state.sendbird;
  const sdkLoaded = !!sdk?._iid;
  
  return {
    user,fetchNotificationsinprogress,
    sdkLoaded, notificationChannel
  };
};

const EnhancedSendbirdProvider = connect(mapStateToProps)(SendbirdWithRedux);

const useSendbird = () => {
  const context = useContext(SendbirdContext);
  if (!context) {
    throw new Error('useSendbird must be used within a SendbirdWithRedux');
  }
  return context;
};

export { EnhancedSendbirdProvider, useSendbird };
