import React, { useState, useEffect } from 'react';
import { Box, } from '@mui/material';
import {ManagerHeader, PrimaryButton, Drawer} from '../index'; 
import {useManager, useCache, useNavigation, useSendbird, useBackend} from '../../context'
import SelectedLocation from './SelectedLocation'; // Adjust the import path as necessary
import SelectedAvailability from './SelectedAvailability';
import SelectedTodos from './SelectedTodos';
import ShareIcon from '@mui/icons-material/Share';
import { useAuth0 } from '@auth0/auth0-react';
export const drawerConfigs = [
  {
    type: 'locations',
    component: <SelectedLocation />,
  },
  {
    type: 'availabilities',
    component: <SelectedAvailability />,
  },
  {
    type: 'buckets',
    component: <SelectedTodos />,
  },
];

const getComponentByType = (type) => {
  const config = drawerConfigs.find((drawer) => drawer.type === type);
  return config ? config.component : null;
};

const ManagerBottom = ({ selectedSharable, type, onClose}) => {
  const {page, PAGE_NAMES} = useNavigation()
  const data = selectedSharable?.data
  const SearchBottom = () =>{
    const {onUpdateSearchParams} = useCache()
    return <PrimaryButton  message="search"  onClick={()=>{
      onUpdateSearchParams(data, type)
      onClose()
    }}
    sx={{ width: '60%'}}
    
    />
  
  }
  const ShareBottom = () =>{
    const {user} = useCache()
    const {currentChannel, onSendMessage } = useSendbird();
    return <PrimaryButton
    message="share"
    startIcon={<ShareIcon />}
    onClick={() => {
      onSendMessage(currentChannel?._url, `${user.given_name} shared ${type}`, type, selectedSharable)
      onClose()
    }}
    sx={{ color: 'black', borderColor: 'black', width: '60%', mb: 1, mt:1 }}
  />

}

  return page == PAGE_NAMES.SearchPage? <SearchBottom/> : 
         page == PAGE_NAMES.InboxPage? <ShareBottom/> :
         null
}

// DrawerView component
const SharableDrawer = React.memo(({ isOpen, setIsOpen, type }) => {
    const component = getComponentByType(type)
  const drawerProps = useManager(type);
  const {isAuthenticated} = useAuth0()
  const {onUpdateUser} = useBackend()
  const onClose = () =>{
    if(isAuthenticated)
      onUpdateUser({[drawerProps.type]: drawerProps.sharables}, true)
    setIsOpen(false)
  }
  if(!drawerProps) return
  return (
    <Drawer isOpen={isOpen} onClose={onClose} 
    renderheader={()=><ManagerHeader {...drawerProps} onClose={()=>setIsOpen(false)}/>}
    renderfooter={()=><ManagerBottom {...drawerProps} onClose={onClose}/>}
    >
          {component}
          

      </Drawer>
  );
});

export default SharableDrawer