import React, { createContext, useContext, useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import {fetchBusiness} from '../pages/BusinessPage/BusinessPage.duck.js'
import { useNavigation } from './NavigationProvider.js';
import { updateToken, getBusiness } from '../util/api.js';
import {deleteServiceImage, uploadServiceImages,  updateOwnBusiness, fetchOwnBusiness, uploadAvatar as uploadBusinessAvatar} from '../ducks/business.duck.js';

import {updateUserSuccess, updateUser, fetchUser } from '../ducks/user.duck.js';
import {getTransactions, createTransaction, getChannelTransaction, updateTransaction, getClientSecret} from '../ducks/transaction.duck.js';

import { 
   OPERATION_CHECK_SLUG_AVAILABILITY,
   OPERATION_UPDATE_USER, OPERATION_UPDATE_BUSINESS, OPERATION_UPDATE_TRANSACTION, OPERATION_CREATE_TRANSACTION, OPERATION_GET_CHANNEL_TX,
   WORKER_ROLE
  } from '../util/constants.js';

import {useCache} from '.';
import {fetchSearch} from '../pages/SearchPage/SearchPage.duck.js'
const BackendContext = createContext();

const BackendProvider = ({children}) => {
  const {user, isCustomer, ownBusiness} = useCache()
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading: loadingAuth0, getAccessTokenSilently, user: auth0user, logout } = useAuth0();
    const [token, setToken] = useState(null);
    const { bid } = useNavigation();
    const onFetchBusiness = (bid) => dispatch(fetchBusiness(bid));
    const onUpdateUser = (data, optimistic=false) => {
      if(optimistic){
        dispatch(updateUserSuccess({user: {...data}}))
      }
      dispatch(updateUser({op: OPERATION_UPDATE_USER, ...data}));
    }
    const onSearch = (searchQuery, offset) => dispatch(fetchSearch(searchQuery, offset))
    const onGetPaymentSession = (params) => dispatch(getClientSecret(params))
    const onUpdateTransaction = (params) => dispatch(updateTransaction({op: OPERATION_UPDATE_TRANSACTION, ...params}))
    const onCreateTransaction = (params) => dispatch(createTransaction({op: OPERATION_CREATE_TRANSACTION, ...params}))
    const onGetChannelTransaction = (channel_url) => dispatch(getChannelTransaction(channel_url))
    const onUpdateOwnBusiness = (data) => dispatch(updateOwnBusiness({op: OPERATION_UPDATE_BUSINESS, ...data}));
    const onLoadPrevTransactions = () => dispatch(getTransactions());
    const onCheckSlugAvailability = (slug) => getBusiness({op: OPERATION_CHECK_SLUG_AVAILABILITY, slug})
    const onDeleteImage = (imgId) => dispatch(deleteServiceImage(imgId))

    const onUploadServiceImages = (files) => dispatch(uploadServiceImages(ownBusiness.id, files))
    const onUploadBusinessAvatar = (file) => dispatch(uploadBusinessAvatar(file))
    
    const onFetchOwnBusiness = () => dispatch(fetchOwnBusiness())
    const onUpdateProfile = (data) => {
      return isCustomer? onUpdateUser(data) : onUpdateOwnBusiness(data)
    }
    useEffect(() => {
      if(bid) onFetchBusiness(bid);
    }, [bid]);

    // load token
    useEffect(() => {
        if (!isAuthenticated) {
          updateToken(null);
          setToken(null)
        }
        else getAccessTokenSilently().then(accessToken => {
          updateToken(accessToken)
          setToken(accessToken)
        }).catch(error => {
            logout({ logoutParams: { returnTo: process.env.REACT_APP_MARKETPLACE_ROOT_URL } })
            console.error('Error getting access token silently:', error);
          });
      }, [isAuthenticated, getAccessTokenSilently]);
      


      // load data thar requires token
      useEffect(() => {
        if(!token) return

        if(auth0user && !user?.sub)
          {
            const {sub,  given_name, family_name} = auth0user;
            dispatch(updateUserSuccess({ user: {sub,  given_name, family_name,
            } }));
          }
      }, [token]);

      useEffect(() => {
        if(!token || !user?.sub || user?.id || !isAuthenticated) return
        dispatch(fetchUser())
        dispatch(getTransactions());
        dispatch(fetchOwnBusiness())
      }, [user?.sub, token, user?.id, isAuthenticated]);
  
      useEffect(() => {
        if(user?.role != WORKER_ROLE) return
        dispatch(fetchOwnBusiness())
      }, [user?.role]);
    
    if(isAuthenticated && !token) return
  return (
    <BackendContext.Provider value={{ 
        onFetchBusiness,
        onDeleteImage,
        onUploadServiceImages,
        onUpdateUser,
        onUpdateOwnBusiness,
        onLoadPrevTransactions,
        onCheckSlugAvailability,
        onUpdateTransaction,
        onCreateTransaction,
        onGetPaymentSession,
        onUpdateProfile,
        onSearch,
        onFetchOwnBusiness,
        onUploadBusinessAvatar,
        onGetChannelTransaction
     }}>
      {children}
    </BackendContext.Provider>
  );
};

const useBackend = () => {
  const context = useContext(BackendContext);
  if (!context) {
    throw new Error('useNavigation must be used within a BackendProviderComponent');
  }
  return context;
};
  
  export { BackendProvider , useBackend };

  