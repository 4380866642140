import { useReducer, useEffect } from 'react';
import useInitializeManager from './useInitializeManager';

// Action Types
const ACTIONS = {
  SET_INITIAL: 'set_initial',
  UPDATE_SHARABLE: 'update_sharable',
  ADD_SHARABLE: 'add_sharable',
  DELETE_SHARABLE: 'delete_sharable',
};

// Reducer Function
const managerReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INITIAL:
      return {
        ...state,
        sharables: action.payload.initialList,
        selectedSharable: action.payload.initialSelectedItem,
      };
    case ACTIONS.UPDATE_SHARABLE:
      const updatedSharable = {
        ...state.selectedSharable,
        ...action.payload.update,
      };
      const updatedSharables = state.sharables.map((item) =>
        item.id === updatedSharable.id
          ? updatedSharable
          : { ...item, isDefault: action.payload.update.isDefault ? false : item.isDefault }
      );
      return {
        ...state,
        sharables: updatedSharables,
        selectedSharable: updatedSharable,
      };
    case ACTIONS.ADD_SHARABLE:
      const newSharable = {
        id: `id-${Date.now()}`,
        uid: `uid-${Date.now()}`,
        type: action.payload.type,
        data: [],
        title: action.payload.title,
      };
      return {
        ...state,
        sharables: [...state.sharables, newSharable],
        selectedSharable: newSharable,
      };
    case ACTIONS.DELETE_SHARABLE:
      const filteredSharables = state.sharables.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        sharables: filteredSharables,
        selectedSharable: filteredSharables[0] || null,
      };
    default:
      return state;
  }
};

// Factory function to create a Manager Hook
const createManager = (type) => {
  const useManager = () => {
    const { initialList, initialSelectedItem, isLoading } = useInitializeManager(type);

    const [state, dispatch] = useReducer(managerReducer, {
      sharables: initialList,
      selectedSharable: initialSelectedItem,
    });

    useEffect(() => {
      if (!isLoading) {
        dispatch({
          type: ACTIONS.SET_INITIAL,
          payload: { initialList, initialSelectedItem },
        });
      }
    }, [isLoading, initialList, initialSelectedItem]);

    const updateSharable = (update) => {
      dispatch({ type: ACTIONS.UPDATE_SHARABLE, payload: { update } });
    };

    const addSharable = (title, type) => {
      dispatch({ type: ACTIONS.ADD_SHARABLE, payload: { title, type } });
    };

    const deleteSharable = (id) => {
      dispatch({ type: ACTIONS.DELETE_SHARABLE, payload: { id } });
    };

    if (isLoading || !state.selectedSharable) return null;

    // Manager Base Object
    const manager = {
      sharables: state.sharables,
      selectedSharable: state.selectedSharable,
      setSelectedSharable: updateSharable,
      addSharable,
      deleteSharable,
      updateSharable,
      type,
    };

    // Customize manager based on type
    const typeSpecificManagers = {
      locations: {
        ...manager,
        updateSharableData: (callback) => {
          if (typeof callback !== 'function') return;
          const newData = callback();
          const oldData = manager.selectedSharable.data;
          if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
            updateSharable({ data: newData });
          }
        },
      },
      buckets: {
        ...manager,
        updateSharableData: (newData) => {
          updateSharable({ data: newData });
        },
      },
      availabilities: {
        ...manager,
        updateSharableData: (data) => {
          updateSharable({ data });
        },
      },
      favorites: manager,
    };

    return typeSpecificManagers[type] || manager;
  };

  return useManager;
};

export default createManager;
