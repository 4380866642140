// src/components/Availability/DaySelector.js
import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { DAYS_OF_WEEK } from '../../../util/constants';
import DraggableChip from '../CoreDraggable/DraggableChip'
/**
 * DaySelector Component
 *
 * Renders a list of draggable day chips.
 */
const DaySelector = () => {
  return (
    <Box sx={{width:" 100%"}}>
      <Typography variant="h6">Days of the Week</Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {DAYS_OF_WEEK.map((day) => (
          <DraggableChip
            key={day}
            id={day}
            label={day.charAt(0).toUpperCase() + day.slice(1)}
            data={{
              label: day.charAt(0).toUpperCase() + day.slice(1),
              type: "day"
            }}
            color="secondary"
            backgroundColor="lightcoral"
          />
        ))}
      </Stack>
    </Box>
  );
};

export default DaySelector;
