// BucketDrawer.js
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {SharableDrawer } from '../../../components';
import DrawerIcon from './DrawerIcon'; // Adjust the import path as necessary

const BucketDrawer = React.memo(({ isInitialized }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetIsOpen = useCallback((open) => {
    setIsOpen(open);
  }, []);

  return (
    <Box>
      <DrawerIcon component={<ListAltIcon />} setIsOpen={handleSetIsOpen} isInitialized={isInitialized} />
      <SharableDrawer isOpen={isOpen} setIsOpen={setIsOpen} type="buckets" />
    </Box>
  );
});

export default BucketDrawer;
