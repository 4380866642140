import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { NavigationProvider, useNavigation } from './NavigationProvider';
import { CacheProvider, useCache } from './CacheProvider';
import {BackendProvider, useBackend} from './BackendProvider';
import {EnhancedSendbirdProvider, useSendbird} from './EnhancedSendbirdProvider';
import { ViewProvider, useView } from './ViewProvider';
import {StripeConnectProvider, useStripeConnect} from './StripeConnectProvider'
import { Auth0Provider } from '@auth0/auth0-react';

import {useManager, useManagersStatus,
  SharableManagerProvider
} from './ManagerProvider/SharableManagerProvider'
import defaultMessages from '../translations/defaultMicrocopy.json';
import { IntlProvider } from '../util/reactIntl';
import configureStore from '../store';

const LocalDataProvider = ({ children }) => {
  const initialState = {}; // or fetch from localStorage / API
  const store = configureStore(initialState);
  return (
    <IntlProvider messages={{ ...defaultMessages }} textComponent="span" locale={'en'}>
    <ReduxProvider store={store}>
      <CacheProvider>
        {children}
      </CacheProvider>
    </ReduxProvider>
    </IntlProvider>

  );
};

const ApiProvider = ({ children }) => {
  return (
      <BackendProvider>
      <EnhancedSendbirdProvider>
      <StripeConnectProvider>
      {children}

      </StripeConnectProvider>
      </EnhancedSendbirdProvider>
        
      </BackendProvider>
)}

const RoutesProvider = ({ children }) =>{ 
  return (
  <HelmetProvider>
    <BrowserRouter>
      <NavigationProvider>
      <ViewProvider>
        {children}
        </ViewProvider>
      </NavigationProvider>
    </BrowserRouter>
  </HelmetProvider>
)};

const CustomProviders = ({children }) => {
  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN_TENANT}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: `${process.env.REACT_APP_AUTH0_DOMAIN_TENANT}/api/v2/`,
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <RoutesProvider>
        <LocalDataProvider>
          <ApiProvider>
            <SharableManagerProvider>
               {children}
            </SharableManagerProvider>
            
          </ApiProvider>

        </LocalDataProvider>
     
    </RoutesProvider>
    </Auth0Provider>
);
}

export {CustomProviders as default, useNavigation, useCache, useSendbird, useView, useBackend,useStripeConnect,
  useManager, useManagersStatus

};
