import React from 'react';
import { Box, Divider } from '@mui/material';
import InvoiceDisplay from './InvoiceDisplay';
import Progressbar from './Progressbar';
import Taskbar from './Taskbar';
import CancelButton from './CancelButton';
const ActivityDisplay = ({ tx }) => {
  if (!tx) return null;
  const progress = { inquiry_time: tx.createdat, scheduled_for: tx?.schedule?.startTime, completed_at: tx.completedat };
  return (
    <Box sx={{height: "100%", width:"100%"}}>
      <Box sx={{ padding: 2 }}>
        <Progressbar progress={progress} />
        <Divider sx={{ my: 2 }} />

        <Taskbar tx={tx} />
        <Divider sx={{ my: 2 }} />

        <Divider sx={{ my: 2 }} />
        
        <InvoiceDisplay invoice={tx?.invoice} />
        <CancelButton state={tx?.state}/>
        
      </Box>
    </Box>
  );
};

export default ActivityDisplay;
