import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, CircularProgress } from '@mui/material';
import {FieldTextInput} from '../../../../components';
import { useBackend, useCache } from '../../../../context';
import debounce from 'lodash/debounce';


const SlugInput = ({ control }) => {
  const {onCheckSlugAvailability} = useBackend()
  const {ownBusiness} = useCache()
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);

  const debouncedCheck =  debounce((slug) => {
    setIsChecking(true)
    if(slug === ownBusiness.slug){
      setIsChecking(false)
      setIsAvailable(true)
      return
    }
    const promise = onCheckSlugAvailability(slug)
    promise.then(res=>{
      const isAvailable = res.data
      setIsAvailable(isAvailable)
      setIsChecking(false)
    })
  }, 300);

  const onSlugChange = (event) => {
    const slug = event.target.value;
    debouncedCheck(slug);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#f9f9f9',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '5px 10px',
          width: '100%',
          position: 'relative',
        }}
      >
        <Typography sx={{ fontWeight: 'bold', color: '#555' }}>
          www.beavr.pro/
        </Typography>
        <FieldTextInput
          name="slug"
          control={control}
          inputProps={{ style: { paddingLeft: '3px', width: '100%' } }}
          sx={{
            flexGrow: 1,
            minWidth: '150px',
            border: 'none',
            outline: 'none',
            padding: '0',
            fontSize: '1rem',
            '& input': {
              padding: '0',
              fontWeight: 'bold',
            },
          }}
          placeholder="your-business-id"
          onChange={(event) => {
            onSlugChange(event);
            
          }}
        />
      </Box>
      {isChecking && (
        <Box sx={{display: 'flex', alignItems: "center"}}>
          <CircularProgress size={20}/>
          <Typography sx={{paddingLeft: 1 ,fontSize: '0.8rem' }}> checking.. </Typography>
        </Box>
      )}
      {!isChecking && isAvailable !== null && (
        <Typography  sx={{ color: isAvailable ? 'green' : 'red', fontSize: '0.8rem' }}>
          username is {isAvailable ? 'Available' : 'Taken'}
        </Typography>
      )}
    </Box>
  );
};

export default SlugInput;
