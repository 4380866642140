import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { OPERATION_UPDATE_LISTING, ALL_POSSIBLE_SERVICES } from '../../../../util/constants';
import { FieldCheckboxList } from '../../../../components';
import {default as ScheduledAreasForm, validate_all_work_schedules} from './ScheduledAreasForm'
import { useForm, useWatch } from 'react-hook-form';
import { useCache, useBackend } from '../../../../context';

const ServicesOfferedSection = ({ control }) => (
  <Box sx={{ mb: 2.5 }}>
    <Typography variant="h5" sx={{ mb: 0.5 }}>Services Offered</Typography>
    <FieldCheckboxList control={control} name={"categories"} options={ALL_POSSIBLE_SERVICES} />
  </Box>
);

const filter_categories = (categories=[])=>{
  const filtered = []
  for (const s of categories)
  {
    if(ALL_POSSIBLE_SERVICES.includes(s))
      filtered.push(s)
  }

  return filtered
}
const BackBusinessCardTab = (props) => {
  const {
   setTabButton,
  } = props;
  const {ownBusiness, updateBusinessInProgress} = useCache()
  const {onUpdateOwnBusiness} = useBackend()
  const { control, setValue, watch } = useForm({
    defaultValues: {
      categories: filter_categories(ownBusiness?.categories),
      scheduledAreas: ownBusiness?.scheduledAreas || []
    },
  });
  const values = useWatch({ control });

  useEffect(() => {
    setTabButton({
      onSubmit: () => {
        console.log(values)

        onUpdateOwnBusiness({op: OPERATION_UPDATE_LISTING, ...values})
      },
      
      disabled: values.categories.length == 0 || !validate_all_work_schedules(values.scheduledAreas),
      inprogress: updateBusinessInProgress
    });
  }, [JSON.stringify(values), updateBusinessInProgress]);

 return (
  <Box sx={{ padding: 2.5, width: '100%' }}> {/* 20px padding */}
    <form
      onSubmit={() => {}}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <ServicesOfferedSection control={control} />
      <ScheduledAreasForm scheduledAreas={watch('scheduledAreas')} setScheduledArea={(val)=>{
        setValue('scheduledAreas', val)
      }}/>
    </form>
  </Box>
 )
};



export default BackBusinessCardTab;
