import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider, useMediaQuery, CssBaseline, GlobalStyles } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import 'swiper/css'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'black',
          '&.Mui-selected': {
            color: 'black',
          }
        }
      }
    }
  },
  customVariables: {
    mobileMinWidth: '320px',
    topMinBarHeight: '60px',
    barBackgroundColor: '#ffffff',
    barShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
    barBorder: '1px solid #e0e0e0',
    barPadding: '10px',
    backgroundColor: '#f8f9fa',
    darkOrange: '#FFA500',
    lightOrange: '#FFD580',
    goldenYellow: '##FFD700',
    lightGray: '#f5f5f5',
    darkGray: '#2F2F2F'
  },
  palette: {
    primary: {
      main: '#FFA500',
    },
    secondary: {
      main: '#FFEB3B',
    },
    error: {
      main: '#FF0000',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#212529',
      secondary: '#6c757d',
    },
    grey: {
      500: '#9e9e9e',
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: Array(25).fill('none').map((_, index) =>
    index === 1 ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
  ),
  spacing: 16,
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    body1: {
      color: '#212529',
    },
    body2: {
      color: '#6c757d',
    },
  },
});

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      'html, body': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        overflowX: 'hidden', // Prevent horizontal overflow
      },
      body: {
        backgroundColor: theme.customVariables.backgroundColor,
        color: theme.palette.text.primary,
        fontFamily: 'Arial, sans-serif',
      },
      '.sendbird-message-content__middle__sender-name': {
        visibility: 'hidden',
      },
      '.sendbird-message-content__middle__sender-name::before': {
        content: '""',
      },

      // bubble
      '.sendbird-theme--light .sendbird-text-message-item-body.outgoing': {
        backgroundColor: theme.customVariables.lightOrange + ' !important',
        color: '#000000 !important', 
      },

      '.sendbird-theme--light .sendbird-text-message-item-body.incoming': {
        backgroundColor: theme.customVariables.lightGray + ' !important',
        color: '#000000 !important', 
      },

      '.sendbird-theme--light .sendbird-message-content-reactions.primary': {
        backgroundColor: theme.customVariables.lightOrange + ' !important',
      },
      
      '.sendbird-theme--light .sendbird-message-content-reactions.incoming': {
        backgroundColor: '#FFFFFF !important',
      },
      // counter position is incorrect and we have to set it manually in mobile, not sure why
      '.sendbird--mobile-mode .sendbird-channel-preview__content__lower__unread-message-count':{
        position: 'inherit'
      },
      //////// ICONS ////////
      // icon emoji background
      '.sendbird-theme--light .sendbird-reaction-badge--selected': {
        backgroundColor: theme.customVariables.darkOrange + ' !important',
        border: '1px solid gray'
      },

      // icon borders when hovered
      '.sendbird-theme--light .sendbird-reaction-badge--selected:hover': {
        border: '1px solid black'
      },
      '.sendbird-theme--light .sendbird-reaction-badge--is-add:hover': {
        border: '1px solid black'
      },

      // all icons
      '.sendbird-theme--light .sendbird-icon-color--primary [class*="fill"]': {
        fill: theme.customVariables.darkOrange + ' !important', // Use darkOrange from theme
      },
      // read bubble
      '.sendbird-theme--light .sendbird-badge': {
        backgroundColor: theme.customVariables.darkOrange + ' !important', // Use darkOrange from theme
        color: 'black !important',
        // Ensure children inherit this color
        '& *': {
          color: 'inherit !important',
        }
      },
      '.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus': {
        border: 'solid 1px black' 
      },
      '.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus': {
        border: 'solid 1px gray' 
      },
      '.sendbird-message-input .sendbird-message-input--send': {
        fill: 'blue'
      },
      '.sendbird-theme--light .sendbird-label--color-primary': {
              color: '#333333  '
            },

      '.sendbird-theme--light .sendbird-notification':{
        backgroundColor: theme.customVariables.goldenYellow
      },
      '.sendbird-notification sendbird-conversation__messages__notification':{
        backgroundColor: theme.customVariables.goldenYellow
      },

      // notification banner text color
      '.sendbird-theme--light .sendbird-label--color-oncontent-1':{
        color: 'black'
      },
      '.sendbird-conversation__footer':{
        backgroundColor: 'var(--backgroundColor)'
      },
      ':root': {
        '--topMinBarHeight': theme.customVariables.topMinBarHeight,
        '--barBackgroundColor': theme.customVariables.barBackgroundColor,
        '--barShadow': theme.customVariables.barShadow,
        '--barBorder': theme.customVariables.barBorder,
        '--barPadding': theme.customVariables.barPadding,
        '--backgroundColor': theme.customVariables.backgroundColor,
      },
    })}
  />
);

const mobileWidth = 768;
const tabletWidth = 1024;

const ViewContext = createContext();

const ViewProvider = ({ children }) => {
  const isMobileLayout = useMediaQuery(`(max-width:${mobileWidth}px)`);
  const isTabletLayout = useMediaQuery(`(min-width:${mobileWidth + 1}px) and (max-width:${tabletWidth}px)`);
  const isDesktopLayout = useMediaQuery(`(min-width:${tabletWidth + 1}px)`);

  const [BottombarHeight, setBottombarHeight] = useState(0);
  const [TopbarHeight, setTopbarHeight] = useState(0);
  const context = {
    isMobileLayout,
    isTabletLayout,
    isDesktopLayout,
    BottombarHeight,
    setBottombarHeight,
    TopbarHeight,
    setTopbarHeight,
    theme,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
      <ViewContext.Provider value={context}>
        {children}
      </ViewContext.Provider>
    </ThemeProvider>
  );
};

const useView = () => {
  const context = useContext(ViewContext);
  if (context === undefined) {
    throw new Error('useView must be used within a ViewProvider');
  }
  return context;
};

export { ViewProvider, useView };
