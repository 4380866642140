import React, {useEffect, useState} from 'react';
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Chip
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import css from './SectionBusiness.module.css';
import { useView, useCache, useSendbird, useNavigation } from '../../../context';
import { PrimaryButton } from '../../../components';
import {Likebutton, Sharebutton} from '../../../components';
import { decodeMessage } from '../../../util/utils';
import BusinessQuoter from '../BusinessQuoter'
const BusinessInfo = ({ business }) => {
  const { isDesktopLayout } = useView();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Avatar
        alt="Author Picture"
        src={business.picture}
        className={css.avatar}
        sx={{ width: 150, height: 150 }}
      />
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: 1 }}>{business?.name}</Typography> 
      {isDesktopLayout && <Box sx={{display:"flex", flexDirection:"row", gap: 1}}>
        <Sharebutton />
        <Likebutton />
      </Box>}
    </Box>
  );
}

const StatItem = ({ value, label }) => (
  <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
    <Typography variant="body2" color="textSecondary">{label}</Typography>
  </Box>
);

const Stats = ({ business }) => {
  function formatTime(unixTime) {
    const now = Date.now() / 1000; // Current time in seconds
    const timeDiff = now - unixTime;
    const oneYear = 31536000; // seconds in a year
    const oneMonth = 2592000; // seconds in a month (approx.)
    if (timeDiff >= oneYear) {
        let years = timeDiff / oneYear;
        let wholeYears = Math.floor(years);
        let remainingTime = timeDiff - (wholeYears * oneYear);
        let quarters = Math.round(remainingTime / (oneYear / 4)) / 4;
        return {number: wholeYears + quarters, unit: "Years"};
    } else if (timeDiff >= 3 * oneMonth) {
        let months = Math.floor(timeDiff / oneMonth);
        return {number: months, unit: "Months"};
    } else {
        return {number: "Less than 3 months", unit: ""};
    }
}
  const {stats} = business
  const avgRating = (stats?.star_count / stats?.reviews_count).toFixed(2);;
  const exp = formatTime(stats.experience_from)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%'
      }}
    >
      <StatItem value={`${stats.invoice_count} Project`} label="Completed" />
      <Divider orientation="vertical" flexItem />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginRight: 0.25 }}>{avgRating}</Typography>
          <StarIcon sx={{color: 'black' }} />
        </Box>
        <Typography variant="body2" color="textSecondary">Rating</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <StatItem value={`${exp.number} ${exp.unit} `} label={`Verified experience`} />
    </Box>
  );
}

const DetailSection = ({ title, items }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, flexWrap: 'wrap', gap: 1 }}>
    <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: 1, color: 'text.primary' }}>{title}:</Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {items.map((item, index) => (
        <Chip key={index} label={item} size="small" sx={{ marginBottom: 0.5 }} />
      ))}
    </Box>
  </Box>
);
const SectionBusiness = ({ business }) => {
  const { isDesktopLayout } = useView();
  const [channel, setChannel] = useState()
  const {onGetChannel, sdkLoaded: sbLoaded} = useSendbird()
  const {navigateByName} = useNavigation()
  useEffect(() => {
    if(!business?.id || !sbLoaded) return
    onGetChannel({invited: business.workerids}).then(channel =>setChannel(channel))
  }, [business?.id, sbLoaded]);

  const services = business?.slist
  return (
    <Box
      sx={{
        padding: 2,
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        border: '1px solid #ddd',
        borderRadius: '16px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: 2,
          gap: 2,
        }}
      >
        <BusinessInfo business={business} />
        <Stats business={business} />
      </Box>
      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 2 }}>
        {services && <DetailSection title="Services" items={services} />}
        {business?.tools && <DetailSection title="Tools" items={business.tools} />}
        {business?.vehicles && <DetailSection title="Vehicles" items={business.vehicles} />}
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 1 }}>
          {decodeMessage(business.bio)}
        </Typography>
      </Box>
      {isDesktopLayout && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center horizontally
            flexDirection: 'column',
            width: '100%', // Ensure the container takes full width
            gap: 2,
            marginTop: 2,
          }}
        >
        
        <Box sx={{border: '1px solid gray ',  width: '100%', borderRadius: '8px'}}> <BusinessQuoter /> </Box>
          
        {business?.workerids &&  <PrimaryButton message="Message" sx={{ width: '100%' }} onClick={()=> navigateByName("InboxPage", {state: channel})} disabled={!channel}/>}
        
        </Box>
      )}
    </Box>
  );
};

export default SectionBusiness;
