import React from 'react';
import { useBackend } from '../../context';
import { PrimaryButton } from '../Button/Button';
import { 
  Typography, 
  Button, 
  FormControl, 
  Box, 
  Drawer, 
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

const SelectRole = ({ isOpen, setOpen }) => {
  const {onUpdateUser} = useBackend()
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      role: 'customer' // Set 'customer' as the default role
    }
  });

  const onSubmit = (data) => {
    onUpdateUser(data, true)
  };

  return (
    <Drawer anchor="bottom" open={isOpen}>
      <Box 
        sx={{ 
          width: '100%', 
          padding: 4,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: '#fff',
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center'
        }}
        role="presentation"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Select Your Role
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="role-label">I am a:</InputLabel>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                labelId="role-label"
                {...field}
                label="I am a"
              >
                <MenuItem value="customer">Customer</MenuItem>
                <MenuItem value="provider">Service Provider</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <PrimaryButton 
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit(onSubmit)}
          message={"Submit"}
        />
      </Box>
    </Drawer>
  );
};

export default SelectRole;
