import React, { useEffect, useState, useRef } from 'react';
import { Button, CircularProgress, Box, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const darkenColor = (color, amount) => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);

  let r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let g = ((num >> 8) & 0x00FF) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let b = (num & 0x0000FF) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));
};

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'varianttype' && prop !== 'inprogress' && prop !== 'ready' && prop !== 'keepcolorwhendisabled',
})(({ theme, varianttype, inprogress, ready, keepcolorwhendisabled }) => {
  const backgroundColors = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    danger: theme.palette.error.main,
    neutralGreen: theme.palette.success.main, // Neutral Green color
    neutralBlue: theme.palette.info.main, // Neutral Blue color
    neutralPurple: theme.palette.secondary.main, // Neutral Purple color
    discouraged: theme.palette.grey[500], // Discouraged color: Darker grey
    simple: 'transparent',
  };

  const hoverBackgroundColors = {
    primary: darkenColor(theme.palette.primary.main, -20),
    secondary: darkenColor('#FFEB3B', -20), // Slightly darker yellow
    danger: darkenColor(theme.palette.error.main, -20),
    neutralGreen: darkenColor(theme.palette.success.main, -20), // Darker green
    neutralBlue: darkenColor(theme.palette.info.main, -20), // Darker blue
    neutralPurple: darkenColor(theme.palette.secondary.main, -20), // Darker purple
    discouraged: darkenColor(theme.palette.grey[500], -20), // Darker discouraged color
    simple: 'transparent',
  };

  const textColors = {
    primary: '#000000',
    secondary: '#000000',
    danger: '#ffffff', // White text for danger variant
    neutralGreen: '#ffffff', // White text for neutral green variant
    neutralBlue: '#ffffff', // White text for neutral blue variant
    neutralPurple: '#ffffff', // White text for neutral purple variant
    discouraged: '#000000', // Black text for discouraged variant
    simple: '#000000',
  };

  const borderStyles = {
    simple: '1px solid #B0B0B0',
  };

  const hoverBorderColors = {
    simple: theme.palette.primary.main,
  };

  return {
    backgroundColor: backgroundColors[varianttype] || backgroundColors.primary,
    color: textColors[varianttype] || textColors.primary,
    border: borderStyles[varianttype] || 'none',
    '&:hover': {
      backgroundColor: hoverBackgroundColors[varianttype] || hoverBackgroundColors.primary,
      borderColor: hoverBorderColors[varianttype] || 'inherit',
    },
    '&.Mui-disabled': {
      backgroundColor: keepcolorwhendisabled === "true" || inprogress === "true" || ready === "true" ? backgroundColors[varianttype] || backgroundColors.primary : theme.palette.grey[500],
      color: keepcolorwhendisabled === "true" || inprogress === "true" || ready === "true" ? textColors[varianttype] : theme.palette.text.disabled,
      borderColor: keepcolorwhendisabled === "true" || inprogress === "true" || ready === "true" ? borderStyles[varianttype] : theme.palette.grey[400],
    },
  };
});

const CustomButtonComponent = ({ varianttype, message, inprogress = false, disabled, keepColorWhenDisabled = false, defaultsx={}, sx, ...props }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      if (!inprogress && !showSuccess) {
        setShowSuccess(true);
        const timer = setTimeout(() => setShowSuccess(false), 2000);
        return () => clearTimeout(timer);
      }
    } else {
      hasMounted.current = true;
    }
  }, [inprogress]);

  return (
    <CustomButton
      varianttype={varianttype}
      disabled={disabled || inprogress || showSuccess}
      inprogress={inprogress.toString()}
      ready={showSuccess.toString()}
      keepcolorwhendisabled={keepColorWhenDisabled.toString()}
      sx={{...defaultsx, ...sx }} // Ensure borderRadius can be overridden by sx
      {...props}
    >
      {showSuccess ? <CheckIcon sx={{ color: '#000000' }} /> : inprogress ? <CircularProgress size={24} sx={{ color: '#000000' }} /> : message}
    </CustomButton>
  );
};


export const ExpandableButton = ({ children, buttonText, sx, startIcon }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", position: "relative" }}>
      <Box sx={{ position: "sticky", top: 0, zIndex: 1, width: "100%", backgroundColor:"white" }}>
        <SimpleButton
          fullWidth
          variant="contained"
          onClick={() => setExpanded(!expanded)}
          startIcon={startIcon}
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          message={buttonText}
          sx={{ mb: 0.5, ...sx }}
        />
      </Box>
      
      <Collapse in={expanded} sx={{ width: "100%",  overflowY: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};



export const PrimaryButton = (props) => <CustomButtonComponent varianttype="primary" {...props} defaultsx={{border: '1px solid gray'}}/>;
export const SecondaryButton = (props) => <CustomButtonComponent varianttype="secondary" {...props} defaultsx={{border: '1px solid #C0C0C0'}}/>;
export const DangerButton = (props) => <CustomButtonComponent varianttype="danger" {...props} />;
export const NeutralGreenButton = (props) => <CustomButtonComponent varianttype="neutralGreen" {...props} />;
export const NeutralBlueButton = (props) => <CustomButtonComponent varianttype="neutralBlue" {...props} />;
export const NeutralPurpleButton = (props) => <CustomButtonComponent varianttype="neutralPurple" {...props} />;
export const DiscouragedButton = (props) => <CustomButtonComponent varianttype="discouraged" {...props} />;
export const SimpleButton = (props) => <CustomButtonComponent varianttype="simple" {...props} />;
