import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { ImageGallery } from '../../components';
import { NamedLink } from '../../components';

const BusinessCard = (props) => {
  const { business } = props;
  const availabilityColor = business.availability ? 'green' : 'gray';
  const {stats} = business
  const avgRating = (stats?.star_count / stats?.reviews_count).toFixed(2);
  const hourlyRate = parseInt(business.hourlyrate / 100);
  return (
    <NamedLink name={"BusinessPage"} params={{ bid: business.id }} >
      <Card sx={{ display: 'flex', flexDirection: 'column', margin: '10px', maxHeight:"450px", maxWidth:"450px"}} >
        <ImageGallery bImages={business.images} starImages={business.starimages} minDims={{ minHeight: "150px" }} />
        <CardContent sx={{ padding: '10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: availabilityColor, mr: 1 }} />
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                {business.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StarIcon sx={{ color: 'black', mr: 0.5 }} />
              <Typography variant="body2" component="span">
                {avgRating}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" sx={{ fontSize: '1em', margin: '5px 0' }}>
            ${hourlyRate} / hour
          </Typography>
        </CardContent>
      </Card>
    </NamedLink>
  );
};

export default BusinessCard;
