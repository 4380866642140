// BusinessPage.js

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigation, useCache, useBackend, useView } from '../../context';

import {
  Page,
} from '../../components';
import SectionReviews from './SectionReviews/SectionReviews';
import SectionBusiness from './SectionBusiness/SectionBusiness';
import SectionGallery from './SectionGallery/SectionGallery';
import BusinessPageSkeleton from './BusinessPageSkeleton';

const BusinessPage = ({}) => {
  const { subpage: bid } = useNavigation();
  const { onFetchBusiness,  } = useBackend();
  const {onGetCachedBusiness} = useCache();
  const [business, setBussiness] = useState()
  const { isDesktopLayout, isMobileLayout } = useView();
  const loaded = !!business?.id;
  const [businessLoaded, setBusinessLoaded] = useState(false)
  useEffect(() => {
    onFetchBusiness(bid).then(()=>setBusinessLoaded(true))
  }, []);

  useEffect(() => {
    const cached_business = onGetCachedBusiness(bid)
    setBussiness(cached_business)
  }, [businessLoaded, ]);


  if (!loaded) {
    return (
      <Page>
        <BusinessPageSkeleton isDesktopLayout={isDesktopLayout} isMobileLayout={isMobileLayout} />
      </Page>
    );
  }
  return (
    <Page>
      <Box
        display="flex"
        flexDirection={isDesktopLayout ? 'row' : 'column'}
        gap="25px"
        width="100%"
        maxWidth="1920px"
        padding="10px"
      >
        
        <Box flex={2} sx={{ width: '100%', maxWidth: '1200px', display: "flex", flexDirection: "column", gap: 2, alignItems: 'center' }}>
            <SectionGallery business={business} />
            <SectionReviews business={business} />
        </Box>
        <SectionBusiness business={business} />

      </Box>
    </Page>
  );
};


export default BusinessPage;
