// src/utils/categories.js

import React from 'react';
import {
  Build as BuildIcon,
  CleaningServices as CleaningServicesIcon,
  Handyman as HandymanIcon,
} from '@mui/icons-material';

/**
 * Categories Configuration
 * Defines the structure of categories, subcategories, and items with embedded labels.
 */
export const categories = {
  assembly: {
    subcategories: {
      beds: [
        { id: 'platform_bed', label: 'Platform Bed' },
        { id: 'bunk_bed', label: 'Bunk Bed' },
        { id: 'loft_bed', label: 'Loft Bed' },
        { id: 'murphy_bed', label: 'Murphy Bed' },
        { id: 'canopy_bed', label: 'Canopy Bed' },
        { id: 'adjustable_bed', label: 'Adjustable Bed' },
      ],
      tables: [
        { id: 'dining_table', label: 'Dining Table' },
        { id: 'coffee_table', label: 'Coffee Table' },
        { id: 'console_table', label: 'Console Table' },
        { id: 'nightstand', label: 'Nightstand' },
      ],
      chairsAndSeating: [
        { id: 'sofa', label: 'Sofa' },
        { id: 'desk', label: 'Desk' },
        { id: 'sectional', label: 'Sectional' },
        { id: 'recliner', label: 'Recliner' },
        { id: 'accent_chair', label: 'Accent Chair' },
        { id: 'office_chair', label: 'Office Chair' },
        { id: 'dining_chair', label: 'Dining Chair' },
        { id: 'bar_stool', label: 'Bar Stool' },
      ],
      storage: [
        { id: 'dresser', label: 'Dresser' },
        { id: 'chest', label: 'Chest' },
        { id: 'wardrobe', label: 'Wardrobe' },
        { id: 'armoire', label: 'Armoire' },
        { id: 'cabinet', label: 'Cabinet' },
        { id: 'pantry_unit', label: 'Pantry Unit' },
      ],
      other: [
        { id: 'tv_stand', label: 'TV Stand' },
        { id: 'fitness_equipment', label: 'Fitness Equipment' },
        { id: 'garden_shed', label: 'Garden Shed' },
        { id: 'hammock', label: 'Hammock' },
        { id: 'gazebo', label: 'Gazebo' },
        { id: 'something_else', label: 'Something Else' },
      ],
    },
    icon: <BuildIcon />,
  },
  cleaning: {
    items: [
      { id: 'standard_cleaning', label: 'Standard Cleaning' },
      { id: 'deep_cleaning', label: 'Deep Cleaning' },
      { id: 'move_in_move_out', label: 'Move-In/Move-Out Cleaning' },
      { id: 'post_event_cleaning', label: 'Post-Event Cleaning' },
    ],
    icon: <CleaningServicesIcon />,
  },
  handyman: {
    items: [
      { id: 'general_repairs', label: 'General Repairs' },
      { id: 'fixture_installation', label: 'Fixture Installation' },
      { id: 'mounting_hanging', label: 'Mounting & Hanging' },
      { id: 'basic_plumbing_tasks', label: 'Basic Plumbing Tasks' },
      { id: 'basic_electrical_tasks', label: 'Basic Electrical Tasks' },
      { id: 'painting', label: 'Painting' },
    ],
    icon: <HandymanIcon />,
  },
};

/**
 * Create a lookup map for item IDs to labels.
 * This map is created once when the module is loaded.
 */
const idToLabelMap = {};

// Populate the lookup map by traversing the categories
for (const category of Object.values(categories)) {
  // Check if the category has subcategories
  if (category.subcategories) {
    for (const subcategory of Object.values(category.subcategories)) {
      subcategory.forEach((item) => {
        idToLabelMap[item.id] = item.label;
      });
    }
  }

  // Check if the category has items without subcategories
  if (category.items) {
    category.items.forEach((item) => {
      idToLabelMap[item.id] = item.label;
    });
  }
}

/**
 * Optimized getItemLabel Function using Lookup Map
 * Retrieves the human-readable label for a given item ID.
 *
 * @param {string} id - The unique identifier of the item.
 * @returns {string} - The corresponding label if found; otherwise, returns the ID itself.
 */
export const getItemLabel = (id) => {
  return idToLabelMap[id] || id;
};
