import React, {useState, useEffect } from 'react';
import {MapForm} from '../index'; 
import {  useManager } from '../../context';


const SelectedLocation = () => {
  const {selectedSharable, updateSharableData} = useManager('locations')
  return selectedSharable ? (
      <MapForm
        allowEdit={true} 
        showLocateButton={true}
        fuzzy={false}
        center={selectedSharable.data}
        setCenter={updateSharableData}
      />

  ) : null;
};


export default SelectedLocation;
