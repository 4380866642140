// SearchPage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Page } from '../../components';
import SearchGrid from './SearchGrid';
import Searchbar from './Searchbar/Searchbar'; 
import { useBackend, useCache, useManagersStatus } from '../../context';

function SearchPage() {
  const isManagersInitialized = useManagersStatus();
  const { query, onGetCachedBusiness } = useCache();
  const { onSearch: onSearchRaw } = useBackend();
  const [hasMore, setHasMore] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(true);
  const businessesRef = useRef([]);
  const [businesses, setBusinesses] = useState([]);

  // console.log(businesses)
  useEffect(() => {
    // Keep the ref updated with the latest businesses state
    businessesRef.current = businesses;
  }, [businesses]);

  const onSearch = useCallback(
    (query, offset = 0) => {
      setSearchInProgress(true);
      onSearchRaw(query, offset).then(({ hasMore, bids = [] }) => {
        const newBusinesses = bids.map((bid) => onGetCachedBusiness(bid));
        // Use businessesRef.current to avoid stale values
        const updatedBusinesses = [...businessesRef.current, ...newBusinesses];
        setBusinesses(updatedBusinesses);
        setHasMore(hasMore);
        setSearchInProgress(false);
      });
    },
    [onSearchRaw, onGetCachedBusiness]
  );

  useEffect(() => {
    if (!query || query === '{}') return;
    // Reset businesses on new query
    setBusinesses([]);
    onSearch(query);
  }, [query, onSearch]);

  return (
    <Page>
      <Searchbar isInitialized={isManagersInitialized} />
      <SearchGrid
        businesses={businesses}
        loadInProgress={searchInProgress}
        hasMore={hasMore}
        loadMore={() => onSearch(query, businesses?.length)}
      />
    </Page>
  );
}

export default React.memo(SearchPage);
