// BusinessPageSkeleton.js

import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Skeleton for the Gallery Section
const GallerySkeleton = () => (
  <Box
    sx={{
      width: '100%',
      backgroundColor: 'transparent',
    }}
  >
    <Skeleton height={400} width="100%" />
  </Box>
);

// Skeleton for the Business Info Section
const BusinessInfoSkeleton = ({ isDesktopLayout }) => (
  <Box
    sx={{
      padding: 2,
      width: '100%',
      margin: '0 auto',
      border: '1px solid #ddd',
      borderRadius: '16px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      backgroundColor: '#fff',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 2,
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Skeleton circle height={80} width={80} />
        <Skeleton height={20} width={120} style={{ marginTop: 8 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Skeleton height={40} width={80} />
        <Divider orientation="vertical" flexItem />
        <Skeleton height={40} width={80} />
        <Divider orientation="vertical" flexItem />
        <Skeleton height={40} width={80} />
      </Box>
    </Box>
    <Divider sx={{ marginY: 2 }} />
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 2 }}>
      <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
      <Skeleton height={20} width="80%" style={{ marginBottom: 10 }} />
      <Skeleton height={20} width="70%" style={{ marginBottom: 10 }} />
    </Box>
    <Divider sx={{ marginY: 2 }} />
    <Box sx={{ marginBottom: 2 }}>
      <Skeleton height={15} width="90%" />
      <Skeleton height={15} width="95%" />
      <Skeleton height={15} width="80%" />
    </Box>
    {isDesktopLayout && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          gap: 2,
          marginTop: 2,
        }}
      >
        <Skeleton height={50} width="70%" />
        <Skeleton height={50} width="70%" />
      </Box>
    )}
  </Box>
);

// Skeleton for the Reviews Section
const ReviewsSkeleton = ({ isDesktopLayout, sx }) => (
  <Box sx={{ padding: 2, ...sx }}>
    <Typography variant="h4" gutterBottom>
      <Skeleton width={100} />
    </Typography>
    <Box>
      {Array.from({ length: isDesktopLayout ? 4 : 1 }).map((_, index) => (
        <Box key={index} sx={{ padding: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Skeleton circle height={40} width={40} />
          <Box sx={{ flex: 1 }}>
            <Skeleton height={20} width="60%" />
            <Skeleton height={15} width="80%" style={{ marginTop: 8 }} />
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
);

// Combined Skeleton Component
const BusinessPageSkeleton = ({ isDesktopLayout, isMobileLayout }) => (
  <Box
    display="flex"
    flexDirection={isDesktopLayout ? 'row' : 'column'}
    gap="25px"
    width="100%"
    padding="20px"
    paddingTop={isMobileLayout ? 0 : '20px'}
  >
    <Box flex={2} sx={{ width: '100%', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <GallerySkeleton />
      {isDesktopLayout && (
        <Box sx={{ border: '1px solid #ddd', borderRadius: '5%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', backgroundColor: '#fff' }}>
          <ReviewsSkeleton isDesktopLayout={isDesktopLayout} />
        </Box>
      )}
    </Box>
    <Box flex={1}>
      <BusinessInfoSkeleton isDesktopLayout={isDesktopLayout} />
      {!isDesktopLayout && (
        <ReviewsSkeleton sx={{ mt: 1, backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '5%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }} />
      )}
    </Box>
  </Box>
);

export default BusinessPageSkeleton;
