
// ================ Action types ================ //


export const UPSERT_BUSINESS = 'app/marketplaceData/UPSERT_BUSINESS';
export const UPSERT_TRANSACTION = 'app/marketplaceData/UPSERT_TRANSACTION';


// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  
  stids: {}, // stids has aid as key and a sharables of tx ids as values.
  businesses: {},
  transactions: {},
};

export default function marketplaceDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  if (!payload) return state;

  switch (type) {

    case UPSERT_BUSINESS: {
      const { id: bid,  ...rest } = action.payload;
      const existingBusiness = state.businesses[bid] || {};
      return {
        ...state,
        businesses: {
          ...state.businesses,
          [bid]: {
            id: bid,
            ...existingBusiness,
            ...rest,
          },
        },
      };
    }
    


    case UPSERT_TRANSACTION: {
      const {id} = payload;
      if(!id) return state
      
      const newState = {
        ...state,
        transactions: {
          ...state.transactions,
          [id]: {
            ...state.transactions[id],
            ...payload,
          },
        }
      };
      return newState;
}



    default:
      return state;
  }
}

// ================ Selectors ================ //

export const getCachedSearch = (searchString) => (dispatch, getState) => {
  const state = getState()
  const { searches } = state.marketplaceData

  return searchString in searches ? searches[searchString] : null;
}
export const getCachedstids = (channel_url) => (dispatch, getState) => {
  const state = getState()
  const { stids } = state.marketplaceData

  return channel_url in stids ? stids[channel_url] : null;
}





export const getCachedBusiness = (bid) => (dispatch, getState) => {
  const state = getState()
  const { businesses } = state.marketplaceData;

  return bid in businesses ? businesses[bid] : null
}



export const getCachedTransaction = (txID) => (dispatch, getState) =>{
  const state = getState()
  const { transactions } = state.marketplaceData;
  return txID in transactions ? transactions[txID] : null;
}

export const getCachedChannelTx = (channel_url) => (dispatch, getState) => {
  const state = getState()
  const { transactions } = state.marketplaceData;
  const arr = Object.values(transactions);
  const ctx = arr.filter(transaction => transaction.channel_url === channel_url);
  const lastTx = ctx.reduce((max, transaction) => 
    transaction.lastupdate > max.lastupdate ? transaction : max, ctx[0]);

  
  return lastTx

}

// ================ Action creators ================ //

export const upsertBusiness = (business) => ({
  type: UPSERT_BUSINESS,
  payload: business,
});





export const upsertTransaction = (transaction) => (dispatch, getState) => {
   const formateTransaction = (tx)=> {
    const {user} = getState().user
    const {customer, business} = tx;
    if(!customer || !business) return tx
    const isCustomer = user.sub === customer.id
    const otherUser = isCustomer ? business : customer;
    const formattedTx = {...tx, isCustomer, otherUser}
    return formattedTx
  }

  dispatch({
    type: UPSERT_TRANSACTION,
    payload: formateTransaction(transaction),
  })
}