import React, { createContext, useContext , useEffect, useState, useRef} from 'react';
import { useDispatch  } from 'react-redux';
import {CUSTOMER_ROLE, WORKER_ROLE} from '../util/constants';
import { connect } from 'react-redux';
import { 
  getCachedBusiness,
  getCachedTransaction,
 } from '../ducks/marketplaceData.duck';
import { useAuth0 } from '@auth0/auth0-react';

 const CacheContext = createContext();

export const CacheProviderComponent = (props) => {
  const { children, user, ownBusiness, updateUserInProgress, updateBusinessInProgress, transactions } = props;
  const {isLoading, user: auth0user} = useAuth0()
  const userLoaded = !!user?.id
  const businessLoaded = !!ownBusiness

  const isCustomer = user?.role == CUSTOMER_ROLE // would be better to add role to auth0 to not wait until loading to decide which icon to show
  const isWorker = user?.role == WORKER_ROLE
  const profile = isCustomer? user : ownBusiness
  const updateProfileInProgress = isCustomer? updateUserInProgress : updateBusinessInProgress
  const loaded = (isCustomer && userLoaded) || (isWorker && businessLoaded)
  const [searchParams, setSearchParams] = useState({})
  useEffect(() => {
    if(isLoading) return
    const params = {
      availability: {},
      categories: {},
      location: {},
      callerId: auth0user?.sub || ''
    }
    setSearchParams(params)

  }, [isLoading]);


  const dispatch = useDispatch();
  const onGetCachedBusiness = (bid) => dispatch(getCachedBusiness(bid));
  const onGetCachedTransaction = (transactionId) => dispatch(getCachedTransaction(transactionId));
  
  const onUpdateSearchParams = (searchObj,ltype) => {
    if(ltype == 'locations')
    {
      // location should be roiunded for a better cache hit ratio.
      searchObj['lat'] = parseFloat(searchObj['lat'].toFixed(2))
      searchObj['lng'] = parseFloat(searchObj['lng'].toFixed(2))
      setSearchParams({...searchParams, location: searchObj})
    }

    else if(ltype == 'availabilities')
      setSearchParams({...searchParams, availability: searchObj})

    else if(ltype == 'buckets')
    {
      const categorySet = new Set();
      searchObj.forEach(item => {
        categorySet.add(item.category);
      });

      const uniqueCategoriesList = [...categorySet]

      setSearchParams({...searchParams, categories: uniqueCategoriesList});
    }

  }



  return (
    <CacheContext.Provider value={{
      transactions,
      businessLoaded,
      isCustomer,
      isWorker,
      loaded,
      onGetCachedBusiness, 
      onGetCachedTransaction,
      onUpdateSearchParams,
      query: JSON.stringify(searchParams),
      profile,
      updateUserInProgress, updateBusinessInProgress, updateProfileInProgress,
      ...props
     }}>
      {children}
    </CacheContext.Provider>
  );
};

const useCache = () => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('useCache must be used within a CacheProviderComponent');
  }
  return context;
};
const mapStateToProps = state => {
  const { user,   updateUserInProgress } = state.user;
  const {readCounts} = state.sendbird
  const {transactions} = state.marketplaceData
  const { updateTransactioninprogress } = state.transaction;
  const {ownBusiness, updateBusinessInProgress} = state.business;
  return { user,  ownBusiness, transactions,  updateUserInProgress, updateBusinessInProgress, readCounts, 
    updateTransactioninprogress,  
  };

  };
  
  const CacheProvider = connect(mapStateToProps)(CacheProviderComponent);
  export { CacheProvider , useCache };

