import React, { useState } from 'react';
import { Box, Avatar, Typography, Grid, Rating, Card, Button, Drawer } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { ScrollList, SecondaryButton } from '../../../components';
import { decodeMessage } from '../../../util/utils';
const Review = ({ customerNickname, stars, message, customerPicture }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Avatar alt={customerNickname} src={customerPicture} />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1">
          {customerNickname}
        </Typography>
        <Rating value={stars} readOnly />
        <Typography variant="body2">
          {decodeMessage(message)}
        </Typography>
      </Grid>
    </Grid>
  );
}

const SwiperView = ({ reviews = [] }) => {
  const swiperReviews = reviews.slice(0, 5)
  return (
    <Swiper spaceBetween={16} slidesPerView={1}>
      {swiperReviews.map((review, index) => (
        <SwiperSlide key={index}>
          <Box sx={{ padding: 1 }}>
            <Review
              customerNickname={review.customer_given_name}
              stars={review.stars}
              message={review.message}
              customerPicture={review.customer_picture}
            />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const ScrollListView = ({  reviews }) => {
  const [offset, setOffset] = useState(10)
  const listReviews = reviews.slice(0, offset)
  const hasMoreReviews = reviews.length > listReviews.length
  const components = listReviews.map((review, index) => (
    <Box sx={{ padding: 1 }} key={index}>
      <Review
        customerNickname={review.customer_given_name}
        stars={review.stars}
        message={review.message}
        customerPicture={review.picture}
      />
    </Box>
  ));

  return (
    <ScrollList
      items={reviews}
      components={components}
      hasMore={hasMoreReviews}
      loadMoreinprogress={false}
      loadMore={() => setOffset(offset + 10)}
      selectedSharable={() => {}}
      sx={{
        maxHeight: `100%`,
        overflowY: 'auto',
      }}
    />
  );
};

const SectionReviews = ({ business, sx }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const reviews = business?.reviews || []
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '900px',
        maxHeight: '1600px',
        borderRadius: '5%',
        padding: 1,
        ...sx,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Reviews
      </Typography>
      <SwiperView reviews={reviews} />

      
      <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%"}}>
      <SecondaryButton message="View All Reviews" onClick={toggleDrawer(true)} sx={{width: "50%", mt: 0.5}}/>
      </Box>
      <Drawer
  anchor="right"
  open={drawerOpen}
  onClose={toggleDrawer(false)}
  sx={{
    '& .MuiDrawer-paper': {
      width: '70%',      // Ensure it doesn't cover the entire width
    },
  }}
>
  <Box sx={{ padding: 2 }}>
    <Typography variant="h6" gutterBottom>
      All Reviews
    </Typography>
    <ScrollListView business={business} reviews={reviews} />
  </Box>
</Drawer>

    </Card>
  );
};

export default SectionReviews;
