import React, {useEffect, useState} from 'react';
import { Controller } from 'react-hook-form';
import {CircularProgress, Box } from '@mui/material';
import css from './AvatarForm.module.css';
import { useBackend, useCache } from '../../../context';
import { Avatar } from '@mui/material';
import { SimpleButton } from '../../Button/Button';
import Skeleton from 'react-loading-skeleton';

const AvatarForm = ({setUrl, url}) => {
  const [uploading, setUploading] = useState(false)
  const {onUploadBusinessAvatar} = useBackend()
  const handleImageChange = (e) => {
    setUploading(true)
    const file = e.target.files[0];
    onUploadBusinessAvatar(file).then(({url})=>{
      setUploading(false)
      setUrl(url)
    })
  };

  const handleButtonClick = () => {
    document.getElementById('profileImageInput').click();
  };
  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems:"center",}}>
              {uploading?
              <Skeleton width="150px" height="150px"/>
              :<Box sx={{display: "flex", flexDirection: "column", alignItems:"center", width:"fit-content"}} onClick={handleButtonClick} >
              <Avatar src={url} sx={{width: 150, height: 150}}/>
              <SimpleButton message="Edit Image"  sx={{border: "0"}}/>
              <input
                type="file"
                id="profileImageInput"
                accept="image/*"
                onChange={handleImageChange}
                className={css.hiddenInput}
              />
            </Box>}
      </Box>
  );
};


export default AvatarForm;
