
import React, { useEffect, useState } from 'react';
import { useCache } from '../../../context';

import LocationMessage from './LocationMessage'
import BucketMessage from './BucketMessage'
import AvailabilityMessage from './AvailabilityMessage'

const CustomMessage = ({message}) => {
  const {user} = useCache()
  const sentByMe = user?.id === message?.sender.userId
  const type = message?.customType
  const list = message?.data? JSON.parse(message?.data) : {}
  if(!list?.data) return
  if(type == 'locations')
    return <LocationMessage
              sentByMe={sentByMe} title={list.title} 
              latitude={list.data.lat} longitude={list.data.lng} 
              fuzzy={list.data.fuzzy} circleRadius={list.data.circleRadius}
              
            />
  else if(type == 'buckets')
    return <BucketMessage bucket={list.data} sentByMe={sentByMe} title={list.title}/>

  else if(type =='availabilities')
    return <AvailabilityMessage availability={list.data} sentByMe={sentByMe} title={list.title}/>

}


export default CustomMessage