// AttachmentMenu.js
import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { SelectedTodos, SelectedAvailability,  SharableDrawer, SelectedLocation } from '../../../components';
import {useCache, useSendbird} from '../../../context';

const AttachmentMenu = ({ anchorEl, handleAttachmentClose, channel }) => {
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  // Handle selecting an attachment option
  const handleMenuItemClick = (option) => {
    setSelectedAttachment(option);
    handleAttachmentClose(); // Close the menu after selection
  };

  // Determine which component to render based on selectedAttachment
  const renderDrawerComponent = () => {
    switch (selectedAttachment) {
      case 'locations':
        return  <SelectedLocation />;
      case 'buckets':
        return <SelectedTodos />;
      case 'image':
        return <div>Image Component Placeholder</div>; // Temporary development placeholder
      case 'availabilities':
        return <SelectedAvailability />;
      default:
        return null;
    }
  };

  // Handle closing the drawer
  const handleDrawerClose = () => {
    setSelectedAttachment(null);
  };

  return (
    <>
      {/* Attachment Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleAttachmentClose}
        MenuListProps={{
          'aria-labelledby': 'attachment-button',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('locations')}>
          <LocationOnIcon sx={{ mr: 1 }} />
          Locations
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('buckets')}>
          <ListAltIcon sx={{ mr: 1 }} />
          Bucket List
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('image')}>
          <ImageIcon sx={{ mr: 1 }} />
          Image
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('availabilities')}>
          <EventIcon sx={{ mr: 1 }} />
          Availability
        </MenuItem>
      </Menu>

      {/* Sharable Drawer */}
      {selectedAttachment && (
        <SharableDrawer
          isOpen={Boolean(selectedAttachment)}
          setIsOpen={handleDrawerClose}
          component={renderDrawerComponent()}
          type={selectedAttachment}
        />
      )}
    </>
  );
};

export default AttachmentMenu;
