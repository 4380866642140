// ConnectAccountOnboardingDrawer.jsx

import React, { useState } from 'react';
import { useBackend, useStripeConnect } from '../../context';
import {
  Drawer,
  Button,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding as RawConnectAccountOnboarding,
} from "@stripe/react-connect-js";

const ConnectAccountOnboardingDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { stripeConnectInstance, loading, error, needsBoarding } = useStripeConnect();
  const {onFetchOwnBusiness} = useBackend()
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    onFetchOwnBusiness() // we need to re-fetch to update boarding status
  };

  // Show nothing if not needsBoarding
  if (!needsBoarding) {
    return null;
  }

  return (
    <>
      <Box
         sx={{
          display: "flex",
          flexDirection: "column",
          gap:1,
          mt: 3 // we need to center the content vertically instead of this.
        }}
      >
        <Typography variant="h6" color="textPrimary">
          Stripe needs more information
        </Typography>
        <Button variant="contained" color="primary" onClick={handleDrawerOpen}>
          Provide Information
        </Button>
      </Box>

      {/* Drawer Component */}
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        

        {/* Drawer Content */}
        <Box p={2} overflow="auto" height="calc(100% - 64px)">
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography color="error">Failed to load Stripe: {error.message}</Typography>
            </Box>
          ) : stripeConnectInstance ? (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <RawConnectAccountOnboarding />
            </ConnectComponentsProvider>
          ) : (
            <Typography>No Stripe instance available.</Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ConnectAccountOnboardingDrawer;
