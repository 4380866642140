import React, {useState } from 'react';
import { InvoiceForm } from '../../../components';
import { useBackend, useCache, useSendbird } from '../../../context';
import {NOTIFICATION_UPDATE_INVOICE} from '../../../util/constants'
import Drawer from '../Drawer'
import { PrimaryButton } from '../../../components';

const InvoicerDrawer = (props) => {
  const {
    tx,
    isOpen,
    onClose
  } = props;
  const {onUpdateTransaction} = useBackend()
  const {ownBusiness, updateTransactioninprogress} = useCache()
  const {onSendTxUpdate} = useSendbird()
  const [lines, setLines] = useState([]);
  if(!ownBusiness) return
  const invoice = {lines, hourlyrate: ownBusiness.hourlyrate, minpay: ownBusiness.minpay}
  const {id, channel_url} = tx
  const data = {invoice, id, channel_url, last_notification_type: NOTIFICATION_UPDATE_INVOICE}

  return <Drawer isOpen={isOpen} onClose={onClose}
    renderfooter={()=> {
      return <PrimaryButton
      inprogress={updateTransactioninprogress}
      message="Create Invoice"
      onClick={()=>{
        onUpdateTransaction(data).then(() => {
          onSendTxUpdate(channel_url, data)
          onClose()
        })
      }}
    />
    }}
  >
          <InvoiceForm data={lines} setData={setLines}/>
          
      </Drawer>


};


export default InvoicerDrawer