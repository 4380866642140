// StripeConnectContext.jsx

import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { loadStripe } from '@stripe/stripe-js';
import { useCache } from './index'; // Ensure this path is correct
import { CUSTOMER_ROLE } from "../util/constants";
import {
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

const StripeConnectContext = createContext();

export const StripeConnectProvider = ({ children }) => {

  const { ownBusiness, user, loaded } = useCache();
  const isCustomer = user?.role === CUSTOMER_ROLE;
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [needsBoarding, setNeedsBoarding] = useState(false);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null);     // For error handling

  const { stripeAccount } = ownBusiness || {};
  const { stripeCustomer } = user || {};
  const { future_requirements, requirements } = stripeAccount || {};

  // Initialize Stripe for Customers
  useEffect(() => {
    if (isCustomer) {
      const initializeStripeForCustomer = async () => {
        try {
          const promise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          setStripePromise(promise);
          setLoading(false);
        } catch (err) {
          console.error("Failed to load Stripe for customer:", err);
          setError(err);
          setLoading(false);
        }
      };
      initializeStripeForCustomer();
    }
  }, [stripeCustomer, isCustomer]);

  // Initialize Stripe Connect for Service Providers
  useEffect(() => {
    if (!ownBusiness?.stripeSession || isCustomer) return;

    const initializeStripeConnect = async () => {
      try {
        const instance = await loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret: () => Promise.resolve(ownBusiness.stripeSession.client_secret),
        });
        setStripePromise(instance.stripePromise);
        setStripeConnectInstance(instance);
        setLoading(false);
      } catch (err) {
        console.error("Failed to initialize Stripe Connect:", err);
        setError(err);
        setLoading(false);
      }
    };

    initializeStripeConnect();
  }, [ownBusiness?.stripeSession, isCustomer]);

  // Determine if onboarding is needed
  useEffect(() => {
    if (!loaded) return;
    const needsOnboarding = (future_requirements?.currently_due?.length > 0) || (requirements?.currently_due?.length > 0);
    setNeedsBoarding(needsOnboarding);
  }, [loaded, future_requirements, requirements]);

  const contextValue = useMemo(() => ({
    stripeConnectInstance,
    stripePromise,
    needsBoarding,
    loading,
    error,
    isCustomer,
  }), [stripeConnectInstance, stripePromise, needsBoarding, loading, error, isCustomer]);

  return (
    <StripeConnectContext.Provider value={contextValue}>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        {children}
      </ConnectComponentsProvider>
    </StripeConnectContext.Provider>
  );
  };

export const useStripeConnect = () => {
  const context = useContext(StripeConnectContext);
  if (!context) {
    throw new Error("useStripeConnect must be used within a StripeConnectProvider");
  }
  return context;
};
