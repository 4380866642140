
import React, { useState } from 'react';

import { ClickableMap } from '../../../components';

const LocationMessage = ({latitude, longitude, title, sentByMe, ...rest }) => {
  return <ClickableMap
          center={{ lat: latitude, lng: longitude }}
          title={title}
          setCenter={() => {}} // No need to change the center in this case
          previewsx={{
            cardsx: {width: 250, height: 250},
            boxsx: {justifyContent: sentByMe ? 'flex-end' : 'flex-start', ml: sentByMe? 0: 2.5, mr: sentByMe? 0: 2.5,mb:1}
          }}   
          />

};

export default LocationMessage