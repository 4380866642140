
import {upsertTransaction, getCachedTransaction} from './marketplaceData.duck'
import {fetchBusiness} from './business.duck'
import {createTransaction} from './transaction.duck'
import {NOTIFICATION_UPDATE_TYPES} from '../util/constants'
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
export const INITIALIZE_SENDBIRD_SDK = 'app/SENDBIRD/INITIALIZE_SENDBIRD_SDK';

export const CREATE_CHANNEL_REQUEST = 'app/SENDBIRD/CREATE_CHANNEL_REQUEST';
export const CREATE_CHANNEL_SUCCESS = 'app/SENDBIRD/CREATE_CHANNEL_SUCCESS';
export const CREATE_CHANNEL_ERROR = 'app/SENDBIRD/CREATE_CHANNEL_ERROR';

export const SET_NOTIFICATION_CHANNEL = 'app/SENDBIRD/SET_NOTIFICATION_CHANNEL';
export const SET_READ_COUNTS = 'app/SENDBIRD/SET_READ_COUNTS'
const initialState = {
  createChannelinprogress: false,
  sdk: null,
  notificationChannel: null,
  readCounts: {channel_count: 0, notification_count: 0}
  };
  

export const initializeSDK = sdk =>  ({
  type: INITIALIZE_SENDBIRD_SDK,
  payload: sdk
});

export const createChannelRequest = () =>  ({
  type: CREATE_CHANNEL_REQUEST,
});

export const createChannelSuccess = () =>  ({
  type: CREATE_CHANNEL_SUCCESS,
});

export const createChannelError = () =>  ({
  type: CREATE_CHANNEL_ERROR,
});









export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    
    case INITIALIZE_SENDBIRD_SDK:
      return {...state, sdk: payload}

    case CREATE_CHANNEL_REQUEST:
      return {...state, createChannelinprogress: true}

    case CREATE_CHANNEL_SUCCESS:
      return {...state, createChannelinprogress: false}

    case CREATE_CHANNEL_ERROR:
      return {...state, createChannelinprogress: false}

    case SET_NOTIFICATION_CHANNEL:
      return {...state, notificationChannel: payload}

    case SET_READ_COUNTS:
      return {...state, readCounts: {...state.readCounts, ...payload}}
    default:
      return state;
  }
}

export const getChannelByUrl = (url) => (dispatch, getState) => {
  const sb = getState().sendbird.sdk;
  return sb.groupChannel.getChannel(url)
}

export const initNotificationChannel = (url) => async (dispatch, getState) => {
  return // temporarly to avoid a bug
  const sb = getState().sendbird.sdk;
  const channel = await sb.groupChannel.getChannel(url);
  dispatch({type: SET_NOTIFICATION_CHANNEL, payload: channel})
}

export const updateUnreadCounts = () => (dispatch, getState) => {
  const sb = getState().sendbird.sdk;
  const totalCount_promise = sb.groupChannel.getTotalUnreadMessageCount();
  const notification_promise = sb.groupChannel.getTotalUnreadMessageCount({channelCustomTypesFilter: ["notification"]});
  Promise.all([totalCount_promise, notification_promise]).then(res =>{
    const channel_count = res[0] - res[1]
    const notification_count = res[1]
    dispatch({type: SET_READ_COUNTS, payload: {channel_count, notification_count}})
  })
}

export const createNotificationChannel = (uid) => (dispatch, getState) => {
  return
  const sb = getState().sendbird.sdk;
  const { currentUser } = sb;
  const params =  {
    invitedUserIds: [uid],
    isDistinct: true,
    customType: 'notification',
    name: "notification"
  }
  const promise = dispatch(createChannel(params));
  promise.then(channel => {
    
    sb.currentUser.createMetaData({"notification_url": channel._url})
  })
}


export const createChannel = (params) => (dispatch, getState) => {
  const sb = getState().sendbird.sdk;
  const { currentUser } = sb;
  const {invited, ...rest} = params
  if(!invited) return
  //const users = [...invited, currentUser.userId]
  const users = ['google-oauth2|110214648748259340171', "google-oauth2|116649677841528696300"]
  dispatch(createChannelRequest());

  return sb.groupChannel.createChannel({isDistinct: true, invitedUserIds: users, ...rest})
    .then(channel => {
      dispatch(createChannelSuccess());
      return channel;  // Ensure the channel is returned here
    })
    .catch((error) => {
      console.error(error);  // Log the error for debugging
      dispatch(createChannelError());
      throw error;  // Rethrow the error to ensure the promise is rejected
    });
};



export const updateUserMetadata = (metadata) => (dispatch, getState) => {
  const sb = getState().sendbird.sdk;
  return sb.currentUser.updateMetaData(metadata);
}

export const addTxUpdateHandler = () => (dispatch, getState) => {
  const sb = getState().sendbird.sdk;
  const txupdatesHandler = new GroupChannelHandler({
    onMessageReceived: (channel, message) => {
      if(NOTIFICATION_UPDATE_TYPES.includes(message.customType))
      {
        const tx = JSON.parse(message.data)
        dispatch(upsertTransaction(tx))
        dispatch(updateUnreadCounts())
      }
  },
  })
  sb.groupChannel.addGroupChannelHandler('txupdatesHandler', txupdatesHandler);
}

export const handleInquiry = (channel) => (dispatch, getState) =>{
  console.log("message sent")
}


export const sendMessage = (channel_url, message, type, data) => (dispatch, getState) => {
  const params = {
    message: message,
    customType: type,
    data: JSON.stringify(data),
};

  const promise = dispatch(getChannelByUrl(channel_url))
  promise.then(channel => channel.sendUserMessage(params))
}

