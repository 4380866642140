

import React from 'react';
import { Page, SelectedFavorites } from '../../components';

export const FavoritesPage = props => {

      return (
      <Page >
        <SelectedFavorites/>
      </Page>
    );
  
};





export default FavoritesPage;