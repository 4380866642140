import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Box, Paper, Typography, Chip, Stack,  } from '@mui/material';
import TodoItem from './TodoItem'


const MainDropArea = ({ items, setItems, showTimeSelector, }) => {
    const { isOver, setNodeRef } = useDroppable({
      id: 'todosArea',
    });

    
  
    const handleRemoveItem = (id) => {
      const newItems = items.filter((item) => item.id !== id)
      setItems(newItems);
    };
  
    const handleIncreaseItem = (id) => {
      const newItems = items.map((item) =>
        item.id === id ? { ...item, count: item.count + 1 } : item
      )
      setItems(newItems);
    };
  
    const handleDecreaseItem = (id) => {
      const newItems = items.map((item) =>
        item.id === id ? { ...item, count: item.count - 1 } : item
      ).filter((item) => item.count > 0)

      setItems(newItems)

    };
  
    const handleDropTime = (id) => {
      const newItems = items.map((item) =>
        item.id === id ? { ...item, time: item.time + 10 } : item
      )
      setItems(newItems);
    };
  
    const handleClearTime = (id) => {
      const newItems = items.map((item) =>
        item.id === id ? { ...item, time: 0 } : item
      )
      setItems(newItems);
    };
  
    return (
        <Paper
          ref={setNodeRef}
          sx={{
            backgroundColor: isOver ? 'lightgrey' : 'transparent',
            padding: 1,
            minHeight: 200,
            border: `2px dashed ${isOver ? 'blue' : 'grey'}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {items.length === 0 ? (
            <Typography variant="body2" color="textSecondary" overflowX='scroll'>
              Drag items here
            </Typography>
          ) : (
            <Box 
            sx={{display:"flex", flexDirection:"row", width: "100%", gap: 1, overflowX: 'auto'}}
            >
              {items.map((item) => (
                <TodoItem
                  key={item.id}
                  item={item}
                  onRemoveItem={handleRemoveItem}
                  onIncreaseItem={handleIncreaseItem}
                  onDecreaseItem={handleDecreaseItem}
                  onDropTime={handleDropTime}
                  onClearTime={handleClearTime}
                  showTimeSelector={showTimeSelector}
                />
              ))}
            </Box>
          )}
        </Paper>
    );
  };
  

export default MainDropArea;
