
import React, { useEffect, useState } from 'react';

import { Card, CardContent, Box,Drawer  } from '@mui/material';


const DrawerView = ({ children,drawerOpen, onClose, sx = {} }) => {
  const { boxsx = {}} = sx;

  return (
    <Drawer
      anchor="bottom"
      open={drawerOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          height: '100%',
        },
      }}
    >
      <Box sx={{
                height: "100%", 
                width: "100%",
              }}>
                {children}
      </Box>
    </Drawer>
  );
};

const CardView = ({ children, top, sx={}, onClick }) => {
  const {cardsx={}, cardcontentsx={},boxsx={}} = sx
  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingBottom: 0,
          display: 'flex',
          ...boxsx
        }}
      >
        <Card
          sx={{ ...cardsx}}
          onClick={onClick}
        >
          <CardContent
            sx={{
              padding: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              ...cardcontentsx,
              '&:last-child': {
                paddingBottom: 0, // Override MUI's default last-child padding
              },
            }}
          >
            {/* Reuse Content component for top and children */}
            <Box sx={{
                height: "100%", 
                width: "100%",
              }}>
                {children}
              </Box>
          </CardContent>
        </Card>
      </Box>

      
    </>
  );
};


const ClickableCard =  ({preview, content, drawerOpen, setDrawerOpen, previewsx, drawersx }) => {
  return <>
  <CardView 
        sx={previewsx}
        onClick={()=>setDrawerOpen(true)}
  >
          {preview}
    </CardView>
  
    <DrawerView sx={drawersx} drawerOpen={drawerOpen} onClose={()=>setDrawerOpen(false)}>
      {content}
    </DrawerView>
  </>
}


export default ClickableCard