// AvailabilityDrawer.js
import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import {SharableDrawer } from '../../../components';
import DrawerIcon from './DrawerIcon'; // Adjust the import path as necessary

const AvailabilityDrawer = React.memo(({ isInitialized }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetIsOpen = useCallback((open) => {
    setIsOpen(open);
  }, []);

  return (
    <Box>
      <DrawerIcon component={<EventIcon />} setIsOpen={handleSetIsOpen} isInitialized={isInitialized} />
      <SharableDrawer isOpen={isOpen} setIsOpen={setIsOpen} type="availabilities" />
    </Box>
  );
});

export default AvailabilityDrawer;
