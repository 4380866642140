// src/components/SharableManagerProvider.jsx
import React, { createContext, useContext, useMemo } from 'react';
import createManager from './createManagerContext';

// Create a single context for all managers
const ManagersContext = createContext(null);

// Create a separate context for initialization status
const InitializationContext = createContext(false);

// Custom hook to use the Managers Context
const useManagers = () => {
  const context = useContext(ManagersContext);
  if (!context) {
    throw new Error('useManagers must be used within a SharableManagerProvider');
  }
  return context;
};

// Custom hook to use the Initialization Context
const useInitialization = () => {
  const context = useContext(InitializationContext);
  if (context === undefined) {
    throw new Error('useInitialization must be used within a SharableManagerProvider');
  }
  return context;
};

// Define manager types
const managerTypes = ['locations', 'favorites', 'buckets', 'availabilities'];

// Create hooks for each manager type
const managerHooks = managerTypes.reduce((hooks, type) => {
  hooks[type] = createManager(type);
  return hooks;
}, {});

const SharableManagerProvider = ({ children }) => {
  // Initialize all managers by calling each hook directly
  const locationsManager = managerHooks['locations']();
  const favoritesManager = managerHooks['favorites']();
  const bucketsManager = managerHooks['buckets']();
  const availabilitiesManager = managerHooks['availabilities']();

  // Determine if all managers are initialized
  const initialized = useMemo(() => {
    return (
      !!locationsManager &&
      !!favoritesManager &&
      !!bucketsManager &&
      !!availabilitiesManager
    );
  }, [locationsManager, favoritesManager, bucketsManager, availabilitiesManager]);

  // Memoize the managers to prevent unnecessary re-renders
  const managers = useMemo(
    () => ({
      locations: locationsManager,
      favorites: favoritesManager,
      buckets: bucketsManager,
      availabilities: availabilitiesManager,
    }),
    [locationsManager, favoritesManager, bucketsManager, availabilitiesManager]
  );

  return (
    <ManagersContext.Provider value={managers}>
      <InitializationContext.Provider value={initialized}>
        {children}
      </InitializationContext.Provider>
    </ManagersContext.Provider>
  );
};

// Centralized useManager Hook
const useManager = (type) => {
  const managers = useManagers();
  const manager = managers[type.toLowerCase()];


  return manager;
};

// Hook to access initialization status
const useManagersStatus = () => {
  return useInitialization();
};

export { SharableManagerProvider, useManager, useManagersStatus };
