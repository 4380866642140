import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, IconButton, Slider } from '@mui/material';
import { default as LocationIcon } from '@mui/icons-material/LocationOn';

import * as turf from '@turf/turf';  
import { SecondaryButton } from '../../Button/Button';
const config = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  initialZoom: 8,
  accurateZoom: 15,
  fuzzyColor: '#c0392b', // Adding this to match the Map component
};

const calculateZoomForRadius = (radius, cardMode) => {
  // Adjust scaling based on whether cardMode is true (small container)
  const scaleFactor = cardMode ? 2.5 : 1.5; // Higher factor for smaller containers (card mode)

  const radiusMeter = radius * 1000; // Convert radius to meters
  const adjustedRadius = radiusMeter * scaleFactor; // Apply scaleFactor based on mode
  const factor = adjustedRadius / 200; // Adjust the factor to control the zoom

  // Calculate zoom level, ensuring the circle fits well in the map
  const zoomLevel = Math.log2(40075016.686 / (256 * factor)) - 1;

  return Math.min(Math.max(zoomLevel, 0), 22); // Ensure zoom is within the 0-22 range
};

// sometimes marker disappear I think we need to useEffect on "fuzzy" to create it if we move from fuzzy to not fuzzy
const MapForm = ({ center, setCenter, allowEdit, fuzzy, cardMode}) => {
  const radiusRef = useRef(25);
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const circleLayerId = useRef('fuzzy-location-circle');

  const fly = ({ lat, lng }) => {
          mapRef.current.flyTo({
            center: [lng, lat],
            speed: 1.2,
            curve: 1,
            essential: true
          });
        
          fuzzy ? updateFuzzyArea({ lat, lng }) : updateMarker({ lat, lng })
          // update lat,lng only. keep radius as is if exists.
          setCenter(prev => ({...prev,lat,lng}))
  }
  useEffect(() => {
    if(center?.lat && center?.lng) return
    const latlng = {lng: center.lng, lat: center.lat} // replace this with geo
    const rObj = fuzzy? {radius: radiusRef.current} : {}
    setCenter({...latlng, ...rObj })
  }, []);

  useEffect(() => {
    if(!fuzzy || !radiusRef.current || !center?.lat || !center?.lng || !mapRef?.current) return

    updateFuzzyArea({...center})
  }, [radiusRef.current]);
  useEffect(() => {
    if(!mapContainer.current) return
    mapboxgl.accessToken = config.mapboxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: [center?.lng || -122.4194, center?.lat || 37.7749],
      zoom: fuzzy ? calculateZoomForRadius(radiusRef.current, cardMode) : config.accurateZoom,
    });

    

    if (allowEdit) {
      mapRef.current.on('click', (e) => {
        const { lng, lat } = e.lngLat;
        fly({ lat, lng })
        
      });
    }

  }, []);

  useEffect(() => {
    if (mapRef.current && center && center.lat && center.lng) {
      fly({...center});
    }
  }, [JSON.stringify({lat: center.lat, lng: center.lng})]);

  const updateMarker = ({ lat, lng }) => {
    if (markerRef.current) {
      markerRef.current.setLngLat([lng, lat]);
    } else {
      markerRef.current = new mapboxgl.Marker({ color: 'black' })
        .setLngLat([lng, lat])
        .addTo(mapRef.current);
    }
  };

  const updateFuzzyArea = ({ lat, lng }) => {
    const options = { steps: 64, units: 'kilometers', properties: {} };
    const circle = turf.circle([lng, lat], radiusRef.current / 2, options);
  
    // Check if the map style is fully loaded
    if (!mapRef.current.isStyleLoaded()) {
      mapRef.current.once('styledata', () => {
        if (mapRef.current.getLayer(circleLayerId.current)) {
          mapRef.current.getSource(circleLayerId.current).setData(circle);
        } else {
          mapRef.current.addSource(circleLayerId.current, {
            type: 'geojson',
            data: circle,
          });
  
          mapRef.current.addLayer({
            id: circleLayerId.current,
            type: 'fill',
            source: circleLayerId.current,
            paint: {
              'fill-color': config.fuzzyColor,
              'fill-opacity': 0.3,
            },
          });
        }
      });
    } else {
      if (mapRef.current.getLayer(circleLayerId.current)) {
        mapRef.current.getSource(circleLayerId.current).setData(circle);
      } else {
        mapRef.current.addSource(circleLayerId.current, {
          type: 'geojson',
          data: circle,
        });
  
        mapRef.current.addLayer({
          id: circleLayerId.current,
          type: 'fill',
          source: circleLayerId.current,
          paint: {
            'fill-color': config.fuzzyColor,
            'fill-opacity': 0.3,
          },
        });
      }
    }
  };


  const handleGeolocationClick = () => {
    if(!allowEdit)
      fly({...center})
    else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fly({lat: latitude, lng: longitude})
        },
        (error) => console.error("Error getting location:", error)
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  


  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      
    >
      <Box ref={mapContainer} sx={{height:"100%"}}>
      {!cardMode && (
        <Box  width="100%" flexGrow={1} position="relative">
            <IconButton
              onClick={handleGeolocationClick}
              sx={{
                position: "absolute",
                right: 20, 
                top: 20,
                transform: "translateX(-50%)",
                zIndex: 10,
                backgroundColor: "white",
              }}
            >
              <LocationIcon />
            </IconButton>
        </Box>
      )}

      </Box>

      {/* Map container taking up full available space */}
      


      {/* Slider */}
      {fuzzy && !cardMode && <Box width="100%" padding={2}>
        <Slider
          sx={{ width: "100%", margin: "0 auto" }} // Center slider horizontally
          value={center?.radius || 10}
          onChange={(event, newValue) => {
            setCenter(prev => ({...prev,radius: newValue}))
            radiusRef.current = newValue
          }}
          valueLabelDisplay="auto"
        />
      </Box>}
    </Box>
  );
};


export default MapForm;
