

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Import configs and util modules
import { injectIntl } from '../../util/reactIntl';

// Import shared components
import { Page } from '../../components';
import TabWizard from './TabWizard/TabWizard';

import css from './AccountPage.module.css';

export const AccountPageComponent = props => {
  const {
    intl,
  } = props;

      return (
      <Page container={css.container}>
        <TabWizard
          id="AccountSettingsWizard"
          intl={intl}
      
        />
      </Page>
    );
  
};




const AccountPage = compose(
  injectIntl
)(AccountPageComponent);

export default AccountPage;