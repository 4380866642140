import React, { useState, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Box, Tabs, Tab, CircularProgress } from '@mui/material';
import { useCache, useStripeConnect } from '../../context';
import { Page } from '../../components';
import ConnectAccountOnboardingDrawer from './ConnectAccountOnboardingDrawer'
import ConnectPayments from './ConnectPayments';
import ConnectPayouts from './ConnectPayments';
import ConnectTaxSettings from './ConnectTaxSettings';
const PAYMENTS = 'PAYMENTS';
const PAYOUTS = 'PAYOUTS';
const TAXES = 'TAXES';

const FinancesPageComponent = (props) => {
  const [activeTab, setActiveTab] = useState(PAYMENTS);

  const { needsBoarding } = useStripeConnect();
  const {updateBusinessInProgress} = useCache()
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if(needsBoarding)
    return <Page>
      {updateBusinessInProgress? <CircularProgress/> : <ConnectAccountOnboardingDrawer/>}
    </Page>
  return (
    <Page>
      <Box sx={{width:"100%"}}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <Tab label="Payments" value={PAYMENTS} />
        <Tab label="Payouts" value={PAYOUTS} />
        <Tab label="Taxes" value={TAXES} />

      </Tabs>
      
      <Box sx={{ height: "100%", display:"flex", justifyContent:"center", alignItems:"center", padding: 2}}>
              {activeTab === PAYMENTS && <ConnectPayments/>}
              {activeTab === PAYOUTS && <ConnectPayouts/>}
              {activeTab === TAXES && <ConnectTaxSettings/>}

      </Box>
      </Box>
      
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

const FinancesPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(FinancesPageComponent);

export default FinancesPage;
