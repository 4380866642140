



import InboxPage from '../pages/InboxPage/InboxPage';
import BusinessPage from '../pages/BusinessPage/BusinessPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import SearchPage from '../pages/SearchPage/SearchPage';
import ActivityPage from '../pages/ActivityPage/ActivityPage';
import FinancesPage from '../pages/FinancesPage/FinancesPage';
import FavoritesPage from '../pages/FavoritesPage/FavoritesPage';
import { useAuth0 } from '@auth0/auth0-react';


import { Page } from '../components';

import React, { useState, useEffect } from 'react';
const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  useEffect(() => {
    if (!isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return <Page>
    loading...
  </Page>
};
const LogoutPage = () => {
  const {isAuthenticated, logout } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      console.log(process.env.REACT_APP_MARKETPLACE_ROOT_URL)
      logout(process.env.REACT_APP_MARKETPLACE_ROOT_URL);
    }
  }, [isAuthenticated]);

  return <Page>
    loading...
  </Page>
};

export const getRouteByName = (name) => {
  return routeConfiguration.find(route => route.name === name)
}
 
export const routeConfiguration =  
   [
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/logout',
      name: 'LogoutPage',
      component: LogoutPage,
    },
    {
      path: '/',
      name: 'SearchPage',
      component: SearchPage,
      auth: false,
    },

    {
      path: '/b/:bid/',
      name: 'BusinessPage',
      component: BusinessPage,
      auth: false,
    },


    {
      path: '/finances',
      name: 'FinancesPage',
      component: FinancesPage,
      auth: true,
    },

    {
      path: '/inbox',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
    },

    {
      path: '/activity',
      name: 'ActivityPage',
      auth: true,
      authPage: 'LoginPage',
      component: ActivityPage,
    },

    {
      path: '/account',
      name: 'AccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: AccountPage,
    },

    {
      path: '/favorites',
      name: 'FavoritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: FavoritesPage,
    },

  ];


  export const getPath = (name) =>{
    const route = routeConfiguration.find(route => route.name === name)
    return route.path
  }

  export const getPathName = (path) => {
    const names = {
      "/": "SearchPage",
      "b": "BusinessPage",
      "finances": "FinancesPage",
      "inbox": "InboxPage",
      "activity": "ActivityPage",
      "account": "AccountPage",
      "favorites": "FavoritesPage",
    }

    return names[path]
  }


  
  export const PATHS = {
    HomePage: getPath("SearchPage"),

    SearchPage: getPath("SearchPage"),
    BusinessPage: getPath("BusinessPage"),
    FinancesPage: getPath("FinancesPage"),
    InboxPage: getPath("InboxPage"),
    ActivityPage: getPath("ActivityPage"),
    AccountPage: getPath("AccountPage"),
    FavoritesPage: getPath("FavoritesPage"),
  
  }
  
export const PAGE_NAMES = {

  SearchPage: "SearchPage",
  BusinessPage: "BusinessPage",
  FinancesPage: "FinancesPage",
  InboxPage: "InboxPage",
  ActivityPage: "ActivityPage",
  AccountPage: "AccountPage",
  FavoritesPage: "FavoritesPage",

}

export const PANELS = {

  CHANNEL_LIST: 'CHANNEL_LIST',
  CHANNEL: 'CHANNEL',
  ACTIVITY_DISPLAY: 'DISPLAY',
}

