import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, FormControlLabel, Switch, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ItemSettingsModal = ({ open, setModalOpen, selectedSharable, updateSharable, onDelete }) => {
  const [editData, setEditData] = useState({});
  useEffect(() => {
    setEditData({
      title: selectedSharable.title || '',
      isDefault: false, // Set isDefault from selectedSharable.isisDefault
    });
  }, [selectedSharable]);

  const alreadyDefault = selectedSharable.isDefault
  
  const handleSave = () => {
    const update = {
      title: editData.title,
      isDefault: editData.isDefault, // Include 'isDefault' in the update object
    };
    updateSharable(update);
    setModalOpen(false)
  };

  const handleDelete = () => {
    if (selectedSharable) {
      onDelete(selectedSharable.id);
    }
    setModalOpen(false)
  };

  if (!selectedSharable) return null;

  return (
    <Modal open={open} onClose={() => setModalOpen(false)}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: 300,
        }}
      >
        {/* Close button positioned to the far left */}
        <IconButton
          onClick={() => setModalOpen(false)}
          sx={{ position: 'absolute', top: 8, left: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" mb={2} textAlign="center">
          Edit Item
        </Typography>

        <TextField
          label="Title"
          value={editData.title}
          onChange={(e) => setEditData({ ...editData, title: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
        />

        {!alreadyDefault && <FormControlLabel
          control={
            <Switch
              checked={editData.isDefault}
              onChange={(e) => setEditData({ ...editData, isDefault: e.target.checked })} // Update 'isDefault' in editData
              color="primary"
            />
          }
          label="Assign default"
          sx={{ mb: 2 }}
        />}

        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
            Save
          </Button>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ItemSettingsModal;
