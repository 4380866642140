import React from 'react';
import { useTheme, Typography } from '@mui/material';
import {  ClickableCard, AvailabilityPreview } from '../../../components';
import {capitalizeFirstChar} from '../../../util/utils'

const AvailabilityMessage = ({ availability, title, sentByMe }) => {
  const theme = useTheme();
  const preview = <AvailabilityPreview availability={availability}/>
  const top = <Typography variant="h6"> {capitalizeFirstChar(title)} </Typography>

  return <ClickableCard
          top={top}
          preview={preview}
          previewsx={{
            cardsx: {width: 250, maxHeight: 250},
            cardcontentsx: {backgroundColor: sentByMe ? theme.customVariables.lightOrange : theme.customVariables.lightGray},
            boxsx: {justifyContent: sentByMe ? 'flex-end' : 'flex-start', ml: sentByMe? 0: 2.5, mr: sentByMe? 0: 2.5,mb:1,}
          }}
        />

};

export default AvailabilityMessage;
