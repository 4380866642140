import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FieldTextInput, FieldCurrencyInput } from '../../../../components';
import SlugInput from './SlugInput';
import { Box, Typography } from '@mui/material';
import { useBackend, useCache } from '../../../../context';
const FrontBusinessCardTab = (props) => {
  const {
   setTabButton,
  } = props;
  const {  ownBusiness, updateBusinessInProgress } = useCache();
  const {onUpdateOwnBusiness} = useBackend()
  const { control,  } = useForm({
    defaultValues: {
      name: ownBusiness?.name || '',
      slug: ownBusiness?.slug || '',
      hourlyrate: ownBusiness?.hourlyrate,
      minpay: ownBusiness?.minpay,
    },
  });

  const values = useWatch({ control });
  useEffect(() => {
    setTabButton({
      onSubmit: () => {
        console.log(values)
        onUpdateOwnBusiness(values);
      },
      inprogress: updateBusinessInProgress,
    });
  }, [JSON.stringify(values), updateBusinessInProgress]);

  return (
    <Box sx={{ padding: 2.5, width: '100%' }}> {/* 20px padding */}
      <form
        onSubmit={() => {}}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <BusinessNameSection control={control} defaultValue={ownBusiness?.name}/>
        <BusinessSlugSection control={control} />
        <RateSection control={control} />
      </form>
    </Box>
  );
};

const BusinessNameSection = ({ control }) => (
  <Box sx={{ mb: 2.5, width: '100%' }}> {/* 20px margin-bottom */}
    <Typography variant="h5">Business Name</Typography>
    <FieldTextInput name="name" placeholder="Rising Sun Mechanic" control={control} sx={{ width: '100%' }}  />
  </Box>
);

const RateSection = ({control}) => {
  return <Box sx={{ mb: 3, width: '100%' }}>
    <Typography variant="h6">Quote</Typography>
     <Box sx={{ mb: 1 }}>
        <Typography variant="body2">My hourly rate is</Typography>
        <FieldCurrencyInput control={control} name="hourlyrate" label="$" fullWidth />
      </Box>

      <Box > {/* Margin-bottom: 30px */}
        <Typography variant="body2">My minimum per job is:</Typography>
        <FieldCurrencyInput control={control} name="minpay" label="$" fullWidth />
      </Box>
  </Box>
}

const BusinessSlugSection = ({ control }) => (
  <Box sx={{ mb: 2.5, width: '100%' }}>
    <Typography variant="h5">Business Id</Typography>
    <SlugInput control={control}/>
  </Box>
);


export default FrontBusinessCardTab;
