import React, { useEffect, useState } from 'react';
import {ReviewForm } from '../../../components';
import { useSendbird, useModal, useBackend, useCache } from '../../../context';
import { NOTIFICATION_UPDATE_REVIEW } from '../../../util/constants';
import {encodeMessage} from '../../../util/utils'
import { v4 as uuidv4 } from 'uuid';
import Drawer from '../Drawer'

export const MODAL_ID = "ReviewDrawer";

const ReviewDrawer = (props) => {
  const {
    tx,
    onClose,
  } = props;
  const {onSendTxUpdate} = useSendbird()
  const [r, setReview] = useState({});
  const {user, updateTransactioninprogress} = useCache()
  const {onUpdateTransaction} = useBackend()
  const {id, bid, channel_url} = tx
  const reviewButton = {
    inprogress: updateTransactioninprogress,
    onClick: () => {
      const review = {
        id: uuidv4(),
        bid,
        stars: r.rating, 
        customerID: user?.id, 
        message: encodeMessage(r.message),
        customer_given_name: user?.given_name, customer_family_name: user?.family_name,
        customer_picture: user?.picture,
        created_at: Date.now()
      }
      const data = {
        id, 
        review,
        last_notification_type: NOTIFICATION_UPDATE_REVIEW
      }
      onUpdateTransaction(data).then(() =>{
        onSendTxUpdate(channel_url, data)
        onClose()
      })
    },
    text: "Review"
  };

  return (
    <Drawer>
      <ReviewForm tx={tx} setReview={setReview} />
      {reviewButton}

    </Drawer>
  );
};


export default ReviewDrawer;
